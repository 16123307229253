import './account_page.css';
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { AuthContext } from 'contexts/auth_context';
import { CartContext } from 'contexts/cart_context';
import { UserContext } from 'contexts/user_context';

import PersonIcon from '@material-ui/icons/Person';
import EmailIcon from '@material-ui/icons/Email';
import CakeIcon from '@material-ui/icons/Cake';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import HeightIcon from '@material-ui/icons/Height';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import FitnessCenterIcon from '@material-ui/icons/FitnessCenter';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import SyncIcon from '@material-ui/icons/Sync';

import {
  Dialog,
  InputAdornment,
  Select,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import { updateProfile, updateEmail, sendEmailVerification } from 'firebase/auth';

import MobileSettingsButton from 'components/shared/mobile_settings_button/mobile_settings_button';
import { Capacitor } from '@capacitor/core';
import DietaryPreferences from 'components/shared/dietary_preferences/dietary_preferences';
import { exerciseFrequencies, lifestyleExamples } from 'components/shared/calorie_calculator/steps/activity_lifestyle_step';
import { HealthConnect } from 'capacitor-health-connect';
import { CapacitorHealthkit } from '@perfood/capacitor-healthkit';
import HealthService from 'services/health_service';

const AccountPage = () => {

  const { currentUser } = React.useContext(AuthContext);
  let userObject = React.useContext(UserContext).userObject;
  let setUserObject = React.useContext(UserContext).setUserObject;
  const [editNameDialogOpen, setEditNameDialogOpen] = React.useState(false);
  const [editEmailDialogOpen, setEditEmailDialogOpen] = React.useState(false);
  const [editAgeDialogOpen, setEditAgeDialogOpen] = React.useState(false);
  const [editSexDialogOpen, setEditSexDialogOpen] = React.useState(false);
  const [editHeightDialogOpen, setEditHeightDialogOpen] = React.useState(false);
  const [editActivityDialogOpen, setEditActivityDialogOpen] = React.useState(false);
  const [editCardioDialogOpen, setEditCardioDialogOpen] = React.useState(false);
  const [editStrengthDialogOpen, setEditStrengthDialogOpen] = React.useState(false);
  const [editDietaryDialogOpen, setEditDietaryDialogOpen] = React.useState(false);
  const [editNotificationsDialogOpen, setEditNotificationsDialogOpen] = React.useState(false);
  const [editLoading, setEditLoading] = React.useState(false);
  const [emailError, setEmailError] = React.useState('');
  const [ageError, setAgeError] = React.useState('');
  const resetAllContexts = React.useContext(CartContext).resetAllContexts;
  const { logout } = React.useContext(AuthContext);
  const { setNotificationPreferences } = React.useContext(UserContext);
  const [editHealthSyncDialogOpen, setEditHealthSyncDialogOpen] = useState(false);
  const showBanner = React.useContext(UserContext).showBanner;
  const [healthPermissions, setHealthPermissions] = useState({
    steps: null,
    weight: null,
    bodyFat: null
  });
  const [isCheckingPermissions, setIsCheckingPermissions] = useState(true);

  useEffect(() => {
    const checkHealthPermissions = async () => {
      if (Capacitor.getPlatform() === 'web') {
        setIsCheckingPermissions(false);
        return;
      }

      try {
        if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
          // Only check current state of permissions, don't request new ones
          setHealthPermissions({
            steps: userObject.healthSync?.steps ?? false,
            weight: userObject.healthSync?.weight ?? false,
            bodyFat: userObject.healthSync?.bodyFat ?? false
          });
        } 
      } catch (error) {
        console.error('Error checking health permissions:', error);
      }
      setIsCheckingPermissions(false);
    };

    checkHealthPermissions();
  }, []);

  const handleHealthPermissionChange = async (type) => {
    if (Capacitor.getPlatform() === 'web') return;

    console.log('Handling health permission change for:', type);
    try {
      if (healthPermissions[type]) {
        console.log('Disabling sync for:', type);
        // Disabling sync - no need to check permissions
        const newHealthPermissions = { ...healthPermissions };
        newHealthPermissions[type] = false;
        setHealthPermissions(newHealthPermissions);

        const newUserObject = { ...userObject };
        newUserObject.healthSync = {
          ...userObject.healthSync,
          [type]: false
        };
        setUserObject(newUserObject);

      } else {
        console.log('Enabling sync for:', type);
        if (Capacitor.getPlatform() === 'ios') {
          try {
            // Define read permissions based on type
            let readPermissions = [];
            if (type === 'steps') {
              readPermissions = ['steps'];
            } else if (type === 'weight') {
              readPermissions = ['weight'];
            } else if (type === 'bodyFat') {
              readPermissions = ['bodyFat'];
            }
            
            await CapacitorHealthkit.requestAuthorization({
              all: [],
              read: readPermissions,
              write: []
            });

            // Only update if permission was granted (if it failed, it would have thrown)
            const newHealthPermissions = { ...healthPermissions };
            newHealthPermissions[type] = true;
            setHealthPermissions(newHealthPermissions);

            const newUserObject = { ...userObject };
            newUserObject.healthSync = {
              ...userObject.healthSync,
              [type]: true
            };
            setUserObject(newUserObject);

            // Perform immediate sync after enabling
            showBanner('Access granted, syncing data...');
            await HealthService.syncHistoricalData(newUserObject, setUserObject, showBanner);

          } catch (error) {
            showBanner('Health App access denied');
            console.error('Error requesting iOS health permissions:', error);
          }
        } else if (Capacitor.getPlatform() === 'android') {
          try {
            console.log('Requesting Android permissions...');
            let permissions = [];
            if (type === 'steps') {
              permissions = ['Steps'];
            } else if (type === 'weight') {
              permissions = ['Weight'];
            } else if (type === 'bodyFat') {
              permissions = ['BodyFat'];
            }
            console.log('Requesting permissions:', permissions);

            console.log('Checking permissions after settings...');
            const permissionsAfterSettings = await HealthConnect.checkHealthPermissions({
              read: [type === 'steps' ? 'Steps' : type === 'weight' ? 'Weight' : 'BodyFat'],
              write: []
            });

            console.log('Permissions after settings (raw):', permissionsAfterSettings);
            console.log('Permissions after settings (stringified):', JSON.stringify(permissionsAfterSettings));

            const permissionType = type === 'steps' ? 
              'android.permission.health.READ_STEPS' : 
              type === 'weight' ? 
                'android.permission.health.READ_WEIGHT' : 
                'android.permission.health.READ_BODY_FAT';

            // Updated condition to check grantedPermissions array
            if (permissionsAfterSettings.hasAllPermissions || 
                (permissionsAfterSettings.grantedPermissions && permissionsAfterSettings.grantedPermissions.includes(permissionType))) {
              console.log('Permission granted for:', permissionType);
              // Update health permissions
              const newHealthPermissions = { ...healthPermissions };
              newHealthPermissions[type] = true;
              setHealthPermissions(newHealthPermissions);

              // Update user object
              const newUserObject = { ...userObject };
              newUserObject.healthSync = {
                ...userObject.healthSync,
                [type]: true
              };
              setUserObject(newUserObject);

              showBanner('Access granted, syncing data...');
              await HealthService.syncHistoricalData(newUserObject, setUserObject, showBanner);
            } else {
              console.log('Permission denied for:', permissionType);
              console.log('Available permissions:', permissionsAfterSettings.grantedPermissions);
              showBanner('Health Connect access denied');
            }
          } catch (error) {
            console.error('Error requesting Android permissions:', error);
            showBanner('Health Connect access denied');
          }
        }
      }
    } catch (error) {
      console.error('Error updating health permissions:', error);
      showBanner('Error: ' + error.message);
    }
  };

  function exitDialogs() {
    setEditNameDialogOpen(false);
    setEditEmailDialogOpen(false);
    setEditAgeDialogOpen(false);
    setEditSexDialogOpen(false);
    setEditHeightDialogOpen(false);
    setEditActivityDialogOpen(false);
    setEditCardioDialogOpen(false);
    setEditStrengthDialogOpen(false);
    setEditDietaryDialogOpen(false);
    setEditNotificationsDialogOpen(false);
    setEditLoading(false);
    setAgeError('');
    setEmailError('');
  }

  function changeName(e) {
    e.preventDefault();
    setEditLoading(true);
    const name = e.target.elements.name.value;
    updateProfile(currentUser, {
      displayName: name,
    })
      .then(() => {
        console.log('Profile updated successfully');
      })
      .catch((error) => {
        console.error('Error updating profile:', error);
      });
    setTimeout(exitDialogs, 1500);
  }

  async function changeEmail(e) {
    e.preventDefault();
    setEditLoading(true);
    const newEmail = e.target.elements.email.value;
    updateEmail(currentUser, newEmail)
      .then(() => {
        console.log('Profile updated successfully');
        sendEmailVerification(currentUser);
        exitDialogs();
      })
      .then(() => {
        console.log('Verification email sent to the new address');
      })
      .catch((error) => {
        setEmailError('Error updating profile: ' + error.message);
        setEditLoading(false);
      });
  }

  function changeAge(e) {
    e.preventDefault();
    setEditLoading(true);
    const age = parseInt(e.target.elements.age.value, 10);
    if (age < 18) {
      setAgeError('You must be at least 18 years old.');
      setEditLoading(false);
      return;
    }
    setUserObject({ ...userObject, age: e.target.elements.age.value });
    setAgeError('');
    exitDialogs();
  }

  function changeSex(e) {
    setUserObject({ ...userObject, sex: e.target.value });
    exitDialogs();
  }

  const handleHeightChange = (e) => {
    const { name, value } = e.target;
    setUserObject({ ...userObject, [name]: value });
  };

  const handleHeightUnitChange = (e) => {
    const value = e.target.value;
    if (value === 'cm') {
      setUserObject({ ...userObject, heightUnit: value, heightFeet: '', heightInches: '' });
    } else {
      setUserObject({ ...userObject, heightUnit: value, height: '' });
    }
  };

  function changeHeight(e) {
    e.preventDefault();
    const heightUnit = userObject.heightUnit || 'inches';
    if (heightUnit === 'cm') {
      const height = e.target.elements.height.value;
      setUserObject({ ...userObject, height: height });
    } else {
      const heightFeet = e.target.elements.heightFeet.value;
      const heightInches = e.target.elements.heightInches.value;
      setUserObject({ ...userObject, heightFeet: heightFeet, heightInches: heightInches });
    }
    exitDialogs();
  }

  const getActivityTitle = (val) => {
    const activityLevel = Object.entries(lifestyleExamples).find(([level]) =>
      parseFloat(level) === parseFloat(val)
    );
    return activityLevel ? activityLevel[1].title : '';
  };

  const getExerciseLabel = (val) => {
    const exercise = exerciseFrequencies.find(ex => ex.value === parseFloat(val));
    return exercise ? exercise.label : '';
  };

  const changeNotificationSettings = async () => {
    await setNotificationPreferences(userObject.wantsReminders, userObject.reminderTime);
    exitDialogs();
  };

  // Convert stored time to local time
  const getLocalTime = (storedTime) => {
    if (!storedTime) return '20:00'; // default time

    try {
      // Get stored hours and minutes
      const [hours, minutes] = storedTime.split(':');

      // Create a date object in the user's stored timezone
      const userStoredTimezone = userObject.reminderTimeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
      const today = new Date();
      const dateInStoredTimezone = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        parseInt(hours),
        parseInt(minutes)
      );

      // Convert to local time
      const localTime = dateInStoredTimezone.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });

      return localTime;
    } catch (error) {
      console.error('Error converting time:', error);
      return storedTime;
    }
  };

  return (
    <>
      <div style={{ height: 'calc(100dvh - 45px', overflowY: 'scroll' }}>
        {Capacitor.getPlatform() !== 'web' && <MobileSettingsButton isPlanning={true} />}

        <div>
          <div className="account-page-header">
            <Button
              className="account-page-sign-out-button"
              onClick={() => {
                logout(resetAllContexts);
              }}
            >
              Sign Out
            </Button>
          </div>
          <div className="account-page-subheader">General</div>
          <div className="account-page-info">
            <div className="account-page-row" onClick={() => setEditNameDialogOpen(true)}>
              <PersonIcon
                style={{
                  marginRight: '8px',
                  marginLeft: '10px',
                  color: 'rgb(45, 121, 51)',
                }}
              />
              <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>Name</div>
              <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>
                {currentUser.displayName ? currentUser.displayName : ''}
              </div>
            </div>

            <div className="account-page-row" onClick={() => setEditEmailDialogOpen(true)}>
              <EmailIcon
                style={{
                  marginRight: '8px',
                  marginLeft: '10px',
                  color: 'rgb(45, 121, 51)',
                }}
              />
              <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>Email</div>
              <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>
                {currentUser.email ? currentUser.email : ''}
              </div>
            </div>
            <div className="account-page-row" onClick={() => setEditAgeDialogOpen(true)}>
              <CakeIcon
                style={{
                  marginRight: '8px',
                  marginLeft: '10px',
                  color: 'rgb(45, 121, 51)',
                }}
              />
              <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>Age</div>
              <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>
                {userObject.age ? userObject.age + ' years old' : ''}
              </div>
            </div>
            <div className="account-page-row" onClick={() => setEditSexDialogOpen(true)}>
              <AccessibilityNewIcon
                style={{
                  marginRight: '8px',
                  marginLeft: '10px',
                  color: 'rgb(45, 121, 51)',
                }}
              />
              <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>Sex</div>
              <div
                style={{
                  textTransform: 'capitalize',
                  color: 'grey',
                  margin: '0px 10px 0px auto',
                }}
              >
                {userObject.sex ? userObject.sex : ''}
              </div>
            </div>
            <div
              className="account-page-row"
              style={{ border: 'none' }}
              onClick={() => setEditHeightDialogOpen(true)}
            >
              <HeightIcon
                style={{
                  marginRight: '8px',
                  marginLeft: '10px',
                  color: 'rgb(45, 121, 51)',
                }}
              />
              <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>Height</div>
              <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>
                {userObject.heightUnit === 'cm' && userObject.height
                  ? `${userObject.height} cm`
                  : userObject.heightFeet && userObject.heightInches
                    ? `${userObject.heightFeet} ft ${userObject.heightInches} in`
                    : ''}
              </div>
            </div>
          </div>


          <div className="account-page-subheader">Notifications</div>
          <div className="account-page-info">
            <div
              className="account-page-row"
              onClick={() => setEditNotificationsDialogOpen(true)}
            >
              <NotificationsIcon
                style={{
                  marginRight: '8px',
                  marginLeft: '10px',
                  color: 'rgb(45, 121, 51)',
                }}
              />
              <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>
                Tracking reminder
              </div>
              <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>
                {userObject.wantsReminders
                  ? `Enabled at ${userObject.reminderTime}`
                  : 'Disabled'}
              </div>
            </div>
          </div>

          {Capacitor.getPlatform() !== 'web' && (
            <>
              <div className="account-page-subheader">Health Sync</div>
              <div className="account-page-info">
                <div className="account-page-row" onClick={() => setEditHealthSyncDialogOpen(true)}>
                  <SyncIcon
                    style={{
                      marginRight: '8px',
                      marginLeft: '10px',
                      color: 'rgb(45, 121, 51)',
                    }}
                  />
                  <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>
                    Health App Sync
                  </div>
                  <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>
                    {isCheckingPermissions ? 'Checking...' :
                      (healthPermissions.steps || healthPermissions.weight || healthPermissions.bodyFat ? 'Connected' : 'Not connected')}
                  </div>
                </div>
              </div>

            </>
          )}

          <div className="account-page-subheader">Routine & Preferences</div>
          <div className="account-page-info">
            <div className="account-page-row" onClick={() => setEditActivityDialogOpen(true)}>
              <EmojiPeopleIcon
                style={{
                  marginRight: '8px',
                  marginLeft: '10px',
                  color: 'rgb(45, 121, 51)',
                }}
              />
              <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>
                Daily Lifestyle
              </div>
              <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>
                {getActivityTitle(userObject.activityMultiplier)}
              </div>
            </div>
            <div className="account-page-row" onClick={() => setEditCardioDialogOpen(true)}>
              <DirectionsRunIcon
                style={{
                  marginRight: '8px',
                  marginLeft: '10px',
                  color: 'rgb(45, 121, 51)',
                }}
              />
              <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>
                Cardio Exercise
              </div>
              <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>
                {getExerciseLabel(userObject.cardioModifier || 1.0)}
              </div>
            </div>

            <div className="account-page-row" onClick={() => setEditStrengthDialogOpen(true)}>
              <FitnessCenterIcon
                style={{
                  marginRight: '8px',
                  marginLeft: '10px',
                  color: 'rgb(45, 121, 51)',
                }}
              />
              <div style={{ fontFamily: 'Montserrat-Medium', margin: '0 10px 0 0' }}>
                Strength Training
              </div>
              <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>
                {getExerciseLabel(userObject.muscleBuildingModifier || 1.0)}
              </div>
            </div>

            <div
              className="account-page-row"
              style={{ border: 'none' }}
              onClick={() => setEditDietaryDialogOpen(true)}
            >
              <FastfoodIcon
                style={{
                  marginRight: '8px',
                  marginLeft: '10px',
                  color: 'rgb(45, 121, 51)',
                }}
              />
              <div
                style={{
                  fontFamily: 'Montserrat-Medium',
                  textAlign: 'left',
                  margin: '0 10px 0 0',
                }}
              >
                Dietary Preferences
              </div>
              <div style={{ color: 'grey', margin: '0px 10px 0px auto' }}>Tap to edit</div>
            </div>
          </div>
        </div>
      </div>


      {/* Edit Name Dialog */}
      <Dialog open={editNameDialogOpen} onClose={exitDialogs}>
        <div className="edit-info-dialog-container">
          <form onSubmit={changeName}>
            <div className="account-page-subheader" style={{ textAlign: 'center' }}>
              Edit Name
            </div>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon style={{ color: 'grey' }} />
                  </InputAdornment>
                ),
              }}
              defaultValue={currentUser.displayName}
              style={{ width: '100%', margin: '30px 0px 30px 0px' }}
              type="text"
              name="name"
              placeholder="Name"
            />
            <Button disabled={editLoading} type="submit" className="edit-info-button">
              Confirm
            </Button>
          </form>
        </div>
      </Dialog>

      {/* Edit Email Dialog */}
      <Dialog open={editEmailDialogOpen} onClose={exitDialogs}>
        <div className="edit-info-dialog-container">
          <form onSubmit={changeEmail}>
            <div className="account-page-subheader" style={{ textAlign: 'center' }}>
              Edit Email
            </div>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon style={{ color: 'grey' }} />
                  </InputAdornment>
                ),
              }}
              defaultValue={currentUser.email}
              style={{ width: '100%', margin: '30px 0px 30px 0px' }}
              type="email"
              name="email"
              placeholder="Email"
            />
            {emailError && <div className="edit-info-dialog-error">{emailError}</div>}
            <Button disabled={editLoading} type="submit" className="edit-info-button">
              Confirm
            </Button>
          </form>
        </div>
      </Dialog>

      {/* Edit Age Dialog */}
      <Dialog open={editAgeDialogOpen} onClose={exitDialogs}>
        <div className="edit-info-dialog-container">
          <form onSubmit={changeAge}>
            <div className="account-page-subheader" style={{ textAlign: 'center' }}>
              Edit Age
            </div>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CakeIcon style={{ color: 'grey' }} />
                  </InputAdornment>
                ),
              }}
              defaultValue={userObject.age}
              style={{ width: 'calc(100% - 40px)', margin: '30px 20px 30px 20px' }}
              type="number"
              name="age"
              placeholder="Age"
              error={!!ageError}
              helperText={ageError}
            />
            <Button disabled={editLoading} type="submit" className="edit-info-button">
              Confirm
            </Button>
          </form>
        </div>
      </Dialog>

      {/* Edit Sex Dialog */}
      <Dialog open={editSexDialogOpen} onClose={exitDialogs}>
        <div className="edit-info-dialog-container" style={{ padding: '10px' }}>
          <div className="account-page-subheader" style={{ textAlign: 'center' }}>
            Edit Sex
          </div>
          <div style={{ margin: '20px 20px' }}>
            <Select defaultValue={userObject.sex} native onChange={changeSex}>
              <option value="" disabled>
                Select sex
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </Select>
          </div>
          <Button disabled={editLoading} onClick={exitDialogs} className="edit-info-button">
            Confirm
          </Button>
        </div>
      </Dialog>

      {/* Edit Height Dialog */}
      <Dialog open={editHeightDialogOpen} onClose={exitDialogs}>
        <div className="edit-info-dialog-container">
          <form onSubmit={changeHeight}>
            <div className="account-page-subheader" style={{ textAlign: 'center' }}>
              Edit Height
            </div>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HeightIcon style={{ color: 'grey' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Select
                      name="heightUnit"
                      value={userObject.heightUnit || 'inches'}
                      onChange={handleHeightUnitChange}
                      disableUnderline
                    >
                      <MenuItem value="cm">cm</MenuItem>
                      <MenuItem value="inches">inches</MenuItem>
                    </Select>
                  </InputAdornment>
                ),
              }}
              defaultValue={
                userObject.heightUnit === 'cm' ? userObject.height : userObject.heightFeet
              }
              style={{ width: '100%', margin: '20px 0px 10px 0px' }}
              type="number"
              name={userObject.heightUnit === 'cm' ? 'height' : 'heightFeet'}
              placeholder={userObject.heightUnit === 'cm' ? 'Height (cm)' : 'Feet'}
              onChange={handleHeightChange}
            />
            {userObject.heightUnit !== 'cm' && (
              <TextField
                defaultValue={userObject.heightInches}
                style={{ width: '100%', margin: '10px 0px 20px 0px' }}
                type="number"
                name="heightInches"
                placeholder="Inches"
                onChange={handleHeightChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HeightIcon style={{ color: 'grey' }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <Button disabled={editLoading} type="submit" className="edit-info-button">
              Confirm
            </Button>
          </form>
        </div>
      </Dialog>

      {/* Edit Activity Dialog */}
      <Dialog open={editActivityDialogOpen} onClose={exitDialogs}>
        <div className="ap-edit-info-dialog-container">
          <div className="account-page-subheader" style={{ textAlign: 'center' }}>
            Edit Daily Lifestyle
          </div>
          <div className="ap-activity-options">
            {Object.entries(lifestyleExamples).map(([level, data]) => (
              <div
                key={level}
                onClick={() => {
                  setUserObject({ ...userObject, activityMultiplier: parseFloat(level) });
                  exitDialogs();
                }}
                className={`ap-activity-option ${userObject.activityMultiplier === parseFloat(level) ? 'ap-selected' : ''
                  }`}
                style={{
                  padding: '15px',
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  backgroundColor: userObject.activityMultiplier === parseFloat(level)
                    ? 'rgba(76, 184, 133, 0.1)'
                    : 'white'
                }}
              >
                <div className="ap-activity-title">{data.title}</div>
                <div className="ap-activity-description">{data.description}</div>
                <div style={{ marginTop: '8px', display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                  {data.examples.map((example, i) => (
                    <span key={i} className="ap-example-tag">
                      {example}
                    </span>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Dialog>

      <Dialog open={editCardioDialogOpen} onClose={exitDialogs}>
        <div className="ap-edit-info-dialog-container">
          <div className="account-page-subheader" style={{ textAlign: 'center' }}>
            Edit Cardio Exercise
          </div>
          <div className="ap-activity-options">
            {exerciseFrequencies.map((option) => (
              <div
                key={option.value}
                onClick={() => {
                  setUserObject({ ...userObject, cardioModifier: option.value });
                  exitDialogs();
                }}
                className={`ap-activity-option ${userObject.cardioModifier === option.value ? 'ap-selected' : ''
                  }`}
                style={{
                  padding: '15px',
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  backgroundColor: userObject.cardioModifier === option.value
                    ? 'rgba(76, 184, 133, 0.1)'
                    : 'white'
                }}
              >
                <div className="ap-activity-title">{option.label}</div>
                <div className="ap-activity-description">{option.description}</div>
              </div>
            ))}
          </div>
        </div>
      </Dialog>

      {/* Add new Strength Training Dialog */}
      <Dialog open={editStrengthDialogOpen} onClose={exitDialogs}>
        <div className="ap-edit-info-dialog-container">
          <div className="account-page-subheader" style={{ textAlign: 'center' }}>
            Edit Strength Training
          </div>
          <div className="ap-activity-options">
            {exerciseFrequencies.map((option) => (
              <div
                key={option.value}
                onClick={() => {
                  setUserObject({ ...userObject, muscleBuildingModifier: option.value });
                  exitDialogs();
                }}
                className={`ap-activity-option ${userObject.muscleBuildingModifier === option.value ? 'ap-selected' : ''
                  }`}
                style={{
                  padding: '15px',
                  margin: '10px 0',
                  border: '1px solid #eee',
                  borderRadius: '8px',
                  cursor: 'pointer',
                  backgroundColor: userObject.muscleBuildingModifier === option.value
                    ? 'rgba(76, 184, 133, 0.1)'
                    : 'white'
                }}
              >
                <div className="ap-activity-title">{option.label}</div>
                <div className="ap-activity-description">{option.description}</div>
              </div>
            ))}
          </div>
        </div>
      </Dialog>

      {/* Edit Dietary Preferences Dialog */}
      <Dialog open={editDietaryDialogOpen} onClose={exitDialogs}>
        <div className="edit-info-dialog-container">
          <DietaryPreferences userObject={userObject} setUserObject={setUserObject} />
          <Button disabled={editLoading} onClick={exitDialogs} className="edit-info-button">
            Close
          </Button>
        </div>
      </Dialog>

      {/* Edit Notifications Dialog */}
      <Dialog open={editNotificationsDialogOpen} onClose={exitDialogs}>
        <div className="edit-info-dialog-container">
          <div className="account-page-subheader" style={{ textAlign: 'center' }}>
            Notification Settings
          </div>

          <div style={{ margin: '10px 20px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <FormControlLabel
              control={
                <Switch
                  style={{ color: userObject.wantsReminders ? 'rgb(45, 121, 51)' : "grey" }}
                  checked={userObject.wantsReminders || false}
                  onChange={(e) =>
                    setUserObject({ ...userObject, wantsReminders: e.target.checked })
                  }
                  name="wantsReminders"
                />
              }
              label="Remind me to track my food"
            />
            <br />
            {userObject.wantsReminders && (
              <TextField
                label="Reminder Time"
                type="time"
                style={{ width: '130px' }}
                value={getLocalTime(userObject.reminderTime)}
                onChange={(e) => {
                  // Store time in original timezone
                  setUserObject({ ...userObject, reminderTime: e.target.value })
                }}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }}
              />
            )}
          </div>
          <Button onClick={changeNotificationSettings} className="edit-info-button">
            Confirm
          </Button>
        </div>
      </Dialog>

      <Dialog open={editHealthSyncDialogOpen} onClose={() => setEditHealthSyncDialogOpen(false)}>
        <div className="edit-info-dialog-container">
          <div className="account-page-subheader" style={{ textAlign: 'center' }}>
            Health App Integration
          </div>

          <div style={{ margin: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={healthPermissions.steps}
                  onChange={() => handleHealthPermissionChange('steps')}
                  style={{
                    color: healthPermissions.steps ? 'rgb(45, 121, 51)' : 'grey'
                  }}
                />
              }
              label={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>Sync Steps</span>
                  <span style={{ fontSize: '12px', color: 'grey' }}>
                    Automatically import steps from Health App
                  </span>
                </div>
              }
            />

            <FormControlLabel
              control={
                <Switch
                  checked={healthPermissions.weight}
                  onChange={() => handleHealthPermissionChange('weight')}
                  style={{
                    color: healthPermissions.weight ? 'rgb(45, 121, 51)' : 'grey'
                  }}
                />
              }
              label={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>Sync Weight</span>
                  <span style={{ fontSize: '12px', color: 'grey' }}>
                    Automatically import weight data from Health App
                  </span>
                </div>
              }
            />

            <FormControlLabel
              control={
                <Switch
                  checked={healthPermissions.bodyFat}
                  onChange={() => handleHealthPermissionChange('bodyFat')}
                  style={{
                    color: healthPermissions.bodyFat ? 'rgb(45, 121, 51)' : 'grey'
                  }}
                />
              }
              label={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span>Sync Body Fat</span>
                  <span style={{ fontSize: '12px', color: 'grey' }}>
                    Automatically import body fat percentage from Health App
                  </span>
                </div>
              }
            />
          </div>

          <Button onClick={() => setEditHealthSyncDialogOpen(false)} className="edit-info-button">
            Close
          </Button>
        </div>
      </Dialog>

    </>
  );
};

export default AccountPage;