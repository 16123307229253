import './recipes_page.css'
import React from "react";
import { AuthContext } from 'contexts/auth_context';
import { CartContext } from 'contexts/cart_context';
import RecipeBookList from 'components/shared/recipe_book_list/recipe_book_list';

import { Capacitor } from '@capacitor/core';
import MobileSettingsButton from 'components/shared/mobile_settings_button/mobile_settings_button';

const RecipesPage = () => {

    return (<><div className="contact-us-page-top"  style={{minHeight:"calc(100% - 45px)"}} >

        <div className="recipes-page">
       { Capacitor.getPlatform() !== "web" && <MobileSettingsButton />}
            <div className="recipes-page-header">Recipes</div>
            <div className="recipes-page-columns">
                <div className="recipes-page-orders">

                    {/* <div className="recipes-page-subheading">Recipes</div> */}
                    <RecipeBookList />

                </div>


            </div>
        </div>
    </div></>)

}

export default RecipesPage;