import './App.css';

import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

// import FoodMenu from './components/pages/food_menu/food_menu';
// import Cart from './components/pages/cart/cart';
// import Checkout from './components/pages/checkout/checkout';

import { UserContextProvider } from './contexts/user_context';
import { CartContextProvider } from './contexts/cart_context';
import { MenuContextProvider } from 'contexts/menu_context';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

// import { loadStripe } from '@stripe/stripe-js';
// import { Elements } from '@stripe/react-stripe-js';

import MessageIcon from '@material-ui/icons/Message';



import {
  createMuiTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";

import ContactUs from 'components/pages/contact_us/contact_us';

import { NavLink } from 'react-router-dom';
import { AuthContextProvider } from 'contexts/auth_context';
import PrivateRoute from 'components/shared/private-route/private-route';
import Login from 'components/pages/login/login';
import CalendarPage from 'components/pages/calendar_page/calendar_page';
import AccountPage from 'components/pages/account_page/account_page';
import { RecipeBooksContextProvider } from 'contexts/recipe_books_context';
import Instacart from 'components/pages/instacart/instacart';
import SignUp from 'components/pages/sign_up/sign_up';



import { DndProvider, TouchTransition, MouseTransition } from 'react-dnd-multi-backend'
import { LandingDesktop } from 'components/pages/landing/landing_desktop';
import { LandingMobile } from 'components/pages/landing/landing_mobile';
import { LandingTablet } from 'components/pages/landing/landing_tablet';
import Blog from 'components/pages/blog/blog';
import RecipesPage from 'components/pages/recipes_page/recipes_page';
import { Capacitor } from '@capacitor/core'
import Navigation from 'components/shared/navigation/navigation';
import OrderIngredientsPage from 'components/pages/order_ingredients_page/order_ingredients_page';
import { LandingDesktopSimple } from 'components/pages/landing/landing_desktop_simple';
import { LandingMobileSimple } from 'components/pages/landing/landing_mobile_simple';
import { LandingDesktopSimplePlanning } from 'components/pages/landing/landing_desktop_simple_planning';
import { LandingMobileSimplePlanning } from 'components/pages/landing/landing_mobile_simple_planning';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { firebaseConfig } from './firebase';
import DashboardPage from 'components/pages/dashboard_page/dashboard_page';
import PlanningPage from 'components/pages/planning_page/planning_page';
import AIConsultationPage from 'components/pages/ai_consultation_page/ai_consultation_page';
import PrivateAdminRoute from 'components/shared/private-route/private-route-admin';
import AdminPortal from 'components/pages/admin_portal/admin_portal';
import WeeklyReportPage from 'components/pages/weekly_report_page/weekly_report_page';
import AdvancedLogin from 'components/pages/advanced_login/advanced_login';
import { UserLoaderProvider } from 'components/shared/user_loader/user_loader';
import BannerSystem from 'components/shared/banner_system/banner_system';




// const stripePromise = loadStripe(mainConfig.stripePublicKey);

const theme = createMuiTheme({
  typography: {
    "fontFamily": `"Montserrat", "Helvetica", "Arial", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  },
  palette: {
    primary: {
      main: '#F9F9F9'
    },
    secondary: {
      main: '#000000'
    },
    tertiary: {
      main: '#000000'
    },
    red: 'red'
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "12px",
      }
    },
    MuiButton: {
      textPrimary: {
        color: 'black',
      }
    },
  }
});

const App = () => {
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  const [isSlowConnection, setIsSlowConnection] = React.useState(false);
  const [windowSize, setWindowSize] = React.useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  React.useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    const checkConnectionSpeed = () => {
      if (navigator.connection) {
        const connection = navigator.connection;
        setIsSlowConnection(
          connection.effectiveType === '2g' ||
          connection.effectiveType === 'slow-2g' ||
          connection.downlink < 0.5
        );
      }
    };

    // Clear badge on initial mount and when app becomes active
   

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    window.addEventListener('resize', handleWindowResize);

    if (navigator.connection) {
      navigator.connection.addEventListener('change', checkConnectionSpeed);
      checkConnectionSpeed();
    }

    if (Capacitor.getPlatform() === 'web') {
      FirebaseAnalytics.initializeFirebase(firebaseConfig);
    }

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('resize', handleWindowResize);
      if (navigator.connection) {
        navigator.connection.removeEventListener('change', checkConnectionSpeed);
      }
    };
  }, []);


  let HTML5toTouch;
  if (windowSize[0] > 750) {
    HTML5toTouch = {
      backends: [
        {
          id: 'html5',
          backend: HTML5Backend,
          transition: MouseTransition,
        },
        {
          id: 'touch',
          backend: TouchBackend,
          options: { enableMouseEvents: true, delayTouchStart: 35 },
          preview: true,
          transition: TouchTransition,
        },
      ],
    }
  }
  else {
    HTML5toTouch = {
      backends: [
        {
          id: 'html5',
          backend: HTML5Backend,
          transition: MouseTransition,
        },
      ],
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <DndProvider options={HTML5toTouch}>
        <AuthContextProvider>
          <UserContextProvider>
            <RecipeBooksContextProvider>
              <MenuContextProvider>
                <CartContextProvider>
                  <UserLoaderProvider>
                    <BrowserRouter>
                      <div className="body-inner" style={{ height: '100%' }}>
                        <Navigation></Navigation>
                        <Switch>
                          <Route path="/landing" component={windowSize[0] > 1400 ? LandingDesktopSimple : windowSize[0] > 950 ? LandingTablet : LandingMobileSimple} exact />
                          <Route path="/home" component={windowSize[0] > 1400 ? LandingDesktopSimplePlanning : windowSize[0] > 950 ? LandingTablet : LandingMobileSimplePlanning} exact />

                          <Route path="/" component={Capacitor.getPlatform() !== "web" ? SignUp : windowSize[0] > 1400 ? LandingDesktop : windowSize[0] > 950 ? LandingTablet : LandingMobile} exact />
                          <Route path="/blog" component={Blog} exact />

                          {/* <PrivateRoute path="/food_menu/" component={FoodMenu} exact />
                            <PrivateRoute path="/food_menu/:id" component={FoodMenu} /> */}
                          <PrivateRoute path="/calendar" component={CalendarPage} exact />
                          {/* <PrivateRoute path="/cart" component={Cart} exact />
                            <PrivateRoute path="/checkout" component={Checkout} exact /> */}
                          <Route path="/contact_us" component={ContactUs} exact />
                          <Route path="/instacart/:id" component={Instacart} exact />
                          <Route path="/login" component={Login} exact />
                          <Route path="/signup" component={SignUp} exact />
                          <PrivateRoute path="/advanced_login" component={AdvancedLogin} exact />
                          <PrivateRoute path="/recipes" component={RecipesPage} exact />
                          <PrivateRoute path="/planning" component={PlanningPage} exact />
                          <PrivateAdminRoute path="/admin" component={AdminPortal} exact />
                          <PrivateRoute path="/order_ingredients" component={OrderIngredientsPage} exact />
                          <PrivateRoute path="/account" component={AccountPage} exact />
                          <PrivateRoute path="/dashboard" component={DashboardPage} exact />
                          <PrivateRoute path="/weekly_report" component={WeeklyReportPage} exact />
                          <PrivateRoute path="/ai_consultation" component={AIConsultationPage} exact />

                          <Route path="/exercise-calories" component={() => {
                            window.location.href = "https://thebitetracker.com/blog?post=how-does-the-bitetracker-app-estimate-calorie-burn";
                            return null;
                          }} exact />

                          <Route path="/exercise-points" component={() => {
                            window.location.href = "https://thebitetracker.com/blog?post=bitetrackers-activity-points-system-a-holistic-view-of-fitness";
                            return null;
                          }} exact />

                          <Route component={Error} />
                        </Switch>
                        <NavLink style={{ color: 'white' }} to="/contact_us">
                          <div className="contact-us-bottom-right">
                            <MessageIcon />
                            <span style={{ marginLeft: '10px' }}>Contact Us</span>
                          </div>
                        </NavLink>

                        <BannerSystem isOnline={isOnline} />

                        {/* Offline/Slow Connection Banner */}
                        {(isOnline === false || isSlowConnection) && (
                          <div style={{
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: 'rgb(46 110 77)',
                            color: 'white',
                            padding: '6px 16px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            zIndex: 9999,
                            fontSize: '12px',
                            backdropFilter: 'blur(10px)',
                            margin: '0 16px',
                            width: 'calc(100% - 64px)',
                            borderRadius: '10px',
                            bottom: '60px'
                          }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <span style={{ marginRight: '8px' }}>ⓘ</span>
                              <span>No internet connection</span>
                            </div>
                            <button
                              onClick={() => window.location.reload()}
                              style={{
                                backgroundColor: 'transparent',
                                border: 'none',
                                color: 'white',
                                padding: '4px 0',
                                cursor: 'pointer',
                                fontSize: '12px',
                                fontFamily: 'Montserrat-SemiBold'
                              }}
                            >
                              Try again
                            </button>
                          </div>
                        )}
                      </div>
                    </BrowserRouter>
                  </UserLoaderProvider>
                </CartContextProvider>
              </MenuContextProvider>
            </RecipeBooksContextProvider>
          </UserContextProvider>
        </AuthContextProvider>
      </DndProvider>
    </MuiThemeProvider>
  );
}


export default App;
