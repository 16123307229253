import './navigation.css'

import React from 'react';
// import Badge from '@material-ui/core/Badge';
// import IconButton from '@material-ui/core/IconButton';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { NavLink } from 'react-router-dom';
import { CartContext } from 'contexts/cart_context';
import { useLocation } from 'react-router-dom';
import { Popover } from '@material-ui/core';
import NavigationCartHover from './navigation_cart_hover';
import { AuthContext } from 'contexts/auth_context';

import { Capacitor } from '@capacitor/core';

import LogoWide from 'images/image-2.png'
import RecipesIconWeb from 'images/add-to-plan-icon.png'
import CalendarIconWeb from 'images/add-to-calendar-icon.png'
import LogoNarrow from 'images/BiteTracker-App-Icon.png'
import SettingsSidebar from '../settings_sidebar/settings_sidebar';



const Navigation = () => {
   const cartObject = React.useContext(CartContext).cartObject;
   const [cartHoveredAnchorEl, setCartHoveredAnchorEl] = React.useState(null)
   const [accountDialogOpenAnchorEl, setAccountDialogOpenAnchorEl] = React.useState(null)
   const location = useLocation()
   const { currentUser } = React.useContext(AuthContext)

   const [windowSize, setWindowSize] = React.useState([
      window.innerWidth,
      window.innerHeight,
   ]);


   React.useEffect(() => {
      const handleWindowResize = () => {
         setWindowSize([window.innerWidth, window.innerHeight]);
      };

      window.addEventListener('resize', handleWindowResize);

      return () => {
         window.removeEventListener('resize', handleWindowResize);
      };
   }, []);



   // function handleCartHover(event) {
   //    setCartHoveredAnchorEl(event.currentTarget);
   // }

   function handleCartHoverExit() {
      setCartHoveredAnchorEl(null);
   }

   function openAccountPopover(event) {
      setAccountDialogOpenAnchorEl(event.currentTarget)
   }

   function exitAccountPopover() {
      setAccountDialogOpenAnchorEl(null);
   }




   let mealCounter = 0
   for (let meal in cartObject) {
      if (cartObject[meal].type === "ready-made") {
         mealCounter += 1
      }
   }
   if (location.pathname === "/login" || location.pathname === "/signup") {
      if (Capacitor.getPlatform() !== "web") {
         return (<></>)
      }
   }

   if (["/", "/landing", "/home", "/instacart"].includes(location.pathname)) {
      return (<></>)
   }

   let navigation = <></>

   if (Capacitor.getPlatform() !== "web" && currentUser) {
      navigation = <div className="navigation-app">
         <div className="navigation-app-section">
     
               <NavLink className="navigation-app-link" to="/planning">
                     <img
                        alt="recipes"
                        className={
                           location.pathname === "/planning"
                              ? "navigation-app-calendar-image"
                              : "navigation-app-calendar-image-inactive"
                        }
                        src='recipes-icon.png'
                     />
                     <span
                        className={`navigation-label ${location.pathname === "/planning" ? "active" : ""
                           }`}
                     >
                        Planning
                     </span>
               </NavLink>
            
         </div>

         <div className="navigation-app-section">
            <NavLink className="navigation-app-link" to="/calendar">
               <img
                  alt="calendar"
                  className={
                     location.pathname === "/calendar"
                        ? "navigation-app-calendar-image"
                        : "navigation-app-calendar-image-inactive"
                  }
                  src='calendar-icon-2.png'
               />
               <span
                  className={`navigation-label ${location.pathname === "/calendar" ? "active" : ""
                     }`}
               >
                  Tracking
               </span>
            </NavLink>
         </div>
      </div>



   }

   else {
      navigation = <div className="navigation">
         <NavLink className="navigation-link" to={currentUser ? "/calendar" : "/"}>
            {windowSize[0] > 500 ? <img style={{ marginTop: '5px' }} className="navigation-logo" alt="logo" src={LogoWide} /> :
               <img style={{ marginTop: '5px' }} className="navigation-logo-mobile" alt="logo" src={LogoNarrow} />
            }
         </NavLink>
         <div className="navigation-right">
            {currentUser && <NavLink className="navigation-link" to="/planning"><img alt="recipes" className="navigation-calendar-image" src={RecipesIconWeb} /> {location.pathname === "/planning" ? <b>Planning</b> : "Planning"}</NavLink>}

            {currentUser && <NavLink className="navigation-link" to="/calendar"><img alt="calendar" className="navigation-calendar-image" src={CalendarIconWeb} /> {location.pathname === "/calendar" ? <b>Tracking</b> : "Tracking"}</NavLink>}


            {currentUser ? <button className="navigation-button" onClick={openAccountPopover} > <><PersonOutlineIcon style={{ color: '#2D7933', marginBottom: '1px', marginRight: '3px', fontSize: '27px', opacity: 0.8 }} />
               {currentUser.displayName && windowSize[0] > 500 ? currentUser.displayName.split(" ")[0] : ""}</></button> : <NavLink className="navigation-link" to="/account">{location.pathname === "/account" ? <b>Sign in</b> : "Login"}</NavLink>}

            {/* <NavLink className="navigation-cart" to="/cart" onMouseEnter={handleCartHover} onMouseLeave={handleCartHoverExit}>
            <IconButton aria-label="cart">
               <Badge badgeContent={mealCounter} color="error" style={{
                  padding: '3px 0px'
               }}>
                  <ShoppingCartIcon />
               </Badge>
               &nbsp;{location.pathname === "/cart" ? <b>CART</b> : "CART"}&nbsp;
            </IconButton>

         </NavLink> */}
         </div>

      </div>
   }

   return (<> {navigation}

      {cartHoveredAnchorEl && mealCounter > 0 &&
         <Popover style={{ pointerEvents: 'none' }} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'right', }}
            id="mouse-over-popover" open={Boolean(cartHoveredAnchorEl)} anchorEl={cartHoveredAnchorEl} onClose={handleCartHoverExit} disableRestoreFocus>
            <NavigationCartHover />
         </Popover>
      }


      <SettingsSidebar open={accountDialogOpenAnchorEl} close={exitAccountPopover} />


   </>
   );
}

export default Navigation;