import React, { useState, useContext } from 'react';
import { UserContext } from 'contexts/user_context';
import './weight_tracker_card.css';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import InfoIcon from '@material-ui/icons/Info';
import { Dialog, IconButton, Tooltip } from '@material-ui/core';
import LogWeight from '../log_weight/log_weight';
import WeightOverTimeGraph from '../graphs/weight_over_time_graph';
import { useHistory } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import ScaleIcon from 'images/LogWeight.png';
import EditIcon from '@material-ui/icons/Edit';

const WeightTrackerCard = ({ calendarDate }) => {
    const history = useHistory();
    const { userObject } = useContext(UserContext);
    const [logWeightDialogOpen, setLogWeightDialogOpen] = useState(false);
    const [weightGraphDialogOpen, setWeightGraphDialogOpen] = useState(false);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);

    const isMobile = Capacitor.getPlatform() !== 'web';
    const hasHealthSync = userObject?.healthSync?.weight;

    // Format calendarDate to match weight object format (M D YYYY)
    const formatDateForWeightLookup = (date) => {
        const d = new Date(date);
        return `${d.getMonth() + 1} ${d.getDate()} ${d.getFullYear()}`;
    };

    // Get weight from userObject using formatted date
    const formattedDate = formatDateForWeightLookup(calendarDate);
    const rawWeight = userObject?.weight?.[formattedDate];
    const weight = rawWeight || null;
    const isHealthData = typeof weight === 'object' && weight?.source === 'healthKit';

    const handleLogWeightSubmit = () => {
        setLogWeightDialogOpen(false);
        setWeightGraphDialogOpen(true);
    };

    return (
        <div className="weight-tracker-card">
            {/* Header */}
         
       

            {/* Main Content */}
            <div 
                className={`weight-empty-state ${weight ? 'has-weight' : ''}`}
                onClick={() => weight ? setWeightGraphDialogOpen(true) : setLogWeightDialogOpen(true)}
            >
                <img
                    alt="scale"
                    style={{ height:'42px',padding: '0px 0px', margin: '0px 14px' }}
                    src={ScaleIcon}
                />
                <div >
                    <div className="empty-state-title" style={{marginTop:'3px'}}>
                        {weight ? `${Number(weight.value).toFixed(1)} ${weight.unit}` : 'Log Weight'}
                    </div>
                    <div className="empty-state-subtitle">
                        
                    {weight ? `View your progress` : 'Track your progress'}
                    </div>
                </div>
                {weight && (
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setLogWeightDialogOpen(true);
                        }}
                        size="small"
                        style={{ padding: '4px', marginRight: '8px', marginLeft: 'auto' }}
                    >
                        <EditIcon fontSize="small" style={{ color: '#666' }} />
                    </IconButton>
                )}
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setInfoDialogOpen(true);
                    }}
                    size="small"
                    style={{ padding: '4px', marginRight:'18px', marginLeft: weight ? '0' : 'auto' }}
                >
                    <InfoIcon fontSize="small" style={{ color: '#666' }} />
                </IconButton>
            </div>

            {/* Dialogs */}
            <Dialog open={logWeightDialogOpen} maxWidth="sm" onClose={() => setLogWeightDialogOpen(false)}>
                <LogWeight
                    calendarDate={calendarDate}
                    handleLogWeightExit={handleLogWeightSubmit}
                />
            </Dialog>

            <Dialog open={weightGraphDialogOpen} maxWidth="sm" onClose={() => setWeightGraphDialogOpen(false)}>
                <div className="weight-graph-dialog">
                    <div className="dialog-title">Your weight over time</div>
                    <WeightOverTimeGraph />
                    <div className="ai-consultation-prompt">
                        We recommend reviewing your macros and progress with our AI consultant weekly
                    </div>
                    <button
                        className="consult-button"
                        onClick={() => history.push('ai_consultation')}
                    >
                        Consult with AI
                    </button>
                </div>
            </Dialog>

            <Dialog open={infoDialogOpen} onClose={() => setInfoDialogOpen(false)}>
                <div className="info-dialog">
                    <div className="dialog-title">About Weight Tracking</div>
                    <div className="dialog-content">
                        <p>
                            Regular weight tracking helps you stay accountable and monitor your progress.
                            We recommend weighing yourself at the same time each day for consistency.
                        </p>
                        {isMobile && (
                            <p>
                                You can manually log your weight or enable Health App sync to automatically
                                import your weight measurements.
                            </p>
                        )}
                        <p>
                            Our AI consultant uses your weight trends along with other data to provide
                            personalized recommendations for your nutrition and fitness goals.
                        </p>
                    </div>
                    <button
                        className="dialog-close-button"
                        onClick={() => setInfoDialogOpen(false)}
                    >
                        Close
                    </button>
                </div>
            </Dialog>
        </div>
    );
};

export default WeightTrackerCard; 