import './SwipeableItem.css';
import React, { useContext, useState, useRef, useEffect } from 'react';

import { CartContext } from 'contexts/cart_context';
import Button from '@material-ui/core/Button';
import CaloriesBadge from 'images/calories_badge.png';
import ContentCopy from 'images/content_copy.svg';

import MealDetailsCard from '../meal_details_card/meal_details_card';
import { Dialog, Tooltip, List, ListItem, ListItemText, DialogTitle, DialogContent } from '@material-ui/core';
import { decimalToFraction, getMealNutrientAmount } from 'helpers/recipe_helpers';
import _ from 'lodash';
import CopyMealsToDay from '../copy_meals_to_day/copy_meals_to_day';
import DeleteIcon from '@material-ui/icons/Delete';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

// Add macro units and styles
const macroUnits = {
  Protein: 'g',
  Carbohydrates: 'g',
  Fat: 'g',
  Sodium: 'mg',
  Cholesterol: 'mg',
  Sugar: 'g',
  Fiber: 'g',
  'Net Carbohydrates': 'g',
  'Saturated Fat': 'g',
};

const macroStyles = {
  Protein: { background: '#24C581' },
  Carbohydrates: { background: '#25ABFF' },
  Fat: { background: '#FF802C' },
  Sodium: { background: '#9F48E2' },
  Cholesterol: { background: '#E751CF' },
  Sugar: { background: '#86D1FF' },
  Fiber: { background: '#8D9FFF' },
  'Net Carbohydrates': { background: '#2344BB' },
  'Saturated Fat': { background: '#C72100' },
};

const CalendarMealNoDrag = (props) => {
  const deleteMeal = useContext(CartContext).deleteMeal;
  const setCartMeal = useContext(CartContext).setCartMeal;
  const addOrMoveMealToColumn = useContext(CartContext).addOrMoveMealToColumn;

  const [copyToDayDialogOpen, setCopyToDayDialogOpen] = useState(false);
  const [moveMealDialogOpen, setMoveMealDialogOpen] = useState(false);

  const getMealAttributeWithOptions = useContext(CartContext).getMealAttributeWithOptions;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [portion, setPortion] = useState(props.cartMeal.portion ? props.cartMeal.portion : 1);
  const [swipeDistance, setSwipeDistance] = useState(0);
  const startXRef = useRef(null);
  const startYRef = useRef(null);
  const isHorizontalSwipeRef = useRef(null);

  const childRef = useRef(null);
  const parentRef = useRef(null);
  const [parentHeight, setParentHeight] = useState(0);

  // Define meal categories
  const mealCategories = [
    { id: 'breakfast', name: 'Breakfast' },
    { id: 'lunch', name: 'Lunch' },
    { id: 'dinner', name: 'Dinner' },
    { id: 'snack', name: 'Snack' }
  ];

  // Let's add a state to track if we're in "action mode"
  const [isActionMode, setIsActionMode] = useState(false);

  // First, let's add a ref for the action buttons
  const actionButtonsRef = useRef(null);

  useEffect(() => {
    if (childRef.current) {
      const childHeight = childRef.current.offsetHeight;
      setParentHeight(childHeight);
    }
  }, [props.cartMeal]); // Update when props.cartMeal changes

  useEffect(() => {
    if (parentRef.current) {
      parentRef.current.style.height = `${parentHeight}px`;
    }
  }, [parentHeight]);

  function openCopyDaysDialog() {
    setCopyToDayDialogOpen(true);
  }

  function exitCopyDaysDialog() {
    setCopyToDayDialogOpen(false);
  }

  function openMoveMealDialog(event) {
    setMoveMealDialogOpen(true);
    event.preventDefault();
    event.stopPropagation();
  } 

  function closeMoveMealDialog() {
    setMoveMealDialogOpen(false);
  }

  function handleMoveMeal(category) {
    // Only move if it's a different category
    if (category !== props.cartMeal.calendarCategory) {
      // Use the existing addOrMoveMealToColumn function to move the meal
      addOrMoveMealToColumn(
        props.cartMeal.calendarDate, 
        props.cartMeal, 
        category
      );
    }
    closeMoveMealDialog();
  }

  function handleDeleteClick(event) {
    deleteMeal(props.cartMeal);
    event.stopPropagation();
  }

  function handleDialogOpen(event) {
    if (event.target.tagName !== 'SPAN' && event.target.id !== 'calendar-meal-duplicate') {
      setIsDialogOpen(true);
    }
  }

  function handleDialogClose() {
    setIsDialogOpen(false);
  }

  const setMealAndRecalculateCalories = (meal) => {
    for (let mealNutrient of meal.nutrition.nutrients) {
      mealNutrient['amount'] = 0;
      for (let ingredient of meal.ingredients) {
        for (let ingredientNutrient of ingredient.nutrition.nutrients) {
          if (mealNutrient.name === ingredientNutrient.name) {
            if (ingredientNutrient['amount'] && ingredient['amount']) {
              mealNutrient['amount'] += ingredientNutrient['amount'] * ingredient['amount'];
            }
          }
        }
      }
    }
    setCartMeal(meal);
  };

  useEffect(() => {
    if (portion !== props.cartMeal.portion) {
      setPortion(props.cartMeal.portion);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cartMeal]);

  useEffect(() => {
    const updatePortion = setTimeout(() => {
      if (props.cartMeal.portion === portion) {
        return;
      }

      let mealClone = _.cloneDeep(props.cartMeal);

      if (mealClone.areNutrientsManual) {
        for (let mealNutrient of mealClone.nutrition.nutrients) {
          if (mealNutrient.name === 'Calories') {
            mealNutrient.amount =
              Math.round((mealNutrient.amount * portion) / props.cartMeal.portion * 10) / 10;
          }
          if (mealNutrient.name === 'Fat') {
            mealNutrient.amount =
              Math.round((mealNutrient.amount * portion) / props.cartMeal.portion * 10) / 10;
          }
          if (mealNutrient.name === 'Carbohydrates') {
            mealNutrient.amount =
              Math.round((mealNutrient.amount * portion) / props.cartMeal.portion * 10) / 10;
          }
          if (mealNutrient.name === 'Protein') {
            mealNutrient.amount =
              Math.round((mealNutrient.amount * portion) / props.cartMeal.portion * 10) / 10;
          }
        }
        setCartMeal({ ...mealClone, portion: portion });
      } else {
        if (props.cartMeal.portion) {
          for (let ingredient of mealClone.ingredients) {
            ingredient.amountString = decimalToFraction(
              (ingredient.amount * portion) / props.cartMeal.portion
            );
            ingredient.amount = (ingredient.amount * portion) / props.cartMeal.portion;
          }
        } else {
          for (let ingredient of mealClone.ingredients) {
            ingredient.amountString = decimalToFraction(ingredient.amount * portion);
            ingredient.amount = ingredient.amount * portion;
          }
        }
        setMealAndRecalculateCalories({ ...mealClone, portion: portion });
      }
    }, 100);
    return () => clearTimeout(updatePortion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portion]);

  let cals = getMealNutrientAmount(props.cartMeal, 'Calories');

  // Get the selected macros from props
  const { selectedMacros } = props;

  const handleTouchStart = (e) => {
    startXRef.current = e.touches[0].clientX;
    startYRef.current = e.touches[0].clientY;
    setSwipeDistance(0);
    isHorizontalSwipeRef.current = null;
  };

  const handleTouchMove = (e) => {
    if (startXRef.current === null || startYRef.current === null) return;
  
    const currentX = e.touches[0].clientX;
    const currentY = e.touches[0].clientY;
    const deltaX = currentX - startXRef.current;
    const deltaY = currentY - startYRef.current;
  
    if (isHorizontalSwipeRef.current === null && (Math.abs(deltaX) > 10 || Math.abs(deltaY) > 10)) {
      isHorizontalSwipeRef.current = Math.abs(deltaX) > Math.abs(deltaY);
    }
  
    if (isHorizontalSwipeRef.current) {
      e.preventDefault();
      e.stopPropagation();
      
      if (isActionMode && deltaX > 0) {
        setSwipeDistance(Math.min(0, deltaX - 110));
      } else if (!isActionMode) {
        setSwipeDistance(Math.min(0, deltaX));
      }
    }
  };

  const handleTouchEnd = (e) => {
    if (isHorizontalSwipeRef.current) {
      e.preventDefault();
      e.stopPropagation();
      
      if (isActionMode) {
        if (swipeDistance > -80) {
          setSwipeDistance(0);
          setIsActionMode(false);
        } else {
          setSwipeDistance(-110);
        }
      } else {
        if (swipeDistance < -50) {
          setSwipeDistance(-110);
          setIsActionMode(true);
        } else {
          setSwipeDistance(0);
        }
      }
    }
    startXRef.current = null;
    startYRef.current = null;
    isHorizontalSwipeRef.current = null;
  };

  // Attach a native touchmove listener with { passive: false }
  useEffect(() => {
    const node = parentRef.current;
    if (node) {
      const handleTouchMoveNonPassive = (e) => handleTouchMove(e);
      node.addEventListener('touchmove', handleTouchMoveNonPassive, { passive: false });
      return () => {
        node.removeEventListener('touchmove', handleTouchMoveNonPassive);
      };
    }
  }, []);

  // Add a function to handle move meal without event propagation
  const handleMoveButtonClick = () => {
    // Prevent any event propagation
    setMoveMealDialogOpen(true);
    return false;
  };

  // Add a function to handle delete without event propagation
  const handleDeleteButtonClick = () => {
    // Prevent any event propagation
    deleteMeal(props.cartMeal);
    return false;
  };

  // Add a click handler to the document to close action mode when clicking outside
  useEffect(() => {
    if (isActionMode) {
      const handleClickOutside = (e) => {
        // Check if the click is outside our component
        if (parentRef.current && !parentRef.current.contains(e.target) &&
            actionButtonsRef.current && !actionButtonsRef.current.contains(e.target)) {
          setSwipeDistance(0);
          setIsActionMode(false);
        }
      };
      
      // Add the event listener
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
      
      // Clean up
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('touchstart', handleClickOutside);
      };
    }
  }, [isActionMode]);

  return (
    <>
      <div className="swipeable-item" style={{ position: 'relative' }}>
        {/* Main swipeable content */}
        <div
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          ref={parentRef}
          style={{ position: 'relative', overflow: 'hidden' }}
        >
          <div
            className="calendar-meal-extra-content"
            style={{ 
              transform: `translateX(${swipeDistance}px)`,
              zIndex: 100,
              backgroundColor: 'white'
            }}
          >
            <div 
              className="calendar-meal" 
              onClick={isActionMode ? null : handleDialogOpen} 
              ref={childRef}
            >
              <div className="calendar-meal-top">
                <div className="calendar-meal-left">
                  <img
                    className="calendar-meal-thumbnail"
                    src={props.cartMeal.picture}
                    alt={props.cartMeal.name}
                    onClick={handleDialogOpen}
                  />

                  <div className="calendar-meal-text">
                    <div
                      className="calendar-meal-label"
                      style={{ zIndex: '3000' }}
                      onClick={handleDialogOpen}
                    >
                      {getMealAttributeWithOptions(props.cartMeal, 'name')}
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        {cals > 0 && (
                          <>
                            {/* Always display Calories */}
                            <div
                              style={{ color: 'black' }}
                              className="calendar-meal-calorie-label"
                            >
                              {cals} Cals
                              {/* <img
                                src={CaloriesBadge}
                                alt="calorie badge"
                                style={{ width: '14px', marginLeft: '3px' }}
                              /> */}
                            </div>
                            {/* Display selected macros */}
                            {selectedMacros &&
                              selectedMacros.map((macro) => {
                                const macroValue = getMealNutrientAmount(props.cartMeal, macro);
                                return (
                                  macroValue !== undefined && (
                                    <div
                                      key={macro}
                                      style={{ ...macroStyles[macro], margin: '2px' }}
                                      className="calendar-meal-calorie-chip"
                                    >
                                      {macroValue}
                                      {macroUnits[macro]}
                                    </div>
                                  )
                                );
                              })}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <Tooltip title="Duplicate meal">
                  <Button className="duplicate-button" onClick={openCopyDaysDialog}>
                    <img
                      id="calendar-meal-duplicate"
                      className="landing-upsell-image"
                      src={ContentCopy}
                      alt={'content copy'}
                      style={{ height: '18px', opacity: '0.4', marginRight: '-2px', marginBottom: '4px' }}
                    />
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        
        {/* Completely separate action buttons container */}
        {isActionMode && (
          <div 
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              height: '100%',
              display: 'flex',
              zIndex: 200, // Higher than everything
            }}
            ref={actionButtonsRef}
          >
            {/* Move button */}
            <div
              style={{
                width: '55px',
                height: '100%',
                backgroundColor: '#4caf50',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
              onTouchStart={(e) => {
                e.stopPropagation();
              }}
              onClick={(e) => {
                e.stopPropagation();
                // Use a direct DOM method to open the dialog
                document.body.click(); // Force any active elements to blur
                setTimeout(() => {
                  setMoveMealDialogOpen(true);
                }, 50);
              }}
            >
              <SwapHorizIcon style={{ color: 'white' }} />
            </div>
            
            {/* Delete button */}
            <div
              style={{
                width: '55px',
                height: '100%',
                backgroundColor: 'rgb(255, 119, 119)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
              onTouchStart={(e) => {
                e.stopPropagation();
              }}
              onClick={(e) => {
                e.stopPropagation();
                // Use a direct DOM method to handle deletion
                document.body.click(); // Force any active elements to blur
                setTimeout(() => {
                  deleteMeal(props.cartMeal);
                }, 50);
              }}
            >
              <DeleteIcon style={{ color: 'white' }} />
            </div>
          </div>
        )}
        
        {/* Transparent overlay to prevent meal details from opening when swiped */}
        {isActionMode && (
          <div 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 150, // Between content and buttons
              backgroundColor: 'transparent'
            }}
            onClick={(e) => {
              e.stopPropagation();
              // Swipe back when clicking on the overlay
              setSwipeDistance(0);
              setIsActionMode(false);
            }}
          />
        )}
      </div>

      {isDialogOpen && (
        <Dialog maxWidth={'xl'} open={isDialogOpen} onClose={handleDialogClose}>
          <MealDetailsCard
            isReadOnly={false}
            handleExitClick={handleDialogClose}
            meal={props.cartMeal}
            portion={portion}
            setPortion={setPortion}
          />
        </Dialog>
      )}

      <Dialog open={copyToDayDialogOpen} maxWidth={'xl'} onClose={exitCopyDaysDialog}>
        <CopyMealsToDay
          exitCopyDaysDialog={exitCopyDaysDialog}
          meals={[props.cartMeal]}
          calendarCategory={props.cartMeal.calendarCategory}
        />
      </Dialog>

      {/* Move Meal Dialog */}
      <Dialog open={moveMealDialogOpen} onClose={closeMoveMealDialog}>
        <DialogTitle>Move to</DialogTitle>
        <DialogContent>
          <List>
            {mealCategories.map((category) => (
              <ListItem 
                button 
                key={category.id} 
                onClick={() => handleMoveMeal(category.id)}
                disabled={category.id === props.cartMeal.calendarCategory}
              >
                <ListItemText 
                  primary={category.name} 
                  secondary={category.id === props.cartMeal.calendarCategory ? 'Current location' : ''}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CalendarMealNoDrag;
