import React, { createContext, useState, useRef } from 'react'
import { AuthContext } from './auth_context';
import { app } from "../firebase"
import _ from "lodash";
import { config } from 'Constants'
import { getDateMonthYear, parseDateMonthYear } from 'helpers/date_helpers';
import { getFirestore, collection, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { Capacitor } from '@capacitor/core';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { App as CapacitorApp } from '@capacitor/app';
import { Badge } from '@capawesome/capacitor-badge';

export const UserContext = createContext();


export const UserContextProvider = (props) => {

    const { currentUser } = React.useContext(AuthContext)
    const [userObject, setUserObject] = useState(config.emptyUserObject);
    const [bannerMessages, setBannerMessages] = useState([]);
    const userObjectRef = React.useRef({});
    var usersRef = collection(getFirestore(app), 'users')

    userObjectRef.current = userObject;

    React.useEffect(() => {
        let tokenRefreshListener;
        
        // Only set up the listener if the user has explicitly enabled notifications
        if (userObject.wantsReminders && Capacitor.isNativePlatform()) {
            tokenRefreshListener = FirebaseMessaging.addListener('tokenRefresh', async (event) => {
                console.log('[Notifications] Token refreshed:', event.token);
                const updatedUserObject = {
                    ...userObjectRef.current,
                    fcmToken: event.token,
                };
                await saveUserObject(updatedUserObject);
            });
        }

        return () => {
            if (tokenRefreshListener) {
                tokenRefreshListener.remove();
            }
        };
    }, [userObject.wantsReminders]); // Only re-run when notification preference changes

    React.useEffect(() => {
        // Only clear badge if user has completed onboarding and enabled notifications
        if (userObject?.onboardingStatus?.isComplete && userObject?.wantsReminders) {
            // Clear on initial mount
            Badge.clear().catch(error => 
                console.error('Error clearing badge:', error)
            );

            // Listen for app state changes
            const subscription = CapacitorApp.addListener('appStateChange', ({ isActive }) => {
                if (isActive && userObjectRef.current?.onboardingStatus?.isComplete && userObjectRef.current?.wantsReminders) {
                    Badge.clear().catch(error => 
                        console.error('Error clearing badge:', error)
                    );
                }
            });

            // Cleanup listener on unmount
            return () => {
                subscription.remove();
            };
        }
    }, [userObject.onboardingStatus?.isComplete, userObject.wantsReminders]);

    function saveUserObject(userObject) {
        let userObjectClone = _.cloneDeep(userObject)
        if (userObject.loaded && currentUser) {
            try {

                updateDoc(doc(usersRef, currentUser.uid), userObjectClone);
            } catch (error) {
                console.error("Error updating document:", error);
            }

        }

        setUserObject(userObjectClone)

    }

    function setFoodVendor(foodVendorName, foodVendorID, foodVendorDeliveryRate) {
        saveUserObject({ ...userObject, foodVendor: foodVendorName, foodVendorID: foodVendorID, foodVendorDeliveryRate: foodVendorDeliveryRate })
    }

    function setChosenDeliveryDate(chosenDeliveryDate) {
        saveUserObject({ ...userObjectRef.current, chosenDeliveryDate: chosenDeliveryDate })
    }

    // Function to update calendar layout configuration
    function setCalendarLayout(layoutConfig) {
        saveUserObject({ ...userObject, calendarLayout: layoutConfig });
    }

    // Function to get the current calendar layout or default
    function getCalendarLayout() {
        return userObject.calendarLayout || [
            { id: 'chart', name: 'Nutrition Chart', visible: true },
            { id: 'weight', name: 'Weight Tracker', visible: true },
            { id: 'water', name: 'Water Tracker', visible: true },
            { id: 'breakfast', name: 'Breakfast', visible: true },
            { id: 'lunch', name: 'Lunch', visible: true },
            { id: 'dinner', name: 'Dinner', visible: true },
            { id: 'snack', name: 'Snack', visible: true },
            { id: 'steps', name: 'Steps Tracker', visible: true },
            { id: 'exercise', name: 'Exercise Tracker', visible: true },
        ];
    }


    function getCalorieCategory() {
        if (userObject.isUsingMacros) {
            const calculatedCalories = userObject.proteins * 4 + userObject.fats * 9 + userObject.carbs * 4
            return Math.round(calculatedCalories / 100) * 100
        }
        return Math.round(userObject.calories / 100) * 100
    }


    function getCalories() {
        if (userObject.isUsingMacros) {
            const calculatedCalories = userObject.proteins * 4 + userObject.fats * 9 + userObject.carbs * 4
            return calculatedCalories
        }
        return userObject.calories
    }

    function getCustomRecipeBookId() {
        for (let recipeBookId of userObject.recipeBooks) {
            if (recipeBookId !== config.baseRecipeBookId) { return recipeBookId }
        }
    }

    function resetUserObject() {
        //only should be used during logout
        setUserObject(config.emptyUserObject)

    }

    function getCurrentWeight() {
        let weight = ''
        let unit = ''
        if (userObject.weight) {
            let weightKeys = Object.keys(userObject.weight)
            if (weightKeys.length !== 0) {
                let maxDate = parseDateMonthYear(weightKeys[0])

                weight = userObject.weight[weightKeys[0]]
                unit = "lbs"
                //userObject.weight[weightKeys[0]] can be either a float straight up in pounds or {value:<Float>, unit:<String>}
                if (userObject.weight[weightKeys[0]].value) {
                    weight = userObject.weight[weightKeys[0]].value
                    unit = userObject.weight[weightKeys[0]].unit
                }
                for (let weightDate of Object.keys(userObject.weight)) {
                    let currentDate = parseDateMonthYear(weightDate)
                    if (currentDate > maxDate) {
                        weight = userObject.weight[weightDate]
                        unit = "lbs"
                        if (userObject.weight[weightDate].value) {
                            weight = userObject.weight[weightDate].value
                            unit = userObject.weight[weightDate].unit
                        }
                        maxDate = currentDate
                    }
                }
            }
        }
        return [weight, unit]
    }

    function getCurrentBodyFat() {
        let bodyFat = ''
        if (userObject.bodyFat) {
            let bodyFatKeys = Object.keys(userObject.bodyFat)
            if (bodyFatKeys.length !== 0) {
                let maxDate = parseDateMonthYear(bodyFatKeys[0])

                // Handle both number and object formats
                bodyFat = userObject.bodyFat[bodyFatKeys[0]]
                if (typeof bodyFat === 'object' && bodyFat.value !== undefined) {
                    bodyFat = bodyFat.value
                }

                for (let bodyFatDate of Object.keys(userObject.bodyFat)) {
                    let currentDate = parseDateMonthYear(bodyFatDate)
                    if (currentDate > maxDate) {
                        bodyFat = userObject.bodyFat[bodyFatDate]
                        if (typeof bodyFat === 'object' && bodyFat.value !== undefined) {
                            bodyFat = bodyFat.value
                        }
                        maxDate = currentDate
                    }
                }
            }
        }
        return bodyFat
    }

    function getWaterIntake(date) {
        if (userObject.waterIntake) {
            let dateKey = getDateMonthYear(date);
            if (userObject.waterIntake[dateKey]) {
                return userObject.waterIntake[dateKey];
            }
        }
        return 0;
    }

    function setWaterIntake(date, cups) {
        let waterIntakeObject = userObject.waterIntake ? userObject.waterIntake : {};
        let dateKey = getDateMonthYear(date);

        waterIntakeObject[dateKey] = cups;
        saveUserObject({ ...userObject, waterIntake: waterIntakeObject });
    }

    // Inside UserContextProvider

    function getWaterGoalCups() {
        return userObject.waterGoalCups ? userObject.waterGoalCups : 8;
    }

    function setWaterSettings(newWaterGoalCups, newCupSize, allowHalfCups, cupSizeUnit) {
        saveUserObject({ 
            ...userObject, 
            waterGoalCups: newWaterGoalCups, 
            cupSizeOz: newCupSize,
            allowHalfCups: allowHalfCups || false,
            cupSizeUnit: cupSizeUnit || 'oz'
        });
    }

    function getCupSizeOz() {
        return userObject.cupSizeOz ? userObject.cupSizeOz : 8;
    }

    function getCupSizeUnit() {
        return userObject.cupSizeUnit || 'oz';
    }

    // Function to get exercise data for a specific date
    function getExerciseData(date) {
        let dateKey = getDateMonthYear(date);
        if (userObject.exerciseData && userObject.exerciseData[dateKey]) {
            return userObject.exerciseData[dateKey];
        }
        return [];
    }

    // Function to set exercise data for a specific date
    function setExerciseData(date, data) {
        let dateKey = getDateMonthYear(date);
        let exerciseDataObject = userObject.exerciseData
            ? { ...userObject.exerciseData }
            : {};
        exerciseDataObject[dateKey] = data;

        saveUserObject({ ...userObject, exerciseData: exerciseDataObject });
    }

    async function deleteUserInDB() {
        try {
            await deleteDoc(doc(usersRef, currentUser.uid));
            console.log("Document successfully deleted!");
        } catch (error) {
            console.error("Error deleting document:", error);
        } resetUserObject()
    }

    // Function to get step data for a specific date
    function getStepData(date) {
        let dateKey = getDateMonthYear(date);
        if (userObject.stepData && userObject.stepData[dateKey]) {
            return userObject.stepData[dateKey];
        }
        return 0;
    }

    // Function to set step data for a specific date
    function setStepData(date, steps) {
        let dateKey = getDateMonthYear(date);
        let stepDataObject = userObject.stepData 
            ? { ...userObject.stepData }
            : {};
        stepDataObject[dateKey] = steps;

        saveUserObject({ ...userObject, stepData: stepDataObject });
    }

    // Function to get/set step goal
    function getStepGoal() {
        return userObject.stepGoal || 10000;
    }

    function setStepGoal(goal) {
        saveUserObject({ ...userObject, stepGoal: goal });
    }

    async function setNotificationPreferences(enabled, reminderTime) {
        try {
            console.log('[Notifications] setNotificationPreferences called, enabled:', enabled);
        
            // Always store the updated user preference/timezone
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let updatedUserObject = {
                ...userObject,
                wantsReminders: enabled,
                reminderTime: reminderTime,
                reminderTimeZone: timezone,
                onboardingStatus: {
                    isComplete: true,
                    currentStage: 5
                } 
            };
        
            // Only request permission + token if notifications are enabled and we're on a real device
            if (enabled && Capacitor.isNativePlatform()) {
                console.log('[Notifications] Requesting push permission via FirebaseMessaging...');
            
                // 1) Ask the user for notification permissions
                const permissionStatus = await FirebaseMessaging.requestPermissions();
                console.log('[Notifications] Permission status:', permissionStatus);

                if (permissionStatus.receive === 'granted') {
                    // 2) Get the FCM token
                    const tokenResult = await FirebaseMessaging.getToken();
                    console.log('[Notifications] FCM token result:', tokenResult);
                
                    if (tokenResult && tokenResult.token) {
                        updatedUserObject.fcmToken = tokenResult.token;
                    } else {
                        console.warn('[Notifications] getToken() did not return a token');
                    }
                } else {
                    console.log('[Notifications] Permission not granted');
                }
            }
        
            // 3) Save user object (with or without token)
            await saveUserObject(updatedUserObject);
            console.log('[Notifications] Updated user preferences saved:', updatedUserObject);
            return true;
        } catch (error) {
            console.error('[Notifications] Error in setNotificationPreferences:', error);
            return false;
        }
    }
    

    function showBanner(message, duration = 5000) {
        // Generate a unique ID for this banner
        const bannerId = Date.now();
        const newBanner = {
            id: bannerId,
            message,
            timestamp: Date.now()
        };
        
        setBannerMessages(prev => [...prev, newBanner]);

        if (duration) {
            setTimeout(() => {
                setBannerMessages(prev => prev.filter(banner => banner.id !== bannerId));
            }, duration);
        }
    }

    function hideBanner(bannerId) {
        setBannerMessages(prev => prev.filter(banner => banner.id !== bannerId));
    }

    return (
        <UserContext.Provider value={{ 
            userObject: userObject, 
            userObjectRef: userObjectRef, 
            getCalories: getCalories, 
            setUserObject: saveUserObject, 
            setUserObjectNoSave: setUserObject, 
            getCalorieCategory: getCalorieCategory, 
            setFoodVendor: setFoodVendor, 
            setChosenDeliveryDate: setChosenDeliveryDate, 
            resetUserObject: resetUserObject, 
            getCustomRecipeBookId: getCustomRecipeBookId, 
            deleteUserInDB: deleteUserInDB, 
            getCurrentWeight: getCurrentWeight, 
            getCurrentBodyFat: getCurrentBodyFat,    
            getWaterIntake: getWaterIntake, 
            setWaterIntake: setWaterIntake, 
            getWaterGoalCups: getWaterGoalCups,
            setWaterSettings: setWaterSettings,
            getCupSizeOz: getCupSizeOz,
            getCupSizeUnit: getCupSizeUnit,
            getExerciseData: getExerciseData, 
            setExerciseData: setExerciseData, 
            getStepData, 
            setStepData, 
            getStepGoal, 
            setStepGoal,
            setCalendarLayout,
            getCalendarLayout,
            setNotificationPreferences,
            getNotificationPreferences: () => ({
                enabled: userObject.wantsReminders,
                reminderTime: userObject.reminderTime
            }),
            bannerMessages,
            showBanner,
            hideBanner,
        }}>
            {props.children}
        </UserContext.Provider>
    );
};