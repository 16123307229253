import React, { useState } from 'react';
import './add_recipe_to_plan.css';  // Import the CSS file
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import _ from 'lodash';
import { AuthContext } from 'contexts/auth_context';
import { config } from "Constants";
import { CartContext } from 'contexts/cart_context';
import { UserContext } from 'contexts/user_context';
import { getWeeks } from 'helpers/date_helpers';






const AddRecipeToPlan = (props) => {
    const addRecipeToCookingList = React.useContext(CartContext).addRecipeToCookingList;
    const { currentUser } = React.useContext(AuthContext)
    const [selectedWeek, setSelectedWeek] = useState(getWeeks()[0].startOfWeek);
    const setUserObject = React.useContext(UserContext).setUserObject;
    const userObject = React.useContext(UserContext).userObject
    const [portion, setPortion] = React.useState(props.recipe?.portion? props.recipe.portion : 1)
    const weeks = getWeeks();


    const handleWeekChange = (event) => {
        setSelectedWeek(event.target.value);
    };

    const handleSubmit = async () => {
        if (!selectedWeek) {
            alert('Please select a week.');
            return;
        }

        if (!props.recipe.recipeBooks) {
            //web recipes need to be processed
            let recipeClone = await props.prepareWebRecipe(props.recipe)
            addRecipeToCookingList({ ...recipeClone, week: selectedWeek })
            FirebaseAnalytics.logEvent({
                name: "added_recipe_to_cooking_list",
                params: { recipe_name: recipeClone.name, currentUser: currentUser.uid, recipe_type: 'web' }
            })
        }
        else {
            //if not web
            addRecipeToCookingList(_.cloneDeep({ ...props.recipe, week: selectedWeek, portion: portion}))
            FirebaseAnalytics.logEvent({
                name: "added_recipe_to_cooking_list",
                params: { recipe_name: props.recipe.name, currentUser: currentUser.uid, recipe_type: props.recipe.recipeBooks[0] === config.baseRecipeBookId ? "bitetracker" : "user" }
            })
        }
        props.setShowIngredientsChip && props.setShowIngredientsChip(true)
        props.setShowWeeklyPlanChip && props.setShowWeeklyPlanChip(true)
        setUserObject({ ...userObject, addMealTabValue: 0 })

        props.exitDialog()

    }

    return (
        <div className="container">
            <div style={{ textAlign: 'center', fontFamily: 'Montserrat-SemiBold', padding: '25px 0px' }}>For which week do you want to cook?</div>
            <div className="select-container">
                <select value={selectedWeek} onChange={handleWeekChange}>
                    <option value="" disabled>Select a week</option>
                    {weeks.map((week, index) => (
                        <option key={index} value={week.startOfWeek}>
                            {week.label}
                        </option>
                    ))}
                </select>
            </div>

            <button style={{marginTop:'5px'}} className={!selectedWeek ? "cancel-button" : "submit-button"} disabled={!selectedWeek} onClick={handleSubmit}>Add Recipe to Plan</button>
        </div>
    );
};

export default AddRecipeToPlan;
