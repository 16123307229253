// CalorieCard.js

import React, { useContext, useRef } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { PieChart, Pie, Cell } from 'recharts';
import './calorie_card.css';
import { UserContext } from 'contexts/user_context';
import { NarrowTextField } from '../reusables/narrow_text_field';
import { Dialog } from '@material-ui/core';
import ModifyCalories from '../modify_calories/modify_calories';

const CalorieCard = (props) => {
  const {
    activeIndex,
    setActiveIndex,
    totalProteins,
    totalCarbs,
    totalFats,
    totalSodium,
    totalSugar,
    totalCholesterol,
    totalFiber,
    totalNetCarbs,
    totalSaturatedFat,
    totalCalories, // Ensure totalCalories is passed as a prop
    context = 'daily', // 'daily' or 'recipe'
    editable = false, // Determines if values are editable
    handleNutrientChange, // Function to handle nutrient changes
    errorMessage, // Error message from parent
  } = props;

  const { userObject } = useContext(UserContext);
  const carouselRef = useRef({ touchStartX: 0, touchEndX: 0 });
  const [isCalorieDialogOpen, setIsCalorieDialogOpen] = React.useState(false);

  // Calculate individual macro calories
  const proteinCalories = totalProteins * 4;
  const carbCalories = totalCarbs * 4;
  const fatCalories = totalFats * 9;

  const consumedCalories = proteinCalories + carbCalories + fatCalories;

  // Use totalCalories if available; otherwise, fallback to consumedCalories
  const displayedCalories = totalCalories || consumedCalories;

  // Adjust remaining calories calculation
  const remainingCalories =
    context === 'daily' && userObject?.calories
      ? userObject.calories - displayedCalories
      : null;

  const calorieData = [
    { name: 'Protein', value: proteinCalories },
    { name: 'Carbs', value: carbCalories },
    { name: 'Fat', value: fatCalories },
  ];

  // Only include 'Remaining' in pie chart if in 'daily' context
  if (context === 'daily' && remainingCalories !== null) {
    calorieData.push({
      name: 'Remaining',
      value: remainingCalories >= 0 ? remainingCalories : 0,
    });
  }

  const COLORS = ['#24C581', '#25ABFF', '#FF802C', '#d3d3d3'];

  // Prepare slides data with nutrient names
  const slides = [
    [
      {
        name: 'Protein',
        label: 'Protein',
        total: totalProteins,
        max: userObject?.proteins,
        variant: 'macro-totals-bar-protein',
        unit: 'g',
      },
      {
        name: 'Carbohydrates',
        label: 'Carbs',
        total: totalCarbs,
        max: userObject?.carbs,
        variant: 'macro-totals-bar-carbs',
        unit: 'g',
      },
      {
        name: 'Fat',
        label: 'Fat',
        total: totalFats,
        max: userObject?.fats,
        variant: 'macro-totals-bar-fats',
        unit: 'g',
      },
    ],
    [
      {
        name: 'Sodium',
        label: 'Sodium',
        total: totalSodium,
        max: userObject?.sodium,
        variant: 'macro-totals-bar-sodium',
        unit: 'mg',
      },
      {
        name: 'Sugar',
        label: 'Sugar',
        total: totalSugar,
        max: userObject?.sugar,
        variant: 'macro-totals-bar-sugar',
        unit: 'g',
      },
      {
        name: 'Cholesterol',
        label: 'Cholesterol',
        total: totalCholesterol,
        max: userObject?.cholesterol,
        variant: 'macro-totals-bar-cholesterol',
        unit: 'mg',
      },
    ],
    [
      {
        name: 'Fiber',
        label: 'Fiber',
        total: totalFiber,
        max: userObject?.fiber,
        variant: 'macro-totals-bar-fiber',
        unit: 'g',
      },
      {
        name: 'Net Carbohydrates',
        label: 'Net Carbs',
        total: totalNetCarbs,
        max: userObject?.netCarbs,
        variant: 'macro-totals-bar-net-carbs',
        unit: 'g',
      },
      {
        name: 'Saturated Fat',
        label: 'Saturated Fat',
        total: totalSaturatedFat,
        max: userObject?.saturatedFat,
        variant: 'macro-totals-bar-saturated-fat',
        unit: 'g',
      },
    ],
  ];

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  // Swipe handling logic
  const handleTouchStart = (e) => {
    carouselRef.current.touchStartX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    carouselRef.current.touchEndX = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    const { touchStartX, touchEndX } = carouselRef.current;
    if (touchStartX && touchEndX) {
      const deltaX = touchEndX - touchStartX;
      if (Math.abs(deltaX) > 50) {
        if (deltaX > 0) {
          prevSlide();
        } else {
          nextSlide();
        }
      }
    }
    carouselRef.current.touchStartX = 0;
    carouselRef.current.touchEndX = 0;
  };

  const handlePieChartClick = () => {
    if (context === 'daily') {
      setIsCalorieDialogOpen(true);
    }
  };

  return (
    <div className="calorie-card">
      {/* Pie Chart */}
      <div 
        className="pie-chart-container" 
        style={{ 
          position: 'relative', 
          width: 160, 
          height: 160,
          cursor: context === 'daily' ? 'pointer' : 'default' 
        }}
        onClick={handlePieChartClick}
      >
        <PieChart width={160} height={160}>
          <Pie
            data={calorieData}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={55}
            outerRadius={65}
            fill="#8884d8"
            paddingAngle={0}
          >
            {calorieData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
        <div
          className="calorie-text"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
          }}
        >
          {context === 'daily' ? (
            displayedCalories === 0 ? (
              <>
                <div style={{ fontSize: '20px' }}>{userObject?.calories || 0}</div>
                <div style={{ fontSize: '13px', color: '#B3B3B3', marginTop: '6px' }}>
                   cal left
                </div>
              </>
            ) : (
              <>
                <div style={{ 
                  fontSize: '14px',
                  color:'inherit'
                }}>{displayedCalories} / {userObject?.calories || 0} </div>
                <div style={{ 
                  fontSize: '13px', 
                  color: remainingCalories < 0 ? 'rgb(186 41 41)' : '#B3B3B3', 
                  marginTop: '6px' 
                }}>
                  {Math.abs(remainingCalories)} cal {remainingCalories < 0 ? 'over' : 'left'}
                </div>
              </>
            )
          ) : (
            <div style={{ fontSize: '20px' }}>{displayedCalories} cal</div>
          )}
        </div>
      </div>

      {/* Custom Carousel for Progress Bars */}
      <div
        className="custom-carousel"
        ref={carouselRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {/* Slide Content */}
        <div
          className="slide-content"
          style={{
            transform: `translateX(-${activeIndex * 100}%)`,
          }}
        >
          {slides.map((slide, slideIndex) => (
            <div className="slide" key={slideIndex}>
              {slide.map((item, itemIndex) => (
                <div key={itemIndex} className="progress-bar-container">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: editable ? '0px' : '5px',
                    }}
                  >
                    <div className="progress-bar-label">{item.label}</div>
                    <div className="progress-bar-value">
                      {editable ? (
                        <>
                          <NarrowTextField
                            className="modify-recipe-nutrient-textfield"
                            name={item.name}
                            type="number"
                            value={item.total}
                            onChange={handleNutrientChange}
                            readOnly={item.label === 'Net Carbs'}
                          />
                          {item.unit}
                        </>
                      ) : (
                        `${item.total}${item.unit}`
                      )}
                      {context === 'daily' && item.max ? ` of ${item.max}${item.unit}` : ''}
                    </div>
                  </div>
                  <ProgressBar>
                    <ProgressBar
                      now={item.total}
                      max={context === 'daily' && item.max ? item.max : item.total || 0.1}
                      variant={item.total === 0 && (!item.max || item.max === 0) ? 'secondary' : ''}
                      key={itemIndex}
                      className={item.variant}
                    />
                  </ProgressBar>
                </div>
              ))}
            </div>
          ))}
        </div>

        {/* Indicators */}
        <div className="carousel-indicators">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`indicator ${index === activeIndex ? 'active' : ''}`}
              onClick={() => setActiveIndex(index)}
            ></span>
          ))}
        </div>

        {/* Navigation Controls */}
        <button className="custom-control-prev" onClick={prevSlide}>
          <span className="custom-control-prev-icon" aria-hidden="true"></span>
        </button>
        <button className="custom-control-next" onClick={nextSlide}>
          <span className="custom-control-next-icon" aria-hidden="true"></span>
        </button>
      </div>

      {/* Error Message */}
      {errorMessage && (
        <div style={{ color: 'red', fontSize: '12px', marginTop: '10px' }}>
          {errorMessage}
        </div>
      )}

      {/* Add Dialog */}
      <Dialog 
        maxWidth={'xl'} 
        open={isCalorieDialogOpen} 
        onClose={() => setIsCalorieDialogOpen(false)}
      >
        <div style={{ 
          width: '100vw', 
          maxWidth: '400px', 
          marginBottom: '20px', 
          height: '340px', 
          marginTop: '0px' 
        }}>
          <ModifyCalories />
        </div>
      </Dialog>
    </div>
  );
};

export default CalorieCard;
