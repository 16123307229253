import './settings_sidebar.css'

import React from 'react';
// import Badge from '@material-ui/core/Badge';
// import IconButton from '@material-ui/core/IconButton';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { NavLink } from 'react-router-dom';
import { CartContext } from 'contexts/cart_context';
import { Button, Dialog, TextField, Drawer } from '@material-ui/core';
import { AuthContext } from 'contexts/auth_context';
import { RecipeBooksContext } from 'contexts/recipe_books_context';
import LoopIcon from '@material-ui/icons/Loop';
import { UserContext } from 'contexts/user_context';
import ModifyCalories from '../modify_calories/modify_calories';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import SubjectIcon from '@material-ui/icons/Subject';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SpaIcon from '@material-ui/icons/Spa';

const SettingsSidebar = (props) => {
   const open = props.open
   const close = props.close
   const [isCalorieDialogOpen, setIsCalorieDialogOpen] = React.useState(false)
   const [isDeleteAccountDialogOpen, setIsDeleteAccountDialogOpen] = React.useState(false)
   const [deleteAccountText, setDeleteAccountText] = React.useState("")
   const [isDeleteAccountLoading, setIsDeleteAccountLoading] = React.useState(false)
   const { currentUser } = React.useContext(AuthContext)
   const { logout } = React.useContext(AuthContext)
   const resetAllContexts = React.useContext(CartContext).resetAllContexts
   const { recipeList, deleteRecipe, getCustomRecipeBookId } = React.useContext(RecipeBooksContext)
   const { deleteUserInDB } = React.useContext(UserContext)



   // function handleCartHover(event) {
   //    setCartHoveredAnchorEl(event.currentTarget);
   // }



   function openCalorieDialog() {
      setIsCalorieDialogOpen(true);
      close()
   }

   function openDeleteAccountDialog() {
      setIsDeleteAccountDialogOpen(true);
      close()
   }

   function handleDeleteAccountTextChange(e) {
      setDeleteAccountText(e.target.value)
   }

   async function deleteUserAccount() {
      setIsDeleteAccountLoading(true)
      for (let recipe of recipeList) {
         if (recipe.recipeBooks[0] === getCustomRecipeBookId()) {
            await deleteRecipe(recipe)
            console.log("deleting recipe " + recipe.name)
         }
      }
      console.log("deleting user in DB")
      await deleteUserInDB()
      console.log("deleting user in auth")
      currentUser.delete().then(() => {
         console.log("user deleted")
      }).catch((error) => {
         // An error occurred
         // ...
      });
      setIsDeleteAccountLoading(false)
      setIsDeleteAccountDialogOpen(false)
      logout(resetAllContexts)

   }

   function handleSignOutClick() {
      logout(resetAllContexts)
      close()
   }




   return (<>

      <Drawer
         anchor="left"
         open={Boolean(open)} onClose={close}
         classes={{ paper: "settings-sidebar-paper" }}>
         <div className="settings-sidebar-container">
            <h3 className="settings-sidebar-title">Navigate</h3>
            <div className="settings-sidebar-item"><NavLink onClick={close} to="/account" ><Button onClick={close} className="sidebar-button"><PermContactCalendarIcon className="sidebar-icon" />Account Settings</Button></NavLink></div>
            <div className="settings-sidebar-item"><NavLink onClick={close} to="/dashboard" ><Button onClick={close} className="sidebar-button"><DashboardIcon className="sidebar-icon" />Progress Charts</Button></NavLink></div>
            <div className="settings-sidebar-item"><Button className="sidebar-button" onClick={openCalorieDialog}><WhatshotIcon className="sidebar-icon" />Change Macros</Button></div>
            <div className="settings-sidebar-item"><NavLink onClick={close} to="/ai_consultation" ><Button onClick={close} className="sidebar-button"><SpaIcon className="sidebar-icon" />AI Consultation</Button></NavLink></div>
            <div className="settings-sidebar-item"><NavLink onClick={close} to="/contact_us" ><Button onClick={close} className="sidebar-button"><MailOutlineIcon className="sidebar-icon" />Contact Us</Button></NavLink></div>
            <div className="settings-sidebar-item"><a href="https://thebitetracker.com/blog" onClick={close} target="_blank" rel="noopener noreferrer"><Button onClick={close} className="sidebar-button"><SubjectIcon className="sidebar-icon" />Visit Blog</Button></a></div>
            {currentUser && currentUser.email === 'brian@thebitetracker.com' &&
               <div className="settings-sidebar-item"><NavLink onClick={close} to="/advanced_login" ><Button onClick={close} className="sidebar-button"><SpaIcon className="sidebar-icon" />Advanced Login</Button></NavLink></div>
            }

            <div className="settings-sidebar-divider"></div>
            
            <div className="settings-sidebar-item"><Button className="sidebar-button sidebar-button-danger" onClick={openDeleteAccountDialog}>Delete Account</Button></div>
            <div className="settings-sidebar-item"><Button className="sidebar-button sidebar-button-signout" onClick={handleSignOutClick}>Sign Out</Button></div>
         </div>
      </Drawer>

      <Dialog maxWidth={'xl'} open={isCalorieDialogOpen} onClose={() => { setIsCalorieDialogOpen(false) }} >
         <div style={{ width: '100vw', maxWidth: '400px', marginBottom: '20px', height: '340px', marginTop: '0px' }}>
            <ModifyCalories close={() => { setIsCalorieDialogOpen(false) }} />
         </div>
      </Dialog>

      <Dialog maxWidth={'xl'} open={isDeleteAccountDialogOpen} onClose={() => { setIsDeleteAccountDialogOpen(false) }} >

         <div style={{ textAlign: 'center', margin: '30px' }}> Confirm account deletion
            <div style={{ color: 'grey', fontSize: '14px', margin: '10px 0px 0px 0px' }}>This action will delete your account and all associated data. Type "delete" to confirm.</div>
            <div><TextField className="" placeholder={"delete"} value={deleteAccountText} onChange={handleDeleteAccountTextChange}></TextField></div>
            <div><Button disabled={deleteAccountText !== "delete" || isDeleteAccountLoading} className={deleteAccountText === "delete" ? "delete-account-confirmation-button" : "modify-recipe-cancel-button"} onClick={deleteUserAccount}>Delete {isDeleteAccountLoading && <LoopIcon style={{ animation: 'spin 2s linear infinite' }} />}</Button> <Button className="delete-account-cancel-button" onClick={() => { setIsDeleteAccountDialogOpen(false) }}>Cancel</Button> </div>
            <div style={{ color: 'grey', fontSize: '14px', margin: '10px 0px 0px 0px' }}>Note: this is a security-sensitive operation that requires you to have recently signed in. </div>

         </div>
      </Dialog>

   </>
   );
}

export default SettingsSidebar;