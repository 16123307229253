
import './order_ingredients_page.css'
import React from "react"
import OrderIngredients from "components/shared/order_ingredients/order_ingredients";
import { Capacitor } from "@capacitor/core";
import MobileSettingsButton from "components/shared/mobile_settings_button/mobile_settings_button";
import { CartContext } from "contexts/cart_context";
import { AuthContext } from "contexts/auth_context";




const OrderIngredientsPage = (props) => {


  return (

    <div className="order-ingredients-page" style={{ minHeight: "calc(100% - 45px)" }} >
      {Capacitor.getPlatform() !== "web" && <MobileSettingsButton />}

      <div style={{ maxWidth: '280px', paddingTop: '18vh', marginLeft: 'auto', marginRight: 'auto' }}>
        <div className="mobile-settings-dialog-header"> Order Ingredients </div>
        <OrderIngredients showWarningImmediately={true}></OrderIngredients>

      </div>

    </div>

  )
}

export default OrderIngredientsPage;