import React from "react";
import "./landing_desktop.css";
import calendario from "images/calendario.mp4";
import wholeFoods from "images/whole-foods.png";
import appStoreBadge from "images/app_store_badge.png";
import playStoreBadge from "images/play_store_badge.png";

import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

import { CartContext } from 'contexts/cart_context';
import { AuthContext } from 'contexts/auth_context';

export const LandingDesktop = () => {
  const { currentUser } = React.useContext(AuthContext)


  return (
    <div className="home-page-version">
      <div className="div">
        <img className="page" alt="Page" src="https://c.animaapp.com/DVqGB5xQ/img/page-1@2x.png" />
        <div className="text-wrapper">How it works</div>
        <div className="text-wrapper-2">PROCESS</div>
        <p className="prepare-and-enjoy">
          Prepare and enjoy the meals, <br />
          track other food as you go
        </p>
        <p className="p">
          Prepare your food using the easy-to-follow instructions and enjoy.
          <br></br>
          <br></br>
          You can also use the BiteTracker Calendar from your phone to account for any additional foods you are consuming in a single place.
        </p>
        <div className="text-wrapper-3">Create And Modify Recipes</div>
        <p className="our-platform-allows">
          Create dishes that resonate with your unique palate. You can add a recipe from scratch or copy/edit BiteTracker recipes by modifying ingredients and quantities. You will be able to track calories and macros dynamically and use our platform to store your recipes.
          <br />
          <br />
          Ready to put your personal touch on your meals?
        </p>
        <div className="text-wrapper-4">Get ingredients same-day</div>
        <p className="as-soon-as-you-ve">
          As soon as you craft your weekly plan, simply order your ingredients via Instacart, AmazonFresh or WholeFoods.
          <br></br>        <br></br>

          This includes ingredients from your personal or BiteTracker recipes that you added into your calendar. We batch and generate the ingredient list for you!
        </p>
        <div className="group">
          <p className="text-wrapper-5">Choose or add recipes</p>
          <p className="text-wrapper-6">
            Select and edit recipes from our book of macro-conscious meals or add your own recipes into your profile. As you add ingredients the macros and calories will be automatically calculated.

          </p>
          <img className="img" alt="Group" src="https://c.animaapp.com/DVqGB5xQ/img/group-18400@2x.png" />
        </div>
        <p className="text-wrapper-7">200+ macro-friendly recipes</p>
        <div className="text-wrapper-8">SNEAK PEAK</div>
        <div className="group-2">
          <p className="plan-your-week">
            Plan and track your week

          </p>
          <p className="text-wrapper-9">
            Use our built-in calendar to add meals from the BiteTracker recipe book and from your personal recipe book. You can also keep track of branded and generic food items by adding them to your calendar. Your calories/macros will be accounted for.
          </p>
          <img className="group-3" alt="Group" src="https://c.animaapp.com/DVqGB5xQ/img/group-18401@2x.png" />
        </div>
        <img className="element" alt="Element" src="https://c.animaapp.com/DVqGB5xQ/img/3857131-1.png" />

        <div className="overlap">
          <p className="plan-your-meals-for">
            Plan your meals
            <br />
            for the week
          </p>
          <div className="overlap-group">
            <p className="text-wrapper-10">
              Meet your calorie/macro goals using our web & mobile food calendar, macro-friendly recipe book and integration with Instacart, AmazonFresh and WholeFoods.
            </p>
            <div className="overlap-2">
              <div className="overlap-wrapper">
                <div className="element-free-wrapper">
                  <div className="element-free">
                    100%
                    <br />
                    free
                  </div>
                </div>
              </div>
              <img className="image" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-1@2x.png" />
              <img className="image-2" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-3@2x.png" />
              <img className="image-3" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-4@2x.png" />
              <img className="image-4" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-7@2x.png" />
              <img className="image-5" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-17@2x.png" />
              <img className="image-6" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-11@2x.png" />
              <img
                className="set-of-slice"
                alt="Set of slice"
                src="https://c.animaapp.com/DVqGB5xQ/img/set-of-slice-tomatoes-isolated-png-02-1@2x.png"
              />
              <img className="image-7" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-18@2x.png" />
              <img className="image-8" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-15@2x.png" />
              <img className="image-9" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-14@2x.png" />
              <img
                className="clip-path-group"
                alt="Clip path group"
                src="https://c.animaapp.com/DVqGB5xQ/img/clip-path-group-9@2x.png"
              />
              <img
                className="clip-path-group-2"
                alt="Clip path group"
                src="https://c.animaapp.com/DVqGB5xQ/img/clip-path-group-8@2x.png"
              />
              <img
                className="mariana-medvedeva"
                alt="Mariana medvedeva"
                src="https://c.animaapp.com/DVqGB5xQ/img/mariana-medvedeva-inwco9ycblc-unsplash-1.png"
              />
            </div>
            <img className="group-4" alt="Group" src="https://c.animaapp.com/DVqGB5xQ/img/group-6356290@2x.png" />
            <img className="landing-whole-foods" alt="Group" src={wholeFoods} />

            <a href="https://apps.apple.com/us/app/thebitetracker/id6473445195"><img className="landing-appstore-badge" alt="Group" src={appStoreBadge} /></a>
            <a href="https://play.google.com/store/apps/details?id=com.thebitetracker.com"><img className="landing-playstore-badge" alt="Group" src={playStoreBadge} /></a>

          </div>
          <div className="text-wrapper-11">Support by</div>
          <img className="capa" alt="Capa" src="https://c.animaapp.com/DVqGB5xQ/img/capa-1-1.svg" />
          <div className="overlap-group-wrapper">
            <div className="overlap-3">
              <img className="rectangle" alt="Rectangle" src="https://c.animaapp.com/DVqGB5xQ/img/rectangle-73-1.svg" />
              <div className="group-5">
                <div className="overlap-group-2">
                  <NavLink to="/calendar" ><div className="text-wrapper-12">Use on Web</div></NavLink>
                  <img className="arrow" alt="Arrow" src="https://c.animaapp.com/DVqGB5xQ/img/arrow-2-1.svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="group-6">
          <div className="overlap-4">
            <img className="rectangle-2" alt="Rectangle" src="https://c.animaapp.com/DVqGB5xQ/img/rectangle-73-2.svg" />
            <div className="group-7">
              <div className="overlap-group-3">
                <NavLink to="/calendar" ><div className="text-wrapper-13">Your Calendar</div></NavLink>
                <img className="arrow-2" alt="Arrow" src="https://c.animaapp.com/DVqGB5xQ/img/arrow-2-2.svg" />
              </div>
            </div>
          </div>
        </div>
        {currentUser ? <div className="group-8-account">
          <div className="group-9-account">
            <NavLink to="/planning" ><div className="text-wrapper-14-account">Recipes</div></NavLink>
            <img className="arrow-3-account" alt="Arrow" src="https://c.animaapp.com/DVqGB5xQ/img/arrow-2.svg" />
          </div>
        </div> :
          <div className="group-8">
            <div className="group-9">
              <NavLink to="/account" ><div className="text-wrapper-14">Log in</div></NavLink>
              <img className="arrow-3" alt="Arrow" src="https://c.animaapp.com/DVqGB5xQ/img/arrow-2.svg" />
            </div>
          </div>
        }
        <div className="group-10">
          <div className="order-ingredients">
            Order ingredients
          </div>
          <p className="text-wrapper-15">
            Order ingredients for your personal and BiteTracker recipes via Instacart, AmazonFresh or WholeFoods. The ingredients of the recipes are automatically linked to your shopping cart.

          </p>
          <img className="group-11" alt="Group" src="https://c.animaapp.com/DVqGB5xQ/img/group-6356147@2x.png" />
        </div>
        <div className="overlap-5">
          <div className="overlap-6">
            <img
              className="clip-path-group-3"
              alt="Clip path group"
              src="https://c.animaapp.com/DVqGB5xQ/img/clip-path-group-7@2x.png"
            />
            <img
              className="clip-path-group-4"
              alt="Clip path group"
              src="https://c.animaapp.com/DVqGB5xQ/img/clip-path-group-3@2x.png"
            />
            <img className="image-10" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-67@2x.png" />
            <img className="image-11" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-64@2x.png" />
            <img className="image-12" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-65@2x.png" />
            <img className="image-13" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-63@2x.png" />
            <img className="image-14" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-69@2x.png" />
            <img className="image-15" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-70@2x.png" />
            <img className="image-16" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-71@2x.png" />
            <div className="group-12">
              <div className="overlap-7">
                <div className="group-13">
                  <div className="group-14">
                    <div className="overlap-group-4">
                      <div className="mask-group-wrapper">
                        <div className="mask-group" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="group-15">
                  <div className="group-16">
                    <div className="group-17">
                      <div className="group-18">
                        <div className="group-19">
                          <div className="frame-wrapper">
                            <div className="frame-3">
                              <div className="frame-3">
                                <img
                                  className="vector"
                                  alt="Vector"
                                  src="https://c.animaapp.com/DVqGB5xQ/img/vector.svg"
                                />
                                <div className="text-wrapper-16">6 Min</div>
                              </div>
                            </div>
                          </div>
                          <div className="text-wrapper-17">On the way</div>
                        </div>
                        <div className="group-20">
                          <div className="frame-4">
                            <div className="rectangle-3" />
                            <div className="rectangle-4" />
                            <div className="rectangle-5" />
                          </div>
                          <div className="text-wrapper-18">Order placed</div>
                          <div className="text-wrapper-19">On the way</div>
                          <div className="text-wrapper-20">Delivered</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img
                className="rectangle-6"
                alt="Rectangle"
                src="https://c.animaapp.com/DVqGB5xQ/img/set-of-slice-tomatoes-isolated-png-02-9@2x.png"
              />
            </div>
            <img className="vector-2" alt="Vector" src="https://c.animaapp.com/DVqGB5xQ/img/vector-51.svg" />
            <img
              className="sliced-mushroom"
              alt="Sliced mushroom"
              src="https://c.animaapp.com/DVqGB5xQ/img/sliced-mushroom-isolated-on-a-transparent-background-png-1@2x.png"
            />
            <img
              className="mushroom-slice"
              alt="Mushroom slice"
              src="https://c.animaapp.com/DVqGB5xQ/img/mushroom-slice-isolated-on-a-transparent-background-png-1@2x.png"
            />
            <div className="ellipse-3" />
            <img className="image-17" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-72@2x.png" />
          </div>
          <img className="image-18" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-62@2x.png" />
        </div>
        <p className="eat-healthier">
          Eat according your goals with easy planning

        </p>
        <img
          className="clip-path-group-5"
          alt="Clip path group"
          src="https://c.animaapp.com/DVqGB5xQ/img/clip-path-group-6@2x.png"
        />
        <div className="overlap-8">
          <img
            className="clip-path-group-6"
            alt="Clip path group"
            src="https://c.animaapp.com/DVqGB5xQ/img/clip-path-group-5@2x.png"
          />
          <img
            className="clip-path-group-7"
            alt="Clip path group"
            src="https://c.animaapp.com/DVqGB5xQ/img/clip-path-group-4@2x.png"
          />
          <img
            className="set-of-slice-2"
            alt="Set of slice"
            src="https://c.animaapp.com/DVqGB5xQ/img/set-of-slice-tomatoes-isolated-png-02-2@2x.png"
          />
          <img className="image-19" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-3@2x.png" />
          <img className="image-20" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-30@2x.png" />
          <img className="image-21" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-24@2x.png" />
          <img
            className="set-of-slice-3"
            alt="Set of slice"
            src="https://c.animaapp.com/DVqGB5xQ/img/set-of-slice-tomatoes-isolated-png-02-4@2x.png"
          />
        </div>
        <img
          className="clip-path-group-8"
          alt="Clip path group"
          src="https://c.animaapp.com/DVqGB5xQ/img/clip-path-group-4@2x.png"
        />
        <div className="overlap-9">
          <div className="overlap-10">
            <img
              className="clip-path-group-9"
              alt="Clip path group"
              src="https://c.animaapp.com/DVqGB5xQ/img/clip-path-group-1@2x.png"
            />
            <img className="image-22" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-20@2x.png" />
            <img
              className="set-of-slice-4"
              alt="Set of slice"
              src="https://c.animaapp.com/DVqGB5xQ/img/set-of-slice-tomatoes-isolated-png-02-5@2x.png"
            />
            <img className="image-23" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-26@2x.png" />
            <img
              className="set-of-slice-5"
              alt="Set of slice"
              src="https://c.animaapp.com/DVqGB5xQ/img/set-of-slice-tomatoes-isolated-png-02-6@2x.png"
            />
            <img className="image-24" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-30@2x.png" />
            <img className="image-25" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-25@2x.png" />
          </div>
          <img className="image-26" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-27@2x.png" />
          <img className="image-27" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-28@2x.png" />
        </div>
        <img className="image-28" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-3@2x.png" />
        <img
          className="set-of-slice-6"
          alt="Set of slice"
          src="https://c.animaapp.com/DVqGB5xQ/img/set-of-slice-tomatoes-isolated-png-02-2@2x.png"
        />
        <img className="image-29" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-30@2x.png" />
        <img className="image-30" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-23@2x.png" />
        <div className="overlap-11">
          <div className="overlap-12">
            <div className="overlap-13">
              <img
                className="clip-path-group-10"
                alt="Clip path group"
                src="https://c.animaapp.com/DVqGB5xQ/img/clip-path-group-1@2x.png"
              />
              <img className="image-31" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-30@2x.png" />
              <img className="image-32" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-35@2x.png" />
              <img className="image-33" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-54@2x.png" />
              <img className="image-34" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-55@2x.png" />
              <img className="image-35" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-57@2x.png" />
              <img className="image-36" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-58@2x.png" />
              <div className="group-21">
                <div className="overlap-14">
                  <div className="overlap-15">
                    <div className="text-wrapper-21">Add ingredient</div>
                    <div className="group-22">
                      <div className="overlap-16">
                        <div className="text-wrapper-22">IMPUT MANUALLY</div>
                      </div>
                    </div>
                    <div className="group-23">
                      <div className="text-wrapper-23">Nutrition</div>
                      <div className="text-wrapper-24">Ingredients</div>
                      <div className="overlap-17">
                        <div className="group-24">
                          <div className="div-2">
                            <div className="div-2">
                              <div className="text-wrapper-25">Carbs</div>
                              <div className="text-wrapper-26">75 g</div>
                              <div className="text-wrapper-27">72%</div>
                              <div className="rectangle-wrapper">
                                <div className="rectangle-7" />
                              </div>
                            </div>
                            <div className="ellipse-4" />
                          </div>
                          <div className="ellipse-5" />
                        </div>
                        <div className="group-25">
                          <div className="text-wrapper-28">Protein</div>
                          <div className="text-wrapper-29">7g</div>
                          <div className="text-wrapper-30">17%</div>
                          <div className="overlap-18">
                            <div className="rectangle-8" />
                          </div>
                        </div>
                      </div>
                      <div className="group-26">
                        <div className="overlap-19">
                          <div className="group-27">
                            <div className="text-wrapper-31">Fat</div>
                            <div className="text-wrapper-32">4g</div>
                            <div className="text-wrapper-33">11%</div>
                            <div className="overlap-group-5">
                              <div className="rectangle-9" />
                            </div>
                          </div>
                          <div className="ellipse-4" />
                        </div>
                      </div>
                    </div>
                    <div className="group-28">
                      <div className="group-29">
                        <div className="text-wrapper-34">Brown rice</div>
                        <p className="text-wrapper-35">1/2 cup • 95 grams</p>
                        <p className="text-wrapper-36">3/4 cup • 176.3 grams</p>
                        <img
                          className="ellipse-6"
                          alt="Ellipse"
                          src="https://c.animaapp.com/DVqGB5xQ/img/ellipse-317@2x.png"
                        />
                        <div className="text-wrapper-37">Table salt</div>
                        <p className="text-wrapper-38">1/4 teaspoon • 1.5 grams</p>
                        <img
                          className="ellipse-7"
                          alt="Ellipse"
                          src="https://c.animaapp.com/DVqGB5xQ/img/ellipse-319@2x.png"
                        />
                        <img className="x" alt="X" src="https://c.animaapp.com/DVqGB5xQ/img/x-2.svg" />
                        <div className="text-wrapper-39">Vegetable stock</div>
                        <img
                          className="ellipse-8"
                          alt="Ellipse"
                          src="https://c.animaapp.com/DVqGB5xQ/img/ellipse-318@2x.png"
                        />
                        <img className="x-2" alt="X" src="https://c.animaapp.com/DVqGB5xQ/img/x-1.svg" />
                        <img className="x-3" alt="X" src="https://c.animaapp.com/DVqGB5xQ/img/x.svg" />
                        <img className="line" alt="Line" src="https://c.animaapp.com/DVqGB5xQ/img/line-38.svg" />
                        <img className="line-2" alt="Line" src="https://c.animaapp.com/DVqGB5xQ/img/line-39.svg" />
                      </div>
                    </div>
                  </div>
                  <img
                    className="rectangle-10"
                    alt="Rectangle"
                    src="https://c.animaapp.com/DVqGB5xQ/img/rectangle-23856@2x.png"
                  />
                  <div className="text-wrapper-40">Brown Rice</div>
                  <p className="text-wrapper-41">Healthy and simple brown rice.</p>
                  <div className="group-30">
                    <div className="overlap-group-6">
                      <div className="text-wrapper-42">SIDE DISH</div>
                    </div>
                  </div>
                  <div className="group-31">
                    <div className="overlap-group-6">
                      <div className="text-wrapper-43">LOW-FAT</div>
                    </div>
                  </div>
                  <div className="group-32">
                    <div className="overlap-20">
                      <div className="text-wrapper-44">VEGAN</div>
                    </div>
                  </div>
                  <div className="group-33">
                    <div className="overlap-21">
                      <div className="text-wrapper-45">GLUTEN FREE</div>
                    </div>
                  </div>
                  <div className="group-34">
                    <div className="overlap-22">
                      <div className="text-wrapper-46">DAIRY FREE</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-35">
                <div className="group-36">
                  <div className="input-field-with">
                    <div className="overlap-23">
                      <div className="dropdown-filled">
                        <div className="overlap-group-7">
                          <div className="text-wrapper-47">1100</div>
                        </div>
                      </div>
                      <div className="dropdown-filled-2">
                        <div className="overlap-24">
                          <div className="text-wrapper-48">Calories</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="overlap-25">
                    <div className="dropdown-filled">
                      <div className="overlap-group-7">
                        <div className="text-wrapper-49">150g</div>
                      </div>
                    </div>
                    <div className="dropdown-filled-2">
                      <div className="overlap-26">
                        <div className="text-wrapper-48">Protein</div>
                      </div>
                    </div>
                  </div>
                  <div className="overlap-27">
                    <div className="dropdown-filled">
                      <div className="overlap-group-7">
                        <div className="text-wrapper-49">180g</div>
                      </div>
                    </div>
                    <div className="dropdown-filled-2">
                      <div className="overlap-28">
                        <div className="text-wrapper-48">Carbs</div>
                      </div>
                    </div>
                  </div>
                  <div className="input-field-with-2">
                    <div className="overlap-23">
                      <div className="dropdown-filled">
                        <div className="overlap-group-7">
                          <div className="text-wrapper-50">60g</div>
                        </div>
                      </div>
                      <div className="dropdown-filled-2">
                        <div className="overlap-29">
                          <div className="text-wrapper-48">Fats</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="text-wrapper-51">Max calories / macros per day</p>
                </div>
              </div>
              <img className="image-37" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-52@2x.png" />
            </div>
            <div className="overlap-30">

              <video className="landing-desktop-video" width="100%" height="100%" loop autoPlay={true} muted src={calendario} poster="https://thebitetracker.com/landing-video-poster.png">

              </video>
              <div className="text-wrapper-52">Master Your Meal Planning</div>
              <p className="text-wrapper-53">
                Start crafting your perfect meal plan today and take charge of your nutrition.
              </p>
              <img className="image-38" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-56@2x.png" />
            </div>
            <div className="overlap-31">
              <img className="image-39" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-46@2x.png" />
              <img className="image-40" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-53@2x.png" />
              <img className="image-41" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-59@2x.png" />
            </div>
          </div>
          <div className="text-wrapper-54">Craft Your Perfect Plate</div>
          <p className="manage-your-meals-by">
            Manage your meals from our web and mobile apps by tracking calories, proteins, fats, and carbs, allowing you to craft a personalized,
            well-balanced weekly meal plan that aligns perfectly with your nutritional goals.
          </p>
        </div>
        <img className="image-42" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-28@2x.png" />
        <img className="image-43" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-33@2x.png" />
        <div className="overlap-32">
          <img className="image-44" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-19@2x.png" />
          <img
            className="feta-omelette"
            alt="Feta omelette"
            src="https://c.animaapp.com/DVqGB5xQ/img/feta-omelette-1@2x.png"
          />
          <img className="vector-3" alt="Vector" src="https://c.animaapp.com/DVqGB5xQ/img/vector-5.svg" />
          <div className="ellipse-9" />
          <div className="ellipse-10" />
          <div className="ellipse-11" />
          <div className="ellipse-12" />
        </div>
        <div className="save-time-on">
          Save time
          <br>
          </br> grocery shopping

        </div>
        <p className="reduce-your-food">
          Reduce food waste buying
          <br>
          </br>exactly what you need

        </p>
        <p className="text-wrapper-55">Feel organized and in charge</p>
        <img className="vector-4" alt="Vector" src="https://c.animaapp.com/DVqGB5xQ/img/vector-2-1@2x.png" />
        <img
          className="set-of-slice-7"
          alt="Set of slice"
          src="https://c.animaapp.com/DVqGB5xQ/img/set-of-slice-tomatoes-isolated-png-02-2@2x.png"
        />
        <p className="text-wrapper-56">Get control of your nutrition</p>


        {/* <div className="frame-6">
          <div className="text-wrapper-67">Process</div>
        </div>
        <div className="text-wrapper-68">Our Recipes</div>
        <div className="text-wrapper-69">Benefits</div> */}
        {/* <div className="ellipse-13" /> */}
        <div className="landing-recipes">
          <img
            className="grilled-salmon-2"
            alt="Grilled salmon"
            src="https://c.animaapp.com/DVqGB5xQ/img/grilled-salmon@2x.png"
          />
          <div className="grilled-salmon-2-hover">
            Grilled Salmon
          </div>
          <img
            className="cauliflower-puree"
            alt="Cauliflower puree"
            src="https://c.animaapp.com/DVqGB5xQ/img/cauliflower-puree@2x.png"
          />
          <div className="cauliflower-puree-hover">
            Cauliflower puree
          </div>
          <div className="overlap-38">
            <div className="rectangle-13" />
            <div className="oat-meal-pancakes-2">
            </div>
          </div>
          <div className="oatmeal-pancakes-hover">
            Oatmeal Pancakes
          </div>
          <img
            className="zucchini-wedges-2"
            alt="Zucchini wedges"
            src="https://c.animaapp.com/DVqGB5xQ/img/zucchini-wedges@2x.png"
          />
          <div className="zucchini-wedges-hover">
            Zucchini Wedges
          </div>
          <img
            className="BT-shiitake"
            alt="Bt shiitake"
            src="https://c.animaapp.com/DVqGB5xQ/img/bt-shiitake-mushrooms@2x.png"
          />
          <div className="BT-shiitake-hover">
            BT Shiitake
          </div>
          <img
            className="sweet-potato-fries"
            alt="Sweet potato fries"
            src="https://c.animaapp.com/DVqGB5xQ/img/sweet-potato-fries@2x.png"
          />
          <div className="sweet-potato-fries-hover">
            Sweet Potato Fries
          </div>
          <img
            className="sauteed-shrimp"
            alt="Sauteed shrimp"
            src="https://c.animaapp.com/DVqGB5xQ/img/saute-ed-shrimp@2x.png"
          />
          <div className="sauteed-shrimp-hover">
            Sauted Shrimp
          </div>
          <img
            className="feta-omelette-2"
            alt="Feta omelette"
            src="https://c.animaapp.com/DVqGB5xQ/img/feta-omelette.png"
          />
          <div className="feta-omelet-hover">
            Feta Omelet
          </div>
          <img
            className="set-of-slice-8"
            alt="Set of slice"
            src="https://c.animaapp.com/DVqGB5xQ/img/set-of-slice-tomatoes-isolated-png-02-9@2x.png"
          />
        </div>
        <div className="overlap-39">
          <img className="image-45" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-38@2x.png" />
          <div className="overlap-40">
            <img className="image-46" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-39@2x.png" />
            <img className="image-47" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-42@2x.png" />
            <img className="image-48" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-51@2x.png" />
            <div className="group-40" />
            <div className="capa-2">
              <div className="overlap-41">
                <img className="vector-5" alt="Vector" src="https://c.animaapp.com/DVqGB5xQ/img/vector-4.svg" />
                <img className="vector-6" alt="Vector" src="https://c.animaapp.com/DVqGB5xQ/img/vector-3.svg" />
                <img className="vector-7" alt="Vector" src="https://c.animaapp.com/DVqGB5xQ/img/vector-2.svg" />
                <div className="mix-match-wrapper">
                  <div className="mix-match">
                    Mix &amp;
                    <br />
                    Match
                  </div>
                </div>
                <img className="vector-8" alt="Vector" src="https://c.animaapp.com/DVqGB5xQ/img/vector-1.svg" />
              </div>
            </div>
          </div>
          <img className="image-49" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-40@2x.png" />
          <img className="image-50" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-41@2x.png" />
          <img className="image-51" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-48@2x.png" />
          <img className="image-52" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-45@2x.png" />
          <img className="image-53" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-50@2x.png" />
          <img className="image-54" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-49@2x.png" />
        </div>
        <img className="image-55" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-43@2x.png" />
        <img className="image-56" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-44@2x.png" />
        <img className="image-57" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-66@2x.png" />
        <img className="image-58" alt="" src="https://c.animaapp.com/DVqGB5xQ/img/image-2@2x.png" />
        <img className="capa-3" alt="Capa" src="https://c.animaapp.com/DVqGB5xQ/img/capa-1.svg" />
        <img className="group-41" alt="Group" src="https://c.animaapp.com/DVqGB5xQ/img/group-6356319@2x.png" />
        <img className="landing-whole-foods-2" alt="Group" src={wholeFoods} />

        <div className="try-it-now-bottom">
          <div className="overlap-3">
            <img className="rectangle" alt="Rectangle" src="https://c.animaapp.com/DVqGB5xQ/img/rectangle-73-1.svg" />
            <div className="group-5">
              <div className="overlap-group-2">
                <NavLink to="/calendar" ><div className="text-wrapper-12">Try it now</div></NavLink>
                <img className="arrow" alt="Arrow" src="https://c.animaapp.com/DVqGB5xQ/img/arrow-2-1.svg" />
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="overlap-36">
        <div className="group-39">
          <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=823334ce-e563-427c-a0bf-93463b10dea3"><div className="terms-conditions">Terms of Use</div></a>
          <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=713d822d-3691-46a8-a4f7-5a538d76124b"> <div className="text-wrapper-66">Privacy Policy</div></a>
          <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=f52f48b4-b14d-4771-8156-f35d484da8dc"> <div className="text-wrapper-66">Cookie Policy</div></a>

          <NavLink to="/blog" ><div className="terms-conditions">Blog</div></NavLink>
          <NavLink to="/contact_us" ><div className="terms-conditions">Contact Us</div></NavLink>


        </div>
      </div>
    </div>
  );
};
