// AIConsultationPage.jsx

import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';  
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  doc,
  setDoc,
  deleteDoc,
} from 'firebase/firestore';
import { AuthContext } from 'contexts/auth_context';
import { getDateMonthYear } from 'helpers/date_helpers';
import { getMealNutrientAmount } from 'helpers/recipe_helpers';
import { UserContext } from 'contexts/user_context';
import { CartContext } from 'contexts/cart_context';
import LoopIcon from '@material-ui/icons/Loop';
import MobileSettingsButton from 'components/shared/mobile_settings_button/mobile_settings_button';
import { Capacitor } from '@capacitor/core';
import CreateIcon from '@material-ui/icons/Create';
import AIPlanning from 'components/shared/ai_planning/ai_planning';
import ChatWithAI from 'components/shared/chat_with_ai/chat_with_ai';
import Chat from 'components/shared/chat_with_ai/chat_with_ai';

const AIConsultationPage = () => {
  const [messages, setMessages] = useState([
    {
      content:
        'Hello, how may I help you? You can ask me for example:\n* Help me adjust my macros\n* Review my progress thus far\n* Analyze my tracking consistency\n\nNote if you need help with recipes visit the [planning tab](/planning)',
      role: 'assistant',
    },
  ]);
  const [input, setInput] = useState('');
  const [threadId, setThreadId] = useState(null);
  const [threads, setThreads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [caloriesAndMacrosConsumed, setCaloriesAndMacrosConsumed] = useState(
    null
  );
  const [isFirstMessageInThread, setIsFirstMessageInThread] = useState(true); // Per thread message tracking
  const { currentUser } = useContext(AuthContext);
  const userObject = useContext(UserContext).userObject;
  const loadUsers = useContext(CartContext).loadUsers;
  const isNewlyWrittenUser = useContext(AuthContext).isNewlyWrittenUser;

  useEffect(() => {
    loadUsers();
  }, [isNewlyWrittenUser]);

  // Fetch existing threads from Firestore
  useEffect(() => {
    const fetchThreads = async () => {
      try {
        const db = getFirestore();
        const threadsCollection = collection(
          db,
          'users',
          currentUser.uid,
          'consultationThreads'
        );
        const threadsSnapshot = await getDocs(threadsCollection);
        let threadsData = [];

        threadsSnapshot.forEach((doc) => {
          threadsData.push({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate(),
          });
        });

        threadsData.sort((a, b) => b.createdAt - a.createdAt);

        setThreads(threadsData);
        // By default, start with a new thread when the page is accessed
      } catch (e) {
        console.log(e);
      }
    };
    fetchThreads();
  }, [currentUser]);

  // Fetch calorie and macros data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const db = getFirestore();
        let calendarMealCollectionRef = collection(
          db,
          'users',
          currentUser.uid,
          'calendarMeals'
        );

        const ninetyDaysAgo = new Date();
        ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);

        const querySnapshot = await getDocs(
          query(
            calendarMealCollectionRef,
            where('calendarDate', '>', ninetyDaysAgo)
          )
        );
        let calorieData = {};

        querySnapshot.forEach((doc) => {
          let tempDoc = doc.data();
          if (tempDoc.mealDate) {
            let dateMonthYear = tempDoc.mealDate;
            if (Object.keys(calorieData).includes(dateMonthYear)) {
              calorieData[dateMonthYear].calories += getMealNutrientAmount(
                tempDoc,
                'Calories'
              );
              calorieData[dateMonthYear].fat += getMealNutrientAmount(
                tempDoc,
                'Fat'
              );
              calorieData[dateMonthYear].carbs += getMealNutrientAmount(
                tempDoc,
                'Carbohydrates'
              );
              calorieData[dateMonthYear].protein += getMealNutrientAmount(
                tempDoc,
                'Protein'
              );
            } else {
              calorieData[dateMonthYear] = {
                calories: getMealNutrientAmount(tempDoc, 'Calories'),
                fat: getMealNutrientAmount(tempDoc, 'Fat'),
                carbs: getMealNutrientAmount(tempDoc, 'Carbohydrates'),
                protein: getMealNutrientAmount(tempDoc, 'Protein'),
              };
            }
          }
        });
        setCaloriesAndMacrosConsumed(calorieData);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [currentUser]);

  const handleSendMessage = async () => {
    if (input.trim() === '') return;

    const userMessage = { role: 'user', content: input };
    let updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages); // Update state with new messages

    let agentMessage;
    const currentInput = input;
    setInput('');
    setLoading(true);

    // If it's a new thread, add it immediately before the agent responds
    let isNewThread = false;
    let tempThreadId = threadId;

    if (!threadId) {
      isNewThread = true;
      tempThreadId = `temp-${Date.now()}`;
      const newThread = {
        id: tempThreadId,
        threadId: tempThreadId,
        messages: updatedMessages,
        createdAt: new Date(),
      };
      setThreads([newThread, ...threads]);
      setThreadId(tempThreadId);
    }

    // Determine the message to show during loading
    const loadingMessage = isFirstMessageInThread
      ? 'Processing your weight history, food intake, hydration and exercise data. Please wait...'
      : 'Processing your last message, please wait...';

    updatedMessages = [
      ...updatedMessages,
      { role: 'assistant', content: loadingMessage },
    ];
    setMessages(updatedMessages);

    try {
      // Pass null to the server if it's a new thread
      const serverThreadId = isNewThread ? null : threadId;

      let waterIntakeHistoryInOunces = {};

      if (userObject.waterIntake) {
        for (let date of Object.keys(userObject.waterIntake)) {
          waterIntakeHistoryInOunces[date] =
            userObject.waterIntake[date] *
            (userObject.cupSizeOz ? userObject.cupSizeOz : 8);
        }
      }

      // **Activity Level Mappings**
      const activityLevelMapping = {
        1.2: 'Sedentary',
        1.3: 'Lightly Active',
        1.4: 'Moderately Active',
        1.5: 'Very Active',
      };

      const cardioActivityMapping = {
        1.0: '0-1 times per week',
        1.06: '2-3 times per week',
        1.12: '4-5 times per week',
        1.18: '6-7 times per week',
      };

      const muscleBuildingActivityMapping = {
        1.0: '0-1 times per week',
        1.06: '2-3 times per week',
        1.12: '4-5 times per week',
        1.18: '6-7 times per week',
      };

      // Build the request body, including optional fields if they exist
      const requestBody = {
        threadId: serverThreadId,
        message: currentInput,
        caloriesAndMacrosConsumed: caloriesAndMacrosConsumed,
        weightHistory: userObject.weight,
        caloriesAndMacrosTargeted: {
          calories: userObject.calories,
          fat: userObject.fats,
          carbs: userObject.carbs,
          protein: userObject.proteins,
        },
        exerciseHistory: userObject.exerciseData,
        waterIntakeHistory: waterIntakeHistoryInOunces,
        stepData: userObject.stepData,
        stepGoal: userObject.stepGoal,
      };

      // Conditionally include optional fields
      if (userObject.weightProjection) {
        requestBody.weightProjection = userObject.weightProjection;
      }
      if (userObject.weightGoal) {
        requestBody.weightGoal = userObject.weightGoal;
      }
      if (
        userObject.bodyFatGoal !== undefined &&
        userObject.bodyFatGoal !== null
      ) {
        requestBody.bodyFatGoal = userObject.bodyFatGoal;
      }
      if (
        userObject.activityMultiplier ||
        userObject.cardioModifier ||
        userObject.muscleBuildingModifier
      ) {
        // Map numerical values to readable strings
        const activityMultiplier = userObject.activityMultiplier;
        const cardioModifier = userObject.cardioModifier;
        const muscleBuildingModifier = userObject.muscleBuildingModifier;

        const dailyLifestyle =
          activityLevelMapping[activityMultiplier] ||
          `Activity multiplier: ${activityMultiplier}`;
        const cardioActivityDescription =
          cardioActivityMapping[cardioModifier] ||
          `Cardio modifier: ${cardioModifier}`;
        const muscleBuildingActivityDescription =
          muscleBuildingActivityMapping[muscleBuildingModifier] ||
          `Muscle-building modifier: ${muscleBuildingModifier}`;

        requestBody.activityLevels = {
          dailyLifestyle,
          cardioActivityDescription,
          muscleBuildingActivityDescription,
        };
      }
      if (userObject.projectedGoalDate) {
        requestBody.projectedGoalDate = userObject.projectedGoalDate;
      }

      // Modified request to use POST and include additional data
      const response = await axios.post('consultWithAI', requestBody, {
        headers: {
          'Content-Type': 'application/json',
          // Include any headers if necessary, like authentication tokens
          // Authorization: `Bearer ${currentUser.token}`, // if you have an auth token
        },
      });

      if (response.data) {
        let newAgentMessages = [];
        for (let msg of response.data) {
          if (msg.role !== 'user') {
            let responseText = msg.content[0].text.value;
            let isExisting = updatedMessages.some(
              (existingMsg) => existingMsg.content === responseText
            );

            if (!isExisting) {
              newAgentMessages.push({ role: msg.role, content: responseText });
              agentMessage = { role: msg.role, content: responseText };
            }
          }
        }

        // Remove the loading message
        updatedMessages = updatedMessages.slice(0, -1);

        // Append the new agent messages
        updatedMessages = updatedMessages.concat(newAgentMessages);
        setMessages(updatedMessages);

        const db = getFirestore();
        const responseThreadId = response.data[0].thread_id;

        if (isNewThread) {
          // Update the thread ID with the one from the agent
          setThreadId(responseThreadId);

          // Update the thread in Firestore with the correct thread ID
          const threadRef = doc(
            db,
            'users',
            currentUser.uid,
            'consultationThreads',
            responseThreadId
          );
          await setDoc(
            threadRef,
            {
              threadId: responseThreadId,
              messages: updatedMessages,
              createdAt: new Date(),
            },
            { merge: true }
          );

          // Update the thread in the threads list
          setThreads((prevThreads) => {
            const updatedThreads = prevThreads.map((thread) => {
              if (thread.threadId === tempThreadId) {
                return {
                  ...thread,
                  id: responseThreadId,
                  threadId: responseThreadId,
                  messages: updatedMessages,
                };
              }
              return thread;
            });
            return updatedThreads;
          });
        } else {
          const threadDoc = doc(
            db,
            'users',
            currentUser.uid,
            'consultationThreads',
            threadId
          );
          await setDoc(
            threadDoc,
            { messages: updatedMessages },
            { merge: true }
          );

          setThreads((prevThreads) => {
            const updatedThreads = prevThreads.map((thread) => {
              if (thread.threadId === threadId) {
                return {
                  ...thread,
                  messages: updatedMessages,
                };
              }
              return thread;
            });
            return updatedThreads;
          });
        }

        // Log to Firebase Analytics
        FirebaseAnalytics.logEvent({
          name: 'consult_with_ai_message',
          params: {
            userMessage: userMessage.content,
            agentMessage: agentMessage?.content,
          },
        });

        // After the first message in the thread, set isFirstMessageInThread to false
        setIsFirstMessageInThread(false);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleTabClick = (selectedThreadId) => {
    const selectedThread = threads.find(
      (thread) => thread.threadId === selectedThreadId
    );
    if (selectedThread) {
      setThreadId(selectedThreadId);
      setMessages(selectedThread.messages);
      setIsFirstMessageInThread(selectedThread.messages.length === 1);
    }
  };

  const handleCreateNewThread = () => {
    setThreadId(null);
    setMessages([
      {
        content:
          'Hello, how may I help you? You can ask me for example:\n* Help me adjust my macros\n* Review my progress thus far\n* Analyze my tracking consistency\n\nNote if you need help with recipes visit the [planning tab](/planning)',
        role: 'assistant',
      },
    ]);
    setIsFirstMessageInThread(true); // Set this true for the new thread
  };

  const handleDeleteThread = async (threadIdToDelete) => {
    const db = getFirestore();
    await deleteDoc(
      doc(db, 'users', currentUser.uid, 'consultationThreads', threadIdToDelete)
    );

    const updatedThreads = threads.filter(
      (thread) => thread.threadId !== threadIdToDelete
    );
    setThreads(updatedThreads);

    if (threadId === threadIdToDelete) {
      if (updatedThreads.length > 0) {
        const newSelectedThread = updatedThreads[0];
        setThreadId(newSelectedThread.threadId);
        setMessages(newSelectedThread.messages);
        setIsFirstMessageInThread(newSelectedThread.messages.length === 1);
      } else {
        setThreadId(null);
        setMessages([
          {
            content:
              'Hello, how may I help you? You can ask me for example:\n* Help me adjust my macros\n* Review my progress thus far\n* Analyze my tracking consistency\n\nNote if you need help with recipes visit the [planning tab](/planning)',
            role: 'assistant',
          },
        ]);
        setIsFirstMessageInThread(true);
      }
    }
  };

  const formatDate = (date) => {
    const options = {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return date.toLocaleDateString('en-US', options);
  };

  const renderers = {
    link: ({ href, children }) => {
      if (href.startsWith('/')) {
        return <Link to={href}>{children}</Link>;
      }
      return (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    },
  };

  return (
    <div className="ai-chat-container">
      {Capacitor.getPlatform() !== 'web' && (
        <MobileSettingsButton isPlanning={true} />
      )}

      <div className="ai-chat-header">AI Tracking Consultation</div>
      <div className="ai-chat-subheader">
        Get personalized recommendations from our AI agent based on your
        historical tracking data
      </div>

      <div className="ai-chat-threads-wrapper">
        <div className="ai-chat-threads-container">
          {threads.map((thread) => (
            <div key={thread.id} className="ai-chat-thread-item">
              <div
                className={`ai-chat-thread ${thread.threadId === threadId ? 'active' : ''}`}
              >
                <button
                  style={{ background: 'none', border: 'none' }}
                  onClick={() => handleTabClick(thread.threadId)}
                >
                  {formatDate(thread.createdAt)}
                </button>
                <button
                  style={{
                    background: 'none',
                    border: 'none',
                    marginLeft: '0px',
                    marginRight: '-5px',
                  }}  
                  onClick={() => handleDeleteThread(thread.threadId)}
                >
                  X
                </button>
              </div>
            </div>
          ))}
        </div>
        <button className="ai-chat-new-thread-button" onClick={handleCreateNewThread}>
          <CreateIcon />
        </button>
      </div>

      <div className="ai-chat-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`ai-chat-message ${msg.role}`}>
            <ReactMarkdown components={renderers}>{msg.content}</ReactMarkdown>
          </div>
        ))}
        {error && <div className="ai-chat-error">{error}</div>}
      </div>
      <div className="ai-chat-input-wrapper">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => (e.key === 'Enter' ? handleSendMessage() : null)}
          className="ai-chat-input"
          placeholder="Type your message..."
          disabled={loading}
        />
        <button
          onClick={handleSendMessage}
          className="ai-chat-send-button"
          disabled={loading}
        >
          {loading ? (
            <>
              Sending{' '}
              <LoopIcon
                style={{
                  animation: 'spin 2s linear infinite',
                  marginLeft: '2px',
                  fontSize: '20px',
                }}
              />
            </>
          ) : (
            'Send'
          )}
        </button>
      </div>
    </div>
  );
};

export default AIConsultationPage;
