import { HealthConnect } from 'capacitor-health-connect';
import { CapacitorHealthkit } from '@perfood/capacitor-healthkit';
import { Capacitor } from '@capacitor/core';
import { getDateMonthYear } from 'helpers/date_helpers';

class HealthService {
  static log(message) {
    console.log(message);
    if (Capacitor.getPlatform() === 'android') {
      console.debug('[HealthService] ' + message);
    }
  }

  static async requestAndroidPermissions() {
    if (Capacitor.getPlatform() !== 'android') return true;

    try {
      // Check if Health Connect is available
      const availability = await HealthConnect.checkAvailability();
      HealthService.log('Health Connect availability: ' + availability.availability);
      
      if (availability.availability !== 'installed') {
        throw new Error('Health Connect is not installed');
      }

      // Request permissions
      const permissions = {
        read: ['Steps', 'Weight', 'BodyFat'],
        write: ['Steps', 'Weight', 'BodyFat']
      };

      const result = await HealthConnect.requestPermissions(permissions);
      HealthService.log('Permission request result: ' + JSON.stringify(result));
      
      return true;
    } catch (error) {
      HealthService.log('Error requesting permissions: ' + error.message);
      throw error;
    }
  }

  static getLastUsedWeightUnit(weightData) {
    const sortedDates = Object.keys(weightData).sort().reverse();
    for (const date of sortedDates) {
      if (weightData[date].unit) {
        return weightData[date].unit;
      }
    }
    return 'kg'; // Default to kg if no previous unit found
  }

  static convertWeight(value, fromUnit, toUnit) {
    if (fromUnit === toUnit) return value;

    if (fromUnit === 'kg' && toUnit === 'lbs') {
      const converted = Math.round(value * 2.20462262185 * 10) / 10; // Round to 1 decimal place
      console.log(`Converting ${value}kg to ${converted}lbs`);
      return converted;
    } else if (fromUnit === 'lbs' && toUnit === 'kg') {
      const converted = Math.round(value * 0.45359237 * 10) / 10; // Round to 1 decimal place
      console.log(`Converting ${value}lbs to ${converted}kg`);
      return converted;
    }
    return Math.round(value * 10) / 10; // Also round the value if no conversion needed
  }

  static async syncHistoricalData(userObject, setUserObject, showBanner) {
    if (Capacitor.getPlatform() === 'web') return;

    try {
      // Request permissions first on Android
      if (Capacitor.getPlatform() === 'android') {
        await HealthService.requestAndroidPermissions();
      }

      // Define source priorities (higher number = higher priority)
      const SOURCE_PRIORITIES = {
        'Apple Watch': 3,
        'Apple Watch Series': 3,  // Some watches report this way
        'iPhone': 2,
        'iPhone14,2': 2,         // Some phones report their model
        'Health': 1,             // Generic health app data
        'default': 0             // Any unknown source
      };

      const getSourcePriority = (source) => {
        // Check for partial matches (e.g., "Apple Watch Series 8" should match "Apple Watch")
        for (const [key, priority] of Object.entries(SOURCE_PRIORITIES)) {
          if (source.includes(key)) return priority;
        }
        return SOURCE_PRIORITIES.default;
      };

      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 30);

      // Create copies of the data we might update
      const newStepData = { ...userObject.stepData };
      const newWeightData = { ...userObject.weight };
      const newBodyFatData = { ...userObject.bodyFat };
      let dataUpdated = false;

      // Process steps data
      if (userObject.healthSync?.steps) {
        if (Capacitor.getPlatform() === 'ios') {
          console.log('Fetching steps from HealthKit...');

          const result = await CapacitorHealthkit.queryHKitSampleType({
            sampleName: "stepCount",
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            limit: 0
          });

          if (result && result.resultData && result.resultData.length > 0) {
            console.log('Processing steps data...');

            // Group by date first
            const dailySteps = {};

            result.resultData.forEach(record => {
              const dateObj = new Date(record.startDate);
              if (isNaN(dateObj.getTime())) {
                console.log('Warning: Found invalid date in steps data');
                return;
              }
              const dateKey = getDateMonthYear(dateObj);

              if (!dailySteps[dateKey]) {
                dailySteps[dateKey] = {
                  bySource: {},
                  sources: new Set()
                };
              }

              // Group samples by source
              const sourceName = record.source || 'unknown';
              if (!dailySteps[dateKey].bySource[sourceName]) {
                dailySteps[dateKey].bySource[sourceName] = {
                  total: 0,
                  priority: getSourcePriority(sourceName),
                  samples: []
                };
              }

              dailySteps[dateKey].bySource[sourceName].total += record.value;
              dailySteps[dateKey].bySource[sourceName].samples.push({
                value: record.value,
                startDate: record.startDate,
                endDate: record.endDate
              });
              dailySteps[dateKey].sources.add(sourceName);
            });

            // Process each day's data using priorities
            Object.entries(dailySteps).forEach(([dateKey, dayData]) => {
              // Find the highest priority source with data
              const sources = Object.entries(dayData.bySource);
              sources.sort((a, b) => b[1].priority - a[1].priority);

              const highestPrioritySource = sources[0];

              if (!newStepData[dateKey] || newStepData[dateKey].source === 'healthKit') {
                newStepData[dateKey] = {
                  value: highestPrioritySource[1].total,
                  source: 'healthKit',
                  primarySource: highestPrioritySource[0],
                  priority: highestPrioritySource[1].priority,
                  allSources: Object.fromEntries(
                    sources.map(([source, data]) => [
                      source,
                      { total: data.total, samples: data.samples.length }
                    ])
                  )
                };

                console.log(`${dateKey}: Using ${highestPrioritySource[0]} data (${highestPrioritySource[1].total} steps)`);
                if (sources.length > 1) {
                  console.log(`Other sources: ${sources.slice(1).map(([source, data]) =>
                    `${source}: ${data.total} steps`).join(', ')}`);
                }

                dataUpdated = true;
              }
            });

          } else {
            console.log('No steps data found in the last 30 days');
          }
        } else if (Capacitor.getPlatform() === 'android') {
          HealthService.log('Fetching steps from Health Connect...');
          
          const timeRangeFilter = {
            type: 'between',
            startTime: startDate,
            endTime: endDate
          };
          
          try {
            const stepsResponse = await HealthConnect.readRecords({
              type: 'Steps',
              timeRangeFilter,
              ascendingOrder: true
            });
            

            if (stepsResponse && stepsResponse.records && stepsResponse.records.length > 0) {
              HealthService.log('Processing steps data...');
              
              // Group by date first
              const dailySteps = {};
              
              stepsResponse.records.forEach(record => {
                const dateObj = new Date(record.startTime);
                if (isNaN(dateObj.getTime())) {
                  console.log('Warning: Found invalid date in steps data');
                  return;
                }
                
                const dateKey = getDateMonthYear(dateObj);
                
                if (!dailySteps[dateKey]) {
                  dailySteps[dateKey] = {
                    bySource: {},
                    sources: new Set()
                  };
                }
                
                // Group samples by source
                const sourceName = record.metadata?.dataOrigin || 'Health Connect';
                if (!dailySteps[dateKey].bySource[sourceName]) {
                  dailySteps[dateKey].bySource[sourceName] = {
                    total: 0,
                    priority: getSourcePriority(sourceName),
                    samples: []
                  };
                }
                
                dailySteps[dateKey].bySource[sourceName].total += record.count;
                dailySteps[dateKey].bySource[sourceName].samples.push({
                  value: record.count,
                  startDate: record.startTime,
                  endDate: record.endTime
                });
                dailySteps[dateKey].sources.add(sourceName);
              });
              
              // Process each day's data using priorities
              Object.entries(dailySteps).forEach(([dateKey, dayData]) => {
                // Find the highest priority source with data
                const sources = Object.entries(dayData.bySource);
                sources.sort((a, b) => b[1].priority - a[1].priority);
                
                const highestPrioritySource = sources[0];
                
                if (!newStepData[dateKey] || newStepData[dateKey].source === 'healthKit') {
                  newStepData[dateKey] = {
                    value: highestPrioritySource[1].total,
                    source: 'healthKit',
                    primarySource: highestPrioritySource[0],
                    priority: highestPrioritySource[1].priority,
                    allSources: Object.fromEntries(
                      sources.map(([source, data]) => [
                        source,
                        { total: data.total, samples: data.samples.length }
                      ])
                    )
                  };
                  
                  console.log(`${dateKey}: Using ${highestPrioritySource[0]} data (${highestPrioritySource[1].total} steps)`);
                  if (sources.length > 1) {
                    console.log(`Other sources: ${sources.slice(1).map(([source, data]) =>
                      `${source}: ${data.total} steps`).join(', ')}`);
                  }
                  
                  dataUpdated = true;
                }
              });
            } else {
              console.log('No steps data found in the last 30 days');
            }
          } catch (error) {
            console.error('Error syncing steps:', error);
          }
        }
      }

      // Process weight and body fat data
      if (userObject.healthSync?.weight || userObject.healthSync?.bodyFat) {
        try {
          // Get the last used weight unit
          const lastUsedUnit = HealthService.getLastUsedWeightUnit(userObject.weight);

          if (Capacitor.getPlatform() === 'ios') {
            try {
              // Process weight if enabled
              if (userObject.healthSync?.weight) {
                const weightQuery = {
                  sampleName: "weight",
                  startDate: startDate.toISOString(),
                  endDate: endDate.toISOString(),
                  limit: 0
                };

                const weightResult = await CapacitorHealthkit.queryHKitSampleType(weightQuery);
                if (weightResult?.resultData?.length > 0) {
                  weightResult.resultData.forEach(record => {
                    const dateObj = new Date(record.startDate);
                    if (isNaN(dateObj.getTime())) return;

                    const dateKey = getDateMonthYear(dateObj);
                    if (!newWeightData[dateKey] || newWeightData[dateKey].source === 'healthKit') {
                      const convertedValue = HealthService.convertWeight(record.value, 'kg', lastUsedUnit);
                      newWeightData[dateKey] = {
                        value: convertedValue,
                        unit: lastUsedUnit,
                        source: 'healthKit'
                      };
                      dataUpdated = true;
                    }
                  });
                }
              }

              // Process body fat if enabled
              if (userObject.healthSync?.bodyFat) {
                const bodyFatQuery = {
                  sampleName: "bodyFat",
                  startDate: startDate.toISOString(),
                  endDate: endDate.toISOString(),
                  limit: 0
                };

                const bodyFatResult = await CapacitorHealthkit.queryHKitSampleType(bodyFatQuery);
                if (bodyFatResult?.resultData?.length > 0) {
                  bodyFatResult.resultData.forEach(record => {
                    const dateObj = new Date(record.startDate);
                    if (isNaN(dateObj.getTime())) return;

                    const dateKey = getDateMonthYear(dateObj);
                    if (!newBodyFatData[dateKey] ||
                      (typeof newBodyFatData[dateKey] === 'object' && newBodyFatData[dateKey].source === 'healthKit') ||
                      (typeof newBodyFatData[dateKey] === 'number' && userObject.bodyFat[dateKey].source === 'healthKit')) {
                      newBodyFatData[dateKey] = {
                        value: Math.round(record.value * 1000) / 10,
                        source: 'healthKit'
                      };
                      dataUpdated = true;
                    }
                  });
                }
              }
            } catch (healthKitError) {
              showBanner('Error accessing health data: ' + healthKitError.message);
            }
          } else if (Capacitor.getPlatform() === 'android') {
            // Android Health Connect implementation
            const timeRangeFilter = {
              type: 'between',
              startTime: startDate,
              endTime: endDate
            };
            
            // Process weight if enabled
            if (userObject.healthSync?.weight) {
              console.log('Syncing weight data from Health Connect...');
              try {
                const weightResponse = await HealthConnect.readRecords({
                  type: 'Weight',
                  timeRangeFilter,
                  ascendingOrder: true
                });
                
                console.log('Weight response:', JSON.stringify(weightResponse, null, 2));
                
                if (weightResponse?.records?.length > 0) {
                  weightResponse.records.forEach(record => {
                    const dateObj = new Date(record.time);
                    if (isNaN(dateObj.getTime())) return;
                    
                    const dateKey = getDateMonthYear(dateObj);
                    if (!newWeightData[dateKey] || newWeightData[dateKey].source === 'healthKit') {
                      // Convert to kg first, then to user's preferred unit
                      let weightInKg = record.weight.unit === 'kilogram' 
                        ? record.weight.value 
                        : record.weight.unit === 'gram'
                          ? record.weight.value / 1000
                          : record.weight.unit === 'pound'
                            ? record.weight.value * 0.45359237
                            : record.weight.value; // Default to assuming kg
                      
                      const convertedValue = HealthService.convertWeight(weightInKg, 'kg', lastUsedUnit);
                      
                      newWeightData[dateKey] = {
                        value: convertedValue,
                        unit: lastUsedUnit,
                        source: 'healthKit'
                      };
                      dataUpdated = true;
                    }
                  });
                }
              } catch (error) {
                console.error('Error syncing weight:', error);
              }
            }
            
            // Process body fat if enabled
            if (userObject.healthSync?.bodyFat) {
              console.log('Syncing body fat data from Health Connect...');
              try {
                const bodyFatResponse = await HealthConnect.readRecords({
                  type: 'BodyFat',
                  timeRangeFilter,
                  ascendingOrder: true
                });
                
                console.log('Body fat response:', JSON.stringify(bodyFatResponse, null, 2));
                
                if (bodyFatResponse?.records?.length > 0) {
                  bodyFatResponse.records.forEach(record => {
                    const dateObj = new Date(record.time);
                    if (isNaN(dateObj.getTime())) return;
                    
                    const dateKey = getDateMonthYear(dateObj);
                    if (!newBodyFatData[dateKey] || 
                      (typeof newBodyFatData[dateKey] === 'object' && newBodyFatData[dateKey].source === 'healthKit') ||
                      (typeof newBodyFatData[dateKey] === 'number')) {
                      
                      newBodyFatData[dateKey] = {
                        value: Math.round(record.percentage.value * 10) / 10, // Round to 1 decimal place
                        source: 'healthKit'
                      };
                      dataUpdated = true;
                    }
                  });
                }
              } catch (error) {
                console.error('Error syncing body fat:', error);
              }
            }
          }
        } catch (outerError) {
          showBanner('Error syncing health data');
        }
      }

      // Only update the user object if we actually have new data
      if (dataUpdated) {
        const updatedUserObject = { ...userObject };
        updatedUserObject.stepData = newStepData;
        updatedUserObject.weight = newWeightData;
        updatedUserObject.bodyFat = newBodyFatData;
        setUserObject(updatedUserObject);
        showBanner('Health data sync complete');
      } else {
        console.log('No new health data found');
      }

    } catch (error) {
      console.error('Error syncing health data:', error);
      showBanner('Error syncing health data');
    }
  }
}

export default HealthService;
