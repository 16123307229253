// onboarding_wizard.js

import './onboarding_wizard.css';
import React from 'react';
import freeIcon from 'images/free-icon.png';
import saveTimeIcon from 'images/save-time-icon.png';
import ideasIcon from 'images/ideas-icon.png';
import takeAwayOnboarding from 'images/take-away-rafiki-2.png';

import CalorieCalculator from '../calorie_calculator/calorie_calculator';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { UserContext } from 'contexts/user_context';
import DietaryPreferences from '../dietary_preferences/dietary_preferences';
import { useHistory } from 'react-router-dom';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const navigationButtonsStyle = {
  display: 'flex',
  gap: '10px',
  width: '100%',
  padding: '20px 0',
  borderTop: '1px solid #eee',
  backgroundColor: 'white',
  marginTop: 'auto'
};

const buttonStyle = {
  flex: 1,
  padding: '14px',
  border: 'none',
  borderRadius: '8px',
  fontSize: '16px',
  fontFamily: 'Montserrat-SemiBold',
  cursor: 'pointer'
};

const primaryButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#2D7933',
  color: 'white',
};

const secondaryButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#f5f5f5',
  color: '#666',
};

const OnboardingWizard = (props) => {
  const { userObject, setUserObject, setNotificationPreferences } = React.useContext(UserContext);
  const history = useHistory();

  const [onboardingStage, setOnboardingStage] = React.useState(
    userObject.onboardingStatus?.currentStage || 0
  );
  const [skipped, setSkipped] = React.useState(false);

  const [selectedTracking, setSelectedTracking] = React.useState(true);
  const [selectedPlanning, setSelectedPlanning] = React.useState(true);

  const [wantsReminders, setWantsReminders] = React.useState(true);
  const [reminderTime, setReminderTime] = React.useState('20:00');


  const dietaryPreferencesRef = React.useRef(null);
  const contentRef1 = React.useRef(null);
  const contentRef2 = React.useRef(null);


  const updateOnboardingStatus = (stage) => {
    setOnboardingStage(stage);
    console.log('updating status')

    if (stage !== 5 && stage !== 4) {
      setUserObject({
        ...userObject,
        onboardingStatus: {
          isComplete: false,
          currentStage: stage
        }
      });
    }
  };

  function handleFirstClick() {
    updateOnboardingStatus(1);
  }


  function handleNextClick() {
    if (!selectedTracking && !selectedPlanning) return;

    if (onboardingStage === 2) {
      if (selectedPlanning) {
        updateOnboardingStatus(3);
      } else if (selectedTracking) {
        updateOnboardingStatus(4);
      } else {
        handleEndOfOnboarding();
      }
    } else if (onboardingStage === 3) {
      if (selectedTracking) {
        updateOnboardingStatus(4);
      } else {
        handleEndOfOnboarding();
      }
    } else if (onboardingStage === 4) {
      updateOnboardingStatus(5);
    } else if (onboardingStage === 5) {
      handleNotificationsConfirm();
    } else {
      handleEndOfOnboarding();
    }
  }

  function handleSkipClick() {
    setSkipped(true);
    updateOnboardingStatus(5);
  }

  const handleNotificationsConfirm = async () => {
    try {
      // Use the context function to set notification preferences
      const success = await setNotificationPreferences(wantsReminders, reminderTime);

      // Log user's choices during onboarding
      FirebaseAnalytics.logEvent({
        name: 'notification_preferences_set',
        params: {
          setUpReminders: wantsReminders,
          reminderTime: wantsReminders ? reminderTime : null,
        },
      });

      // Only proceed if notifications were set successfully or if they're disabled
      if (success || !wantsReminders) {
        handleEndOfOnboarding();
      }
    } catch (error) {
      console.error('Error setting notification preferences:', error);
      // You might want to show an error message to the user here
      handleEndOfOnboarding(); // Proceed anyway to not block the user
    }
  };

  function handleEndOfOnboarding() {
    if (selectedPlanning && selectedTracking) {
      FirebaseAnalytics.logEvent({
        name: 'chose_onboarding_option',
        params: { chosenOnboarding: 'both' },
      });
    } else if (selectedPlanning) {
      history.push('/planning');
      FirebaseAnalytics.logEvent({
        name: 'chose_onboarding_option',
        params: { chosenOnboarding: 'planning' },
      });
    } else {
      FirebaseAnalytics.logEvent({
        name: 'chose_onboarding_option',
        params: { chosenOnboarding: 'tracking' },
      });
    }
  }

  const baseContainerStyle = {
    width: '100%',
    maxWidth: '100vw',
    backgroundColor: 'white',
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 20px 0 20px',
    boxSizing: 'border-box',
    ...(window.innerWidth >= 768 ? {
      minHeight: 'auto',
      margin: '20px auto',
      maxWidth: '600px',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
    } : {
      minHeight: '100dvh'
    })
  };

  const contentWrapperStyle = {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',
    padding: '0 0 20px 0'
  };

  const handleBack = () => {
    if (onboardingStage > 0) {
      updateOnboardingStatus(onboardingStage - 1);
    }
  };


  if (onboardingStage === 0) {
    return (
      <div style={baseContainerStyle} className="onboarding-wizard-base-container">
        <div style={{ ...contentWrapperStyle }} >
          <div className="onboarding-wizard-content" style={{ paddingBottom: '40px' }}>
            <div style={{ padding: '10px 20px' }}>
              <div style={{ textAlign: 'center', marginBottom: '30px' }}>
                <div className="onboarding-wizard-title-first">Welcome to</div>
                <div className="onboarding-wizard-title-first-next">
                  BITE<span style={{ color: '#2D7933' }}>TRACKER</span>!
                </div>
              </div>

              <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                <div className="onboarding-wizard-upsell-row">
                  <div className="onboarding-wizard-upsell-row-icon">
                    <img
                      className="onboarding-wizard-upsell-row-icon-inner"
                      alt="Free"
                      src={freeIcon}
                    />
                  </div>
                  <div className="onboarding-wizard-upsell-row-text-first">
                    We are currently <b>100% free</b> with early access open to everyone
                  </div>
                </div>

                <div className="onboarding-wizard-upsell-row">
                  <div className="onboarding-wizard-upsell-row-icon">
                    <img
                      className="onboarding-wizard-upsell-row-icon-inner"
                      alt="Save Time"
                      src={saveTimeIcon}
                    />
                  </div>
                  <div className="onboarding-wizard-upsell-row-text-first">
                    We want to help you save time & achieve your goals - at <b>your pace.</b>
                  </div>
                </div>

                <div className="onboarding-wizard-upsell-row">
                  <div className="onboarding-wizard-upsell-row-icon">
                    <img
                      className="onboarding-wizard-upsell-row-icon-inner"
                      alt="Ideas"
                      src={ideasIcon}
                    />
                  </div>
                  <div className="onboarding-wizard-upsell-row-text-first">
                    It's still early days for us so keep an eye for new features. If you have feedback, <b>reach out to us!</b>
                  </div>
                </div>
              </div>

              <div style={{ textAlign: 'center', margin: '30px 0' }}>
                <div>Cheers,</div>
                <div style={{ fontFamily: 'Montserrat-SemiBold', marginTop: '6px' }}>
                  The BiteTracker Team
                </div>
              </div>
            </div>

            <div style={{
              textAlign: 'center',
              marginTop: 'auto',
              marginBottom: '10px'
            }}>
              <img
                style={{
                  width: '100%',
                  maxWidth: '300px',
                  height: 'auto',
                  objectFit: 'contain'
                }}
                alt="Welcome"
                src="3857131-1.png"
              />
            </div>
          </div>
        </div>

        <div style={{ ...navigationButtonsStyle, position: window.innerWidth >= 768 ? 'static' : 'absolute', bottom: '0px', width: '90%' }}>
          <button
            onClick={handleFirstClick}
            style={primaryButtonStyle}
          >
            Get Started
          </button>
        </div>
      </div>
    );
  }

  if (onboardingStage === 1) {
    return (
      <div style={baseContainerStyle}>
        <div style={contentWrapperStyle}>
          <div className="onboarding-wizard-content" ref={contentRef1}>
            <div className="onboarding-wizard-title">
              With our app you will be able to:
            </div>

            <div className="onboarding-wizard-upsell-row">
              <div className="onboarding-wizard-upsell-row-inner">
                <img
                  className="onboarding-wizard-upsell-row-image"
                  alt="Recipe Book"
                  key="1"
                  src="group-18442.png"
                />
              </div>
              <div className="onboarding-wizard-upsell-row-text">
                <div className="onboarding-wizard-upsell-row-number">1</div>
                <div style={{ textAlign: 'center' }}>
                  <b>Track your calories and macros</b>, exercise and hydration. Make it easy with our
                  barcode scanning and AI Tracking.
                </div>
              </div>
            </div>

            <div className="onboarding-wizard-upsell-row">
              <div
                className="onboarding-wizard-upsell-row-text"
                style={{ marginLeft: '4px', marginRight: '10px', marginTop: '10px' }}
              >
                <div className="onboarding-wizard-upsell-row-number">2</div>
                <div>
                  <b>Plan recipes </b> to cook & eat using our curated recipe book or by adding
                  web/your own recipes
                </div>
              </div>
              <div className="onboarding-wizard-upsell-row-inner">
                <img
                  style={{ marginTop: '10px', height: '80px' }}
                  className="onboarding-wizard-upsell-row-image"
                  key="2"
                  alt="Calendar"
                  src="group-18400.png"
                />
              </div>
            </div>

            <div className="onboarding-wizard-upsell-row" style={{ marginTop: '-3px' }}>
              <div className="onboarding-wizard-upsell-row-inner">
                <img
                  className="onboarding-wizard-upsell-row-image"
                  alt="takeAway"
                  key="3"
                  src={takeAwayOnboarding}
                />
              </div>
              <div className="onboarding-wizard-upsell-row-text">
                <div className="onboarding-wizard-upsell-row-number">3</div>
                <div>
                  <b>Order recipe ingredients </b> of recipes planned directly via Instacart, WholeFoods
                  or AmazonFresh.
                </div>
              </div>
            </div>


          </div>
        </div>
        <div style={navigationButtonsStyle}>
          <button
            onClick={handleBack}
            style={secondaryButtonStyle}
          >
            Back
          </button>
          <button
            onClick={() => updateOnboardingStatus(2)}
            style={primaryButtonStyle}
          >
            Next
          </button>
        </div>
      </div>
    );
  }

  if (onboardingStage === 2) {
    return (
      <div style={baseContainerStyle}>
        <div style={contentWrapperStyle}>
          <div className="onboarding-wizard-content" ref={contentRef2}>
            <div className="onboarding-wizard-title">
              Where do you want to get started?
            </div>

            <div
              className={`onboarding-wizard-button ${selectedTracking ? 'selected' : ''}`}
              onClick={() => setSelectedTracking(!selectedTracking)}
            >
              <div className="onboarding-wizard-button-image">
                <img
                  className="onboarding-wizard-option-image"
                  alt="Calorie Tracking"
                  src="group-18442.png"
                />
              </div>
              <div className="onboarding-wizard-button-text">
                <div className="onboarding-wizard-button-title">Calorie Tracking</div>
                <div className="onboarding-wizard-button-content">
                  Track the calories of foods via search, barcode scanning or with AI as well as your
                  exercise and hydration levels.
                </div>
              </div>
              {selectedTracking && (
                <CheckCircleIcon
                  className="onboarding-wizard-checkmark-icon"
                  style={{ color: '#2D7933' }}
                />
              )}
            </div>

            <div
              className={`onboarding-wizard-button ${selectedPlanning ? 'selected' : ''}`}
              onClick={() => setSelectedPlanning(!selectedPlanning)}
            >
              <div className="onboarding-wizard-button-image">
                <img
                  className="onboarding-wizard-option-image"
                  alt="Recipe Planning"
                  src="group-18400.png"
                />
              </div>
              <div className="onboarding-wizard-button-text">
                <div className="onboarding-wizard-button-title">Recipe Planning</div>
                <div className="onboarding-wizard-button-content">
                  Plan the recipes you want to cook throughout the week and order ingredients via
                  Instacart/Amazon.
                </div>
              </div>
              {selectedPlanning && (
                <CheckCircleIcon
                  className="onboarding-wizard-checkmark-icon"
                  style={{ color: '#2D7933' }}
                />
              )}
            </div>

            <div className="onboarding-wizard-helper-text">
              {selectedTracking && selectedPlanning && 'I am interested in both planning and tracking'}
              {!selectedTracking && selectedPlanning && 'I am interested only in recipe planning'}
              {selectedTracking && !selectedPlanning && 'I am interested only in calorie tracking'}
            </div>

          </div>

        </div>
        <div style={navigationButtonsStyle}>
          <button
            onClick={handleBack}
            style={secondaryButtonStyle}
          >
            Back
          </button>
          <button
            onClick={handleNextClick}
            disabled={!selectedTracking && !selectedPlanning}
            style={{
              ...primaryButtonStyle,
              opacity: (!selectedTracking && !selectedPlanning) ? 0.5 : 1,
            }}
          >
            Next
          </button>
        </div>
      </div>
    );
  }

  if (onboardingStage === 3) {
    return (
      <div style={baseContainerStyle}>
        <div style={contentWrapperStyle}>
          <div className="onboarding-wizard-content">
            <div className="onboarding-wizard-title">
              Tell us your <br />
              <b style={{ color: '#2D7933' }}>dietary preferences</b>
            </div>
            <div ref={dietaryPreferencesRef}>
              <DietaryPreferences />
            </div>

          </div>

        </div>
        <div style={navigationButtonsStyle}>
          <button
            onClick={handleBack}
            style={secondaryButtonStyle}
          >
            Back
          </button>
          <button
            onClick={handleNextClick}
            style={primaryButtonStyle}
          >
            Next
          </button>
        </div>
      </div>
    );
  }

  if (onboardingStage === 4) {
    return (

      <div className="onboarding-wizard-content" style={{ padding: '0px' }} >
        <CalorieCalculator
          handleExitClick={handleSkipClick}
          skip={handleSkipClick}
          showBackButton={true}
          onBack={handleBack}
          onComplete={handleNextClick}
        />
      </div>

    );
  }

  if (onboardingStage === 5) {
    return (
      <div style={baseContainerStyle}>
        <div style={contentWrapperStyle}>
          <div className="onboarding-wizard-content" style={{ width: 'calc(100vw - 40px)', maxWidth: '390px' }}>
            <div className="onboarding-wizard-title">
              Set up tracking <br />
              <b style={{ color: '#2D7933' }}>reminders</b>
            </div>
            <div style={{ fontSize: '12px', color: '#666', margin: '0 auto 20px auto', maxWidth: '280px', textAlign: 'center' }}>
              You can always pause this from your profile
            </div>

            <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
              <button
                style={{ marginRight: '10px' }}
                onClick={() => setWantsReminders(true)}
                className={`onboarding-wizard-notification-button ${wantsReminders ? 'selected' : ''}`}
              >
                Yes
              </button>
              <button
                style={{ marginLeft: '10px' }}
                onClick={() => setWantsReminders(false)}
                className={`onboarding-wizard-notification-button ${!wantsReminders ? 'selected' : ''}`}
              >
                No
              </button>
            </div>

            {wantsReminders && (
              <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                <label style={{ marginRight: '10px' }}>Select Reminder Time</label>
                <br />
                <input
                  type="time"
                  value={reminderTime}
                  style={{ 
                    marginTop: '15px', 
                    fontFamily: 'Montserrat',
                    fontSize: '16px',
                    padding: '10px 14px',
                    borderRadius: '8px',
                    border: '1px solid #ccc',
                    width: '140px',
                    appearance: 'none',
                    backgroundColor: '#fff',
                    color: '#333',
                    textAlign: 'center',
                    /* iOS specific styling */
                    '-webkit-appearance': 'none',
                    height: '44px'
                  }}
                  onChange={(e) => setReminderTime(e.target.value)}
                />
              </div>
            )}
          </div>
        </div>
        <div style={navigationButtonsStyle}>
          <button
            onClick={handleBack}
            style={secondaryButtonStyle}
          >
            Back
          </button>
          <button
            onClick={handleNotificationsConfirm}
            style={primaryButtonStyle}
          >
            Finish
          </button>
        </div>
      </div>
    );
  }

  return null;
};

export default OnboardingWizard;
