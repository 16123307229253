import './dashboard_page.css'
import React from "react";
import { AuthContext } from 'contexts/auth_context';
import { CartContext } from 'contexts/cart_context';
import MobileSettingsButton from 'components/shared/mobile_settings_button/mobile_settings_button';
import { Capacitor } from '@capacitor/core';
import { Dialog, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import LogWeight from 'components/shared/log_weight/log_weight';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import WeightOverTimeGraph from 'components/shared/graphs/weight_over_time_graph';
import BodyFatOverTimeGraph from 'components/shared/graphs/body_fat_over_time_graph';
import CaloriesOverTimeGraph from 'components/shared/graphs/calories_over_time_graph';
import CaloriesBurnedOverTimeGraph from 'components/shared/graphs/calories_burned_over_time_graph';
import ExercisePointsOverTimeGraph from 'components/shared/graphs/exercise_points_over_time_graph';
import HydrationOverTimeGraph from 'components/shared/graphs/hydration_over_time_graph';
import StepsOverTimeGraph from 'components/shared/graphs/steps_over_time_graph';

const DashboardPage = () => {
    const loadUsers = React.useContext(CartContext).loadUsers;
    const [logWeightDialogOpen, setLogWeightDialogOpen] = React.useState(false)
    const [selectedGraph, setSelectedGraph] = React.useState("Weight")

    function handleLogWeightClick() {
        setLogWeightDialogOpen(true)
    }

    const handleLogWeightExit = () => {
        setLogWeightDialogOpen(false)
    }

    const handleGraphChange = (event) => {
        setSelectedGraph(event.target.value);
    };


    return (<>
        <div className={window.innerWidth > 500 ? "" : "dashboard-page"} style={{ minHeight: "calc(100% - 45px)", display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', maxWidth: '800px', margin: 'auto auto' }} >
            {Capacitor.getPlatform() !== "web" && <MobileSettingsButton isPlanning={true} />}

            <div style={{ marginBottom: '23px', fontFamily: 'Montserrat-SemiBold', fontSize: '24px' }}>Progress Charts</div>
            <div className="dashboard-page-top" style={{ marginTop: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '15px', color: 'grey', width: 'calc(100% - 20px)', maxWidth:'320px' }}>

                <FormControl fullWidth>
                    <InputLabel>Select Chart</InputLabel>
                    <Select
                        value={selectedGraph}
                        onChange={handleGraphChange}
                        label="Select Chart"
                       

                    >
                        <MenuItem value={"Weight"}>Weight</MenuItem>
                        <MenuItem value={"Body Fat"}>Body Fat</MenuItem>
                        <MenuItem value={"Macros"}>Macros</MenuItem>
                        <MenuItem value={"Calories Burned"}>Exercise Calories</MenuItem>
                        <MenuItem value={"Exercise Points"}>Exercise Points</MenuItem>
                        <MenuItem value={"Hydration"}>Hydration</MenuItem>
                        <MenuItem value={"Steps"}>Steps</MenuItem>
                    </Select>
                </FormControl>

                <button className="log-weight-button" style={{minWidth:'133px', marginLeft:'20px'}} onClick={handleLogWeightClick}> <AssessmentOutlinedIcon style={{ marginRight: '5px', marginLeft: '-1px' }} /> Log Weight</button>
            </div>

            {selectedGraph === "Weight" && <WeightOverTimeGraph />}
            {selectedGraph === "Body Fat" && <BodyFatOverTimeGraph />}
            {selectedGraph === "Macros" && <CaloriesOverTimeGraph />}
            {selectedGraph === "Calories Burned" && <CaloriesBurnedOverTimeGraph />}
            {selectedGraph === "Exercise Points" && <ExercisePointsOverTimeGraph />}
            {selectedGraph === "Hydration" && <HydrationOverTimeGraph />}
            {selectedGraph === "Steps" && <StepsOverTimeGraph />}

        </div>
        <Dialog open={logWeightDialogOpen} maxWidth={'xl'} onClose={handleLogWeightExit} >
            <LogWeight calendarDate={new Date()} handleLogWeightExit={handleLogWeightExit}></LogWeight>
        </Dialog>
    </>)
}

export default DashboardPage;