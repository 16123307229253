import './calendar.css'
import React, { useEffect } from 'react';
import CalendarColumn from './calendar_column'
import { CartContext, getMealDateString } from 'contexts/cart_context';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { Tooltip } from '@material-ui/core';
import { useResizeDetector } from 'react-resize-detector';
import { UserContext } from 'contexts/user_context';
import { getDateMonthYear } from 'helpers/date_helpers';
import CalendarHeader from './calendar_header';


SwiperCore.use([Navigation]);


const Calendar = (props) => {
	const cartObject = React.useContext(CartContext).cartObject;
	const userObject = React.useContext(UserContext).userObject
	const setUserObjectNoSave = React.useContext(UserContext).setUserObjectNoSave
	const [oldSwiperPosition, setOldSwiperPosition] = React.useState(30)
	const [swiper, setSwiper] = React.useState(null);
	const [layoutVersion, setLayoutVersion] = React.useState(0);
	// Store the previous layout for comparison
	const prevLayoutRef = React.useRef('');

	React.useEffect(() => {
		// Only trigger re-render when the layout specifically changes
		if (userObject && userObject.calendarLayout) {
			const currentLayoutString = JSON.stringify(userObject.calendarLayout);
			
			// Compare with previous layout
			if (prevLayoutRef.current !== currentLayoutString) {
				// Update the version to trigger re-render
				setLayoutVersion(prev => prev + 1);
				// Store current layout for next comparison
				prevLayoutRef.current = currentLayoutString;
			}
		}
	}, [userObject]);

	function setUserCalendarPosition(position) {
		setUserObjectNoSave({ ...userObject, calendarPosition: position })
	}

	const onResize = React.useCallback(() => {
		if (swiper) {
			swiper.update()
		}

	}, [swiper]);

	const { width, ref } = useResizeDetector({ onResize });

	let columnsPerView = 1
	if (width !== undefined && width > 500) {
		columnsPerView = Math.floor((width - 48) / 400);
	}



	if (swiper && swiper.activeIndex !== oldSwiperPosition) {
		if (Math.abs(swiper.activeIndex - oldSwiperPosition) === 1) {
			setOldSwiperPosition(swiper.activeIndex)
		}
		else {
			try {
				swiper.slideTo(oldSwiperPosition)
				setUserCalendarPosition(oldSwiperPosition)
			}
			catch {
			}
		}
	}

	useEffect(() => {
		//this useEffect is needed cause swiper.slideTo does not work upon slider init, thus the waiting of 10 miliseconds.
		const timer = setTimeout(() => {
			if (swiper) {
				swiper.slideTo(30)
				setUserCalendarPosition(30)
			}
		}, 50);
		return () => clearTimeout(timer);
		// eslint-disable-next-line
	}, [swiper]);


	useEffect(() => {
		//this useEffect is needed for CopyMeals to move swiper}
		if (userObject.calendarPositionModified) {
			swiper.slideTo(userObject.calendarPosition)
			setOldSwiperPosition(userObject.calendarPosition)
			setUserObjectNoSave({ ...userObject, calendarPositionModified: false })
		}
		// eslint-disable-next-line
	}, [userObject]);

	let columns = []

	function renderColumn(date, isColumnVisible) {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const columnDateStr = `${year}-${month}-${day}`;
		
		// Filter meals that match this column's date
		const columnMeals = cartObject.filter(meal => {
			const mealDateStr = getMealDateString(meal);
			return mealDateStr === columnDateStr;
		});


		return (
			<SwiperSlide key={`slide-${columnDateStr}-${layoutVersion}`}>
				<CalendarColumn
					isCarouselDisabled={props.isCarouselDisabled}
					cartMeals={columnMeals}
					calendarDate={date}
					isColumnVisible={isColumnVisible}
				/>
			</SwiperSlide>
		);
	}

	if (userObject) {
		for (let x = 0; x < 60; x++) {
			let date = new Date(new Date().getTime());
			// console.log(swiper.activeIndex)
			let isColumnVisible = true
			if (swiper) {
				isColumnVisible = (x >= swiper.activeIndex && x < (swiper.activeIndex + columnsPerView)) ? true : false
			}
			date.setDate(date.getDate() + x - 30);
			columns.push(renderColumn(date, isColumnVisible));
		}
	}




	return (<>
		<CalendarHeader />

		<div ref={ref} className="inner-calendar-frame">

			{window.innerWidth > 595 ? <div className="swiper-custom-button-previous"> <Tooltip title="go to previous day"><NavigateBeforeIcon /></Tooltip></div> :
				<div style={{}}></div>}

			<Swiper
				onSwiper={setSwiper}
				id="main"
				spaceBetween={4}
				slidesPerView={columnsPerView === 0 ? 1 : columnsPerView}
				initialSlide={0}
				navigation={window.innerWidth > 595 && { prevEl: ".swiper-custom-button-previous", nextEl: ".swiper-custom-button-next" }}
				onSlideChange={() => setUserCalendarPosition(swiper.activeIndex)}
				allowTouchMove={false}
				key={`calendar-swiper-${layoutVersion}`}
			>  {columns}
			</Swiper>

			{window.innerWidth > 595 ? <div className="swiper-custom-button-next">   <Tooltip title="go to next day"><NavigateNextIcon /></Tooltip></div> :
				<div style={{}}></div>}

		</div>

	</>)
}
export default Calendar;
