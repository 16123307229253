import React from 'react';
import { Button, Checkbox, Dialog, Tooltip, MenuItem } from '@material-ui/core'
//  import FoodSearchRow from './branded_product_search_row';
import { config } from 'Constants';
import _ from 'lodash';
import { CartContext } from 'contexts/cart_context';

import MealPortioningSlider from '../meal_portioning_slider/meal_portioning_slider';

import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { v4 as uuidv4 } from 'uuid';
import MealDetailsCard from '../meal_details_card/meal_details_card';
import SwipeableTemporaryDrawer from '../calendar/SwipeableTemporaryDrawer';


const AITrackingRow = (props) => {
    const [foodId, setFoodId] = React.useState(null)
    const addFavoriteCustomLog = React.useContext(CartContext).addFavoriteCustomLog;
    const removeFoodFromFavorites = React.useContext(CartContext).removeFoodFromFavorites;
    let food = props.food
    let foodList = props.foodList
    let setFoodList = props.setFoodList
    let index = props.index
    const favoriteFoodList = React.useContext(CartContext).favoriteFoodList
    const [mealBeingViewed, setMealBeingViewed] = React.useState(false)
    const [isActionSheetOpen, setIsActionSheetOpen] = React.useState(false);
    const picture = `https://storage.googleapis.com/z-com-299512.appspot.com/App/${food.foodCategory}.png`

    // Get current measure and calculate nutrients
    const measures = food.measures || [];
    const currentMeasure = food.measures && food.measureIndex !== undefined 
        ? food.measures[food.measureIndex] 
        : null;
    
    // Helper function to get nutrient amount based on current measure and quantity
    const getNutrientAmount = (nutrientName) => {
        // Support both old and new data structures
        if (food.nutrientsPerGram && currentMeasure) {
            const nutrientPerGram = food.nutrientsPerGram[nutrientName.toLowerCase()];
            if (nutrientPerGram !== undefined) {
                return nutrientPerGram * currentMeasure.grams * (food.quantity || 1);
            }
        }
        
        // Fall back to old structure if new one isn't available
        if (food.nutrients) {
            return food.nutrients[nutrientName.toLowerCase()] * (food.quantity || 1);
        } else if (food.macros) {
            const macroKey = nutrientName.toLowerCase().replace(' ', '_');
            return food.macros[macroKey] * (food.portionChosen || 1);
        }
        
        return 0;
    };

    // Calculate total grams
    const gramsAmount = currentMeasure 
        ? Math.round(currentMeasure.grams * (food.quantity || 1))
        : (food.macros && food.macros.portionInGrams) 
            ? Math.round(food.macros.portionInGrams * (food.portionChosen || 1))
            : 0;

    // Function to set portion/quantity
    function setPortion(portion, index) {
        let foodListClone = foodList.concat([])
        
        // Support both old and new data structures
        if (food.measures) {
            foodListClone[index].quantity = portion;
        } else {
            foodListClone[index].portionChosen = portion;
        }
        
        setFoodList(foodListClone)
        
        if (mealBeingViewed) {
            for (let mealNutrient of mealBeingViewed.nutrition.nutrients) {
                if (mealNutrient.name === "Calories") {
                    mealNutrient['amount'] = Math.round(getNutrientAmount("calories"))
                }
                if (mealNutrient.name === "Protein") {
                    mealNutrient['amount'] = Math.round(getNutrientAmount("protein"))
                }
                if (mealNutrient.name === "Carbohydrates") {
                    mealNutrient['amount'] = Math.round(getNutrientAmount("carbohydrates"))
                }
                if (mealNutrient.name === "Fat") {
                    mealNutrient['amount'] = Math.round(getNutrientAmount("fat"))
                }
                if (mealNutrient.name === "Cholesterol") {
                    mealNutrient['amount'] = Math.round(getNutrientAmount("cholesterol"))
                }
                if (mealNutrient.name === "Sugar") {
                    mealNutrient['amount'] = Math.round(getNutrientAmount("sugars"))
                }
                if (mealNutrient.name === "Sodium") {
                    mealNutrient['amount'] = Math.round(getNutrientAmount("sodium"))
                }
                if (mealNutrient.name === "Calcium") {
                    mealNutrient['amount'] = Math.round(getNutrientAmount("calcium"))
                }
                if (mealNutrient.name === "Saturated Fat") {
                    mealNutrient['amount'] = Math.round(getNutrientAmount("saturated_fat"))
                }
                if (mealNutrient.name === "Fiber") {
                    mealNutrient['amount'] = Math.round(getNutrientAmount("fiber"))
                }
            }
            setMealBeingViewed({ ...mealBeingViewed })
        }
    }

    function handleActionSheetChange(index) {
        let foodListClone = foodList.concat([]);
        foodListClone[props.index].measureIndex = index;
        setFoodList(foodListClone);
    }

    function toggleChecked() {
        let foodListClone = foodList.concat([])
        foodListClone[index].checked = !foodListClone[index].checked
        setFoodList(foodListClone)
    }

    function handleMealViewClick() {
        let mealObject = {
            name: food.name,
            type: 'meal',
            portion: food.quantity || food.portionChosen || 1,
            nutrition: {
                nutrients: [
                    {
                        name: "Calories",
                        amount: Math.round(getNutrientAmount("calories")),
                        unit: "kcal"
                    },
                    {
                        name: "Protein",
                        amount: Math.round(getNutrientAmount("protein")),
                        unit: "g"
                    },
                    {
                        name: "Carbohydrates",
                        amount: Math.round(getNutrientAmount("carbohydrates")),
                        unit: "g"
                    },
                    {
                        name: "Fat",
                        amount: Math.round(getNutrientAmount("fat")),
                        unit: "g"
                    },
                    {
                        name: "Cholesterol",
                        amount: Math.round(getNutrientAmount("cholesterol")),
                        unit: "mg"
                    },
                    {
                        name: "Sugar",
                        amount: Math.round(getNutrientAmount("sugars")),
                        unit: "g"
                    },
                    {
                        name: "Sodium",
                        amount: Math.round(getNutrientAmount("sodium")),
                        unit: "mg"
                    },
                    {
                        name: "Calcium",
                        amount: Math.round(getNutrientAmount("calcium")),
                        unit: "mg"
                    },
                    {
                        name: "Saturated Fat",
                        amount: Math.round(getNutrientAmount("saturated_fat")),
                        unit: "g"
                    },
                    {
                        name: "Fiber",
                        amount: Math.round(getNutrientAmount("fiber")),
                        unit: "g"
                    }
                ]
            },
            picture: picture
        }
        setMealBeingViewed(mealObject)
    }

    function handleMealViewExitClick() {
        setMealBeingViewed(false)
    }

    function addToFavorites() {
        let mealObject = {
            name: food.name,
            type: 'meal',
            portion: food.quantity || food.portionChosen || 1,
            nutrition: {
                nutrients: [
                    {
                        name: "Calories",
                        amount: Math.round(getNutrientAmount("calories")),
                        unit: "kcal"
                    },
                    {
                        name: "Protein",
                        amount: Math.round(getNutrientAmount("protein")),
                        unit: "g"
                    },
                    {
                        name: "Carbohydrates",
                        amount: Math.round(getNutrientAmount("carbohydrates")),
                        unit: "g"
                    },
                    {
                        name: "Fat",
                        amount: Math.round(getNutrientAmount("fat")),
                        unit: "g"
                    },
                    {
                        name: "Cholesterol",
                        amount: Math.round(getNutrientAmount("cholesterol")),
                        unit: "mg"
                    },
                    {
                        name: "Sugar",
                        amount: Math.round(getNutrientAmount("sugars")),
                        unit: "g"
                    },
                    {
                        name: "Sodium",
                        amount: Math.round(getNutrientAmount("sodium")),
                        unit: "mg"
                    },
                    {
                        name: "Calcium",
                        amount: Math.round(getNutrientAmount("calcium")),
                        unit: "mg"
                    },
                    {
                        name: "Saturated Fat",
                        amount: Math.round(getNutrientAmount("saturated_fat")),
                        unit: "g"
                    },
                    {
                        name: "Fiber",
                        amount: Math.round(getNutrientAmount("fiber")),
                        unit: "g"
                    }
                ]
            },
            picture: picture
        }
        addFavoriteCustomLog(mealObject)
    }

    let foodInFavorites = false
    for (let favoriteFood of favoriteFoodList) {
        if (favoriteFood.name === food.name) {
            foodInFavorites = favoriteFood
        }
    }

    function removeFromFavorites() {
        removeFoodFromFavorites(foodInFavorites)
    }

    // Function to format measure description based on quantity
    const getFormattedMeasureDescription = () => {
        if (!currentMeasure) return "";
        
        const quantity = food.quantity || 1;
        const description = currentMeasure.description;
        
        // If quantity is 1, just return the original description
        if (quantity === 1) return description;
        
        // Handle descriptions that start with a number like "3 oz (cooked)"
        if (description.match(/^(\d+)\s/)) {
            const match = description.match(/^(\d+)\s/);
            if (match) {
                const originalAmount = parseInt(match[1]);
                const newAmount = originalAmount * quantity;
                
                // Replace the leading number with the new calculated amount
                return description.replace(/^\d+\s/, `${newAmount} `);
            }
        }
        
        // Handle descriptions that start with a fraction like "1/2 chicken thigh"
        if (description.match(/^(\d+)\/(\d+)\s/)) {
            const fractionMatch = description.match(/^(\d+)\/(\d+)\s/);
            if (fractionMatch) {
                const numerator = parseInt(fractionMatch[1]);
                const denominator = parseInt(fractionMatch[2]);
                
                // Convert fraction to decimal and multiply by quantity
                const decimal = (numerator / denominator) * quantity;
                
                // If result is a whole number, display it as such
                if (decimal % 1 === 0) {
                    return description.replace(/^\d+\/\d+\s/, `${decimal} `)
                        .replace(/\b(apple|banana|egg|slice|piece|serving|cup|tbsp|tsp|oz|burger|roll|scoop|thigh|breast|leg|wing)\b/i, match => {
                            return decimal > 1 ? match + 's' : match;
                        });
                }
                
                // Try to simplify to a common fraction if possible
                if (decimal <= 1) {
                    // For simple fractions less than 1
                    const nearestFraction = findNearestFraction(decimal);
                    return description.replace(/^\d+\/\d+\s/, `${nearestFraction} `);
                }
                
                // For mixed numbers (> 1)
                const wholePart = Math.floor(decimal);
                const fractionPart = decimal - wholePart;
                
                if (fractionPart > 0) {
                    const nearestFraction = findNearestFraction(fractionPart);
                    const result = `${wholePart} ${nearestFraction}`;
                    return description.replace(/^\d+\/\d+\s/, `${result} `)
                        .replace(/\b(apple|banana|egg|slice|piece|serving|cup|tbsp|tsp|oz|burger|roll|scoop|thigh|breast|leg|wing)\b/i, match => {
                            return decimal > 1 ? match + 's' : match;
                        });
                } else {
                    return description.replace(/^\d+\/\d+\s/, `${wholePart} `)
                        .replace(/\b(apple|banana|egg|slice|piece|serving|cup|tbsp|tsp|oz|burger|roll|scoop|thigh|breast|leg|wing)\b/i, match => {
                            return decimal > 1 ? match + 's' : match;
                        });
                }
            }
        }
        
        // Handle pluralization for common measure descriptions that start with "1 "
        if (description.match(/^1 /)) {
            // Replace the leading "1 " with the quantity
            return description.replace(/^1 /, `${quantity} `)
                // Handle pluralization of common food words
                .replace(/\b(apple|banana|egg|slice|piece|serving|cup|tbsp|tsp|oz|burger|roll|scoop|thigh|breast|leg|wing)\b/i, match => {
                    return quantity > 1 ? match + 's' : match;
                });
        }
        
        // For descriptions that don't match any of the above patterns, use the × format
        return `${quantity} × ${description}`;
    };

    // Helper function to find the nearest common fraction
    const findNearestFraction = (decimal) => {
        // Common fractions and their decimal values
        const commonFractions = [
            { fraction: "1/4", value: 0.25 },
            { fraction: "1/3", value: 1/3 },
            { fraction: "1/2", value: 0.5 },
            { fraction: "2/3", value: 2/3 },
            { fraction: "3/4", value: 0.75 }
        ];
        
        // Find the closest common fraction
        let closest = commonFractions[0];
        let minDiff = Math.abs(decimal - closest.value);
        
        for (let i = 1; i < commonFractions.length; i++) {
            const diff = Math.abs(decimal - commonFractions[i].value);
            if (diff < minDiff) {
                minDiff = diff;
                closest = commonFractions[i];
            }
        }
        
        // If the decimal is very close to a common fraction, return that fraction
        if (minDiff < 0.05) {
            return closest.fraction;
        }
        
        // Otherwise, format as a decimal with 1 decimal place
        return decimal.toFixed(1);
    };

    return (<>
        <div style={{ borderBottom: '1px solid #e1e1e1' }}>
            <div key={`calendar-add-menu-meal-row-${food.food_id}`} className="calendar-add-menu-meal-row" style={{ marginBottom: '10px', maxWidth: '100%' }}>

                <Checkbox className="ai-tracking-form-checkbox" style={{ paddingRight: '0px', paddingLeft:'3px' }} checked={food.checked} onChange={toggleChecked}></Checkbox>
                <img className="food-search-meal-picture" src={picture} alt={food.food_name} onClick={handleMealViewClick} />

                <div className="calendar-add-menu-meal-column" style={{ overflow: 'hidden' }} >
                    <div style={{ display: 'flex', maxWidth: '100%' }}>
                        <div className="calendar-add-menu-meal-name" style={{ margin: '7px 10px 7px 0px' }}>{food.name}
                        </div>
                    </div>
                    <div className="food-search-box-inner-row">

                        <div >

                            <div className="ai-tracking-add-custom-meal-caloric-info" style={{ marginBottom: '7px' }}>
                                <span className="caloric-chip caloric-chip-calories" style={{ fontSize: '10px' }}> 
                                    {Math.round(getNutrientAmount("calories"))} Cals
                                </span>
                                <span className="caloric-chip caloric-chip-protein">
                                    {Math.round(getNutrientAmount("protein"))}g
                                </span>
                                <span className="caloric-chip caloric-chip-carbs">
                                    {Math.round(getNutrientAmount("carbohydrates"))}g
                                </span>
                                <span className="caloric-chip caloric-chip-fats">
                                    {Math.round(getNutrientAmount("fat"))}g
                                </span>
                                <span style={{ marginLeft: '6px', fontSize: '12px', fontFamily: 'Montserrat-Medium', color: '#b4b4b4' }}> 
                                    {gramsAmount}g
                                </span>
                            </div>

                            {/* Add measure selector if measures are available */}
                            {measures && measures.length > 1 && (
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop:'12px' }}>
                                    <div style={{ fontSize: '13px', marginRight: '10px' }}>Measure:</div>
                                    <div 
                                 
                                        onClick={() => setIsActionSheetOpen(true)}
                                        style={{ display: 'flex', alignItems: 'center', fontSize: '13px'}}
                                    >
                                        {currentMeasure ? getFormattedMeasureDescription() : "Select measure"}
                                        <ArrowDropDownIcon style={{ color: 'grey' }} />
                                    </div>
                                </div>
                            )}

                            <div style={{ display: 'flex', alignItems: 'center', fontSize: '13px' }}>
                                Servings: <span style={{ marginLeft: '10px', marginBottom: '-6px', width: '400px' }}>
                                    <MealPortioningSlider 
                                        portion={food.quantity || food.portionChosen || 1} 
                                        setPortion={setPortion} 
                                        index={index} 
                                        shrink={true} 
                                        smallPopup={true} 
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="calendar-add-menu-meal-column" style={{ maxWidth: '100px' }}>
                    {foodInFavorites ? <Tooltip title="Remove from favorites">
                        <button style={{ color: 'rgb(255 91 149)', background: 'none', border: 'none' }} value={index} onClick={removeFromFavorites}><FavoriteIcon /></button>
                    </Tooltip> :
                        <Tooltip title="Add to favorites">
                            <button style={{ color: 'grey', background: 'none', border: 'none' }} value={index} onClick={addToFavorites}><FavoriteBorderIcon /></button>
                        </Tooltip>
                    }
                </div>
            </div>
        </div>

        <Dialog open={!!mealBeingViewed} maxWidth={'xl'} >
            <MealDetailsCard 
                setPortion={setPortion} 
                index={index} 
                portion={mealBeingViewed ? mealBeingViewed.portion : null} 
                handleExitClick={handleMealViewExitClick} 
                meal={mealBeingViewed} 
                disableCopy={true} 
                addToCalendarEnabled={false} 
            />
        </Dialog>

        {/* Add SwipeableTemporaryDrawer for measure selection on mobile */}
        {measures && measures.length > 0 && (
            <SwipeableTemporaryDrawer
                actionSheetOpen={isActionSheetOpen}
                setActionSheetOpen={setIsActionSheetOpen}
                list={measures.map(measure => measure.description)}
                onChange={handleActionSheetChange}
            />
        )}
    </>)
}

export default AITrackingRow