import './blog.css'
import React from "react";
import { AuthContext } from 'contexts/auth_context';
import { CartContext } from 'contexts/cart_context';


const Blog = () => {




    React.useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.bloghandy.com/api/bh_blogengine.js";
        script.async = true;
        // script.onload = () => this.scriptLoaded();

        document.body.appendChild(script);

    }, [])

    return (<>
        <div className="blog-page" id="bh-posts"></div>

    </>)

}

export default Blog;