import './log_weight.css'
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';

import { InputAdornment, TextField, MenuItem, Select, FormControl, InputLabel, Button } from '@material-ui/core';

import Calendar from 'react-calendar'
import { getDateMonthYear, getDateString } from 'helpers/date_helpers';
import { UserContext } from 'contexts/user_context';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';

const LogWeight = (props) => {
    const history = useHistory();
    const isMobile = Capacitor.getPlatform() !== 'web';
    const [dateSelected, setDateSelected] = React.useState(props.calendarDate)
    const userObject = React.useContext(UserContext).userObject
    const [weight, setWeight] = React.useState('')
    const [weightUnit, setWeightUnit] = React.useState('lbs')
    const [bodyFat, setBodyFat] = React.useState('')
    let setUserObject = React.useContext(UserContext).setUserObject

    const minDate = new Date();
    minDate.setDate(new Date().getDate() - 365)
    const maxDate = new Date();
    maxDate.setDate(new Date().getDate())

    const handleDayChange = (date) => {
        setDateSelected(date)
    }

    const handleWeightChange = (e) => {
        setWeight(e.target.value)
    }

    const handleWeightUnitChange = (e) => {
        setWeightUnit(e.target.value)
    }

    const handleBodyFatChange = (e) => {
        setBodyFat(e.target.value)
    }

    const handleSubmitClick = () => {
        let weightObject = userObject.weight ? userObject.weight : {}
        let bodyFatObject = userObject.bodyFat ? userObject.bodyFat : {}
        let dateMonthYear = getDateMonthYear(dateSelected)

        if (weight) {
            weightObject[dateMonthYear] = { unit: weightUnit, value: parseFloat(weight) }
        }
        if (bodyFat) {
            bodyFatObject[dateMonthYear] = parseFloat(bodyFat)
        }
        setUserObject({ ...userObject, weight: weightObject, bodyFat: bodyFatObject })
        FirebaseAnalytics.logEvent({
            name: "logged_weight",
            params: {}
        })
        props.handleLogWeightExit()
    }

    const handleNavigateToAccount = () => {
        props.handleLogWeightExit();
        history.push('/account');
    };

    // Pre-fill with last entered values for the selected date
    React.useEffect(() => {
        const dateKey = getDateMonthYear(dateSelected);
        
        // Pre-fill weight if exists for selected date
        if (userObject.weight?.[dateKey]) {
            const weightData = userObject.weight[dateKey];
            setWeight(weightData.value.toString());
            setWeightUnit(weightData.unit);
        } else {
            setWeight('');
        }
        
        // Pre-fill body fat if exists for selected date
        if (userObject.bodyFat?.[dateKey]) {
            const bodyFatData = userObject.bodyFat[dateKey];
            setBodyFat(typeof bodyFatData === 'object' ? bodyFatData.value.toString() : bodyFatData.toString());
        } else {
            setBodyFat('');
        }
    }, [dateSelected]);

    return (
        <>
            <div style={{ margin: '20px 30px', textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                
                {isMobile && (
                    <div style={{ 
                        width: '100%', 
                        maxWidth: '350px', 
                        marginBottom: '0px' 
                    }}>
                        {userObject.healthSync?.weight ? (
                            <div style={{ 
                                backgroundColor: 'rgba(46, 110, 77, 0.1)',
                                padding: '12px',
                                borderRadius: '8px',
                                marginBottom: '16px'
                            }}>
                                <div style={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    marginBottom: '8px',
                                    color: '#2E6E4D',
                                    justifyContent: 'center'
                                }}>
                                    <PhoneIphoneIcon style={{ marginRight: '8px', fontSize: '20px' }} />
                                    <span style={{ fontSize: '13px' }}>Health App Sync is enabled</span>
                                </div>
                                <div style={{ 
                                    fontSize: '13px', 
                                    color: '#2E6E4D'
                                }}>
                                    Manual entries will take priority over synced data
                                </div>
                            </div>
                        ) : (
                            <div style={{ 
                                padding: '12px',
                                borderRadius: '8px',
                                marginBottom: '16px',
                                textAlign: 'center'
                            }}>
                                <div style={{ 
                                    marginBottom: '8px',
                                    color: '#666'
                                }}>
                                    <span style={{ fontSize: '13px' }}>
                                        Want to sync weight automatically?
                                    </span>
                                </div>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleNavigateToAccount}
                                    style={{ 
                                        fontSize: '13px',
                                        textTransform: 'none',
                                        color: '#2E6E4D',
                                        borderColor: '#2E6E4D'
                                    }}
                                >
                                    Enable Health App Sync
                                </Button>
                            </div>
                        )}
                    </div>
                )}

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px', marginTop: '8px' }}>
                    <TextField InputLabelProps={{ shrink: true }} style={{ maxWidth: '170px' }} className="log-weight-input" 
                        InputProps={{ endAdornment: <InputAdornment position="end">
                            <FormControl>
                                <Select
                                    name="weightUnit"
                                    value={weightUnit}
                                    onChange={handleWeightUnitChange}
                                    disableUnderline
                                >
                                    <MenuItem value="lbs">lbs</MenuItem>
                                    <MenuItem value="kg">kg</MenuItem>
                                </Select>
                            </FormControl>
                        </InputAdornment> }} 
                        required={true} label="weight" type="number" variant="outlined" placeholder="e.g. 180.0" value={weight} onChange={handleWeightChange}></TextField>
                    <TextField InputLabelProps={{ shrink: true }} style={{ maxWidth: '170px' }} className="log-weight-input" InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }} label="body fat" type="number" variant="outlined" placeholder="e.g. 20.0" value={bodyFat} onChange={handleBodyFatChange}></TextField>
                </div>

                <Calendar style={{ marginLeft: 'auto', marginRight: 'auto' }} value={dateSelected} minDate={minDate} maxDate={maxDate} onChange={handleDayChange} />
                <div style={{ margin: '10px', fontFamily: 'Montserrat-Medium', fontSize: '14px' }}>Weighed on {getDateString(dateSelected)}</div>

                <Button className={isNaN(parseFloat(weight)) ? "modify-recipe-cancel-button" : "copy-meals-to-day-button"} disabled={isNaN(parseFloat(weight))} onClick={handleSubmitClick}> Log Weight </Button>
            </div>
        </>
    )
}
export default LogWeight;
