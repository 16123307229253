import './ai_tracking_form.css'
import React from "react";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { Alert } from '@material-ui/lab';
import LoopIcon from '@material-ui/icons/Loop';
import { AuthContext } from 'contexts/auth_context';
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { CartContext } from 'contexts/cart_context';
import AITrackingRow from './ai_tracking_row';
import { config } from 'Constants';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import ImageIcon from '@material-ui/icons/Image';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import { getAuth } from "firebase/auth";
import SwipeableTemporaryDrawer from '../calendar/SwipeableTemporaryDrawer';
import { Capacitor } from '@capacitor/core';

const AITrackingForm = (props) => {
    const addMealsFromCopy = React.useContext(CartContext).addMealsFromCopy;
    const [loading, setLoading] = React.useState(false);
    const [errorState, setErrorState] = React.useState(0);
    const [prompt, setPrompt] = React.useState('');
    const { currentUser } = React.useContext(AuthContext);
    const [foods, setFoods] = React.useState([]);
    const favoriteFoodList = React.useContext(CartContext).favoriteFoodList;
    const [buttonDisabled, setButtonDisabled] = React.useState(false);
    const fileInputRef = React.useRef();
    const [selectedImage, setSelectedImage] = React.useState(null);
    
    // Speech recognition states
    const [isListening, setIsListening] = React.useState(false);
    const [speechRecognition, setSpeechRecognition] = React.useState(null);
    const [speechSupported, setSpeechSupported] = React.useState(false);
    const [micPermissionError, setMicPermissionError] = React.useState(false);
    const [platform, setPlatform] = React.useState('web');

    // Initialize speech recognition on component mount
    React.useEffect(() => {
        // Detect platform
        if (Capacitor.isNativePlatform()) {
            if (Capacitor.getPlatform() === 'ios') {
                setPlatform('ios');
            } else if (Capacitor.getPlatform() === 'android') {
                setPlatform('android');
            }
        } else {
            setPlatform('web');
        }

        // Check if SpeechRecognition is available
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        
        if (SpeechRecognition) {
            const recognition = new SpeechRecognition();
            
            // Configure based on platform
            if (platform === 'ios') {
                // iOS works better with continuous mode off and interim results off
                recognition.continuous = false;
                recognition.interimResults = false;
            } else if (platform === 'android') {
                // Android can handle continuous mode and interim results
                recognition.continuous = false;
                recognition.interimResults = true;
            } else {
                // Web browser
                recognition.continuous = false;
                recognition.interimResults = false;
            }
            
            recognition.lang = 'en-US';
            
            recognition.onresult = (event) => {
                // Get the most recent result
                const lastResultIndex = event.results.length - 1;
                const transcript = event.results[lastResultIndex][0].transcript;
                
                // Only update if it's a final result or we're not using interim results
                if (event.results[lastResultIndex].isFinal || !recognition.interimResults) {
                    setPrompt(prev => {
                        // If we already have text, add a space before the new text
                        const newText = prev ? `${prev} ${transcript}` : transcript;
                        return newText;
                    });
                    setButtonDisabled(false);
                    
                    // If we're in continuous mode, don't stop listening
                    if (!recognition.continuous) {
                        setIsListening(false);
                    }
                }
            };
            
            recognition.onerror = (event) => {
                console.error('Speech recognition error', event.error);
                if (event.error === 'not-allowed' || event.error === 'permission-denied') {
                    setMicPermissionError(true);
                }
                setIsListening(false);
            };
            
            recognition.onend = () => {
                setIsListening(false);
            };
            
            setSpeechRecognition(recognition);
            setSpeechSupported(true);
        } else {
            console.log('Speech recognition not supported');
            setSpeechSupported(false);
        }
        
        return () => {
            if (speechRecognition) {
                speechRecognition.abort();
            }
        };
    }, [platform]);

    const requestMicrophonePermission = async () => {
        try {
            // For native platforms, we need a different approach
            if (Capacitor.isNativePlatform()) {
                // On iOS, we don't need to explicitly request permission
                // The browser will automatically request it when we start speech recognition
                if (platform === 'ios') {
                    return true;
                }
                
                // For Android, we can use the browser's permission API
                if (platform === 'android' && navigator.permissions) {
                    try {
                        const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
                        if (permissionStatus.state === 'granted') {
                            return true;
                        } else if (permissionStatus.state === 'prompt') {
                            // Will show permission dialog
                            return true;
                        } else {
                            // Permission denied
                            setMicPermissionError(true);
                            return false;
                        }
                    } catch (error) {
                        console.error('Error checking microphone permission:', error);
                        // If we can't check permission, just try to use it
                        return true;
                    }
                }
            } else {
                // For web browsers
                if (navigator.permissions) {
                    try {
                        const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
                        if (permissionStatus.state === 'granted') {
                            return true;
                        } else if (permissionStatus.state === 'prompt') {
                            // Will show permission dialog
                            return true;
                        } else {
                            // Permission denied
                            setMicPermissionError(true);
                            return false;
                        }
                    } catch (error) {
                        console.error('Error checking microphone permission:', error);
                    }
                }
            }
            
            // If we can't check permission, just try to use it
            return true;
        } catch (error) {
            console.error('Error requesting microphone permission:', error);
            return true; // Try anyway
        }
    };

    const toggleListening = async () => {
        if (isListening) {
            speechRecognition.stop();
            setIsListening(false);
        } else {
            // Reset permission error state
            setMicPermissionError(false);
            
            // Request permission first
            const permissionGranted = await requestMicrophonePermission();
            
            if (permissionGranted) {
                try {
                    // On iOS, we need to stop any existing recognition first
                    if (platform === 'ios' && speechRecognition) {
                        try {
                            speechRecognition.stop();
                        } catch (e) {
                            // Ignore errors when stopping
                        }
                        
                        // Small delay to ensure previous session is fully stopped
                        await new Promise(resolve => setTimeout(resolve, 200));
                    }
                    
                    speechRecognition.start();
                    setIsListening(true);
                    
                    // Log analytics event
                    FirebaseAnalytics.logEvent({
                        name: "used_voice_input",
                        params: { 
                            platform: platform,
                            currentUser: currentUser ? currentUser.uid : "null" 
                        }
                    });
                } catch (error) {
                    console.error('Speech recognition error:', error);
                    setIsListening(false);
                    
                    // Check if the error is related to permissions
                    if (error.message && (
                        error.message.includes('permission') || 
                        error.message.includes('not-allowed') ||
                        error.message.includes('denied')
                    )) {
                        setMicPermissionError(true);
                    }
                    
                    // Handle specific iOS error
                    if (platform === 'ios' && error.message && error.message.includes('already running')) {
                        // If recognition is already running, try to stop and restart
                        try {
                            speechRecognition.stop();
                            // Wait a moment and try again
                            setTimeout(() => {
                                try {
                                    speechRecognition.start();
                                    setIsListening(true);
                                } catch (e) {
                                    console.error('Failed to restart speech recognition', e);
                                }
                            }, 300);
                        } catch (e) {
                            console.error('Failed to stop existing speech recognition', e);
                        }
                    }
                }
            }
        }
    };

    const handleChange = (e) => {
        setPrompt(e.target.value);
        setButtonDisabled(false);
    }

    const handleImageSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            setButtonDisabled(false);
        }
    };

    const removeImage = () => {
        setSelectedImage(null);
        fileInputRef.current.value = '';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!prompt && !selectedImage) return;

        setLoading(true);
        setErrorState(0);
        setFoods([]);

        try {
            FirebaseAnalytics.logEvent({
                name: "started_tracked_with_ai",
                params: { 
                    AIprompt: prompt, 
                    hasImage: !!selectedImage,
                    currentUser: currentUser ? currentUser.uid : "null" 
                }
            });

            // Get the Firebase auth token
            const auth = getAuth();
            const idToken = await auth.currentUser.getIdToken(true);

            let response;
            if (selectedImage) {
                const formData = new FormData();
                formData.append('image', selectedImage);
                if (prompt) {
                    formData.append('prompt', prompt);
                }
                response = await axios.post('food-search/parseWithAIImage/v2', formData, {
                    headers: {
                        'Authorization': `Bearer ${idToken}`
                    }
                });
            } else {
                response = await axios.get('food-search/parseWithAI/v2', { 
                    params: { prompt },
                    headers: {
                        'Authorization': `Bearer ${idToken}`
                    }
                });
            }

            setFoods(response.data);
            setButtonDisabled(true);

        } catch (error) {
            setErrorState(1);
            setFoods([]);
            FirebaseAnalytics.logEvent({
                name: "ai_tracking_error",
                params: { 
                    AIprompt: prompt,
                    hasImage: !!selectedImage,
                    currentUser: currentUser ? currentUser.uid : "null" 
                }
            });
        } finally {
            setLoading(false);
        }
    }

    function handleAddToCalendar() {
        let meals = []
        for (let food of foods) {
            if (food.checked) {
                let mealObject = _.cloneDeep(config.emptyRecipe)
                mealObject = props.calendarCategory ? { ...mealObject, calendarCategory: props.calendarCategory } : { ...mealObject, calendarCategory: "breakfast" }
                
                // Get current measure and quantity
                const currentMeasure = food.measures && food.measureIndex !== undefined 
                    ? food.measures[food.measureIndex] 
                    : null;
                const quantity = food.quantity || food.portionChosen || 1;
                
                mealObject = { 
                    ...mealObject, 
                    portion: quantity, 
                    name: food.name, 
                    description: currentMeasure 
                        ? `A serving is ${currentMeasure.description}` 
                        : food.macros?.portionInGrams 
                            ? `A serving is ${food.macros.portionInGrams}g` 
                            : "Made by AI",
                    areNutrientsManual: true,
                    picture: `https://storage.googleapis.com/z-com-299512.appspot.com/App/${food.foodCategory}.png`
                }

                // Helper function to get nutrient amount
                const getNutrientAmount = (nutrientName) => {
                    // Support both old and new data structures
                    if (food.nutrientsPerGram && currentMeasure) {
                        const nutrientPerGram = food.nutrientsPerGram[nutrientName.toLowerCase()];
                        if (nutrientPerGram !== undefined) {
                            return nutrientPerGram * currentMeasure.grams * quantity;
                        }
                    }
                    
                    // Fall back to old structure if new one isn't available
                    if (food.macros) {
                        const macroKey = nutrientName.toLowerCase().replace(' ', '_');
                        return food.macros[macroKey] * quantity;
                    }
                    
                    return 0;
                };

                for (let mealNutrient of mealObject.nutrition.nutrients) {
                    if (mealNutrient.name === "Calories") {
                        mealNutrient['amount'] = Math.round(getNutrientAmount("calories"))
                    }
                    if (mealNutrient.name === "Protein") {
                        mealNutrient['amount'] = Math.round(getNutrientAmount("protein"))
                    }
                    if (mealNutrient.name === "Carbohydrates") {
                        mealNutrient['amount'] = Math.round(getNutrientAmount("carbohydrates"))
                    }
                    if (mealNutrient.name === "Fat") {
                        mealNutrient['amount'] = Math.round(getNutrientAmount("fat"))
                    }
                    if (mealNutrient.name === "Cholesterol") {
                        mealNutrient['amount'] = Math.round(getNutrientAmount("cholesterol"))
                    }
                    if (mealNutrient.name === "Sugar") {
                        mealNutrient['amount'] = Math.round(getNutrientAmount("sugars"))
                    }
                    if (mealNutrient.name === "Sodium") {
                        mealNutrient['amount'] = Math.round(getNutrientAmount("sodium"))
                    }
                    if (mealNutrient.name === "Calcium") {
                        mealNutrient['amount'] = Math.round(getNutrientAmount("calcium"))
                    }
                    if (mealNutrient.name === "Saturated Fat") {
                        mealNutrient['amount'] = Math.round(getNutrientAmount("saturated_fat"))
                    }
                    if (mealNutrient.name === "Fiber") {
                        mealNutrient['amount'] = Math.round(getNutrientAmount("fiber"))
                    }
                }
                meals.push(mealObject)
            }
        }
        FirebaseAnalytics.logEvent({
            name: "ai_tracking_added_to_calendar",
            params: { AIprompt: prompt, currentUser: currentUser ? currentUser.uid : "null" }
        })

        addMealsFromCopy(meals, props.calendarDate, props.calendarCategory)
        props.handleExitClick()
    }

    let rows = []
    let allNotChecked = true
    for (let index in foods) {
        let food = foods[index]
        if (food.checked) {
            allNotChecked = false
        }
        let foodInFavorites = false;
        for (let favoriteFood of favoriteFoodList) {
            if (favoriteFood.food_id === food.food_id) {
                foodInFavorites = true
                break
            }
        }
        rows.push(<AITrackingRow food={food} foodList={foods} setFoodList={setFoods} index={index} foodInFavorites={foodInFavorites}
            handleExitClick={props.handleExitClick} calendarCategory={props.calendarCategory} calendarDate={props.calendarDate} />)
    }


    return (

        <>
            <div className="custom-meal-form-top-message">
                Describe your meal and/or upload a photo to estimate macros
            </div>

            <div >
                <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>



                    <div className="extract-recipe-dialog-form-line-item" >
                        <TextField className="ai-tracking-form-input" style={{ width: '90%' }} variant="outlined" disabled={loading} multiline required={!selectedImage} name='URL' InputLabelProps={{ shrink: true }} value={prompt} onChange={handleChange} placeholder="e.g. one large grilled chicken thigh with half a cup of white rice and small side of bbq pinto beans"></TextField>

                        <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            onChange={handleImageSelect}
                        />
                        
                        <button
                            type="button"
                            className="order-ingredients-button-for-browser"
                            style={{ 
                                padding: '10px', 
                                marginLeft: '10px',
                                minWidth: 'unset',
                                backgroundColor: selectedImage ? '#4CAF50' : undefined
                            }}
                            onClick={() => fileInputRef.current.click()}
                        >
                            <ImageIcon />
                        </button>
                        
                        {speechSupported && (
                            <button
                                type="button"
                                className="order-ingredients-button-for-browser"
                                style={{ 
                                    padding: '10px', 
                                    marginLeft: '10px',
                                    minWidth: 'unset',
                                    backgroundColor: isListening ? '#FF5722' : micPermissionError ? '#f44336' : undefined,
                                    animation: isListening ? 'pulse 1.5s infinite' : 'none'
                                }}
                                onClick={toggleListening}
                                disabled={loading}
                                title={micPermissionError ? "Microphone permission denied" : "Speak to enter food"}
                            >
                                {isListening ? <MicOffIcon /> : <MicIcon />}
                            </button>
                        )}
                    </div>

                    {selectedImage && (
                        <div style={{ 
                            marginBottom: '10px', 
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '10px'
                        }}>
                            <div style={{
                                position: 'relative',
                                width: '100px',
                                height: '100px',
                                borderRadius: '8px',
                                overflow: 'hidden'
                            }}>
                                <img 
                                    src={URL.createObjectURL(selectedImage)} 
                                    alt="Selected food" 
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    }}
                                />
                                <button
                                    type="button"
                                    onClick={removeImage}
                                    style={{
                                        position: 'absolute',
                                        top: '4px',
                                        right: '4px',
                                        background: 'rgba(0, 0, 0, 0.5)',
                                        border: 'none',
                                        borderRadius: '50%',
                                        width: '24px',
                                        height: '24px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: 'white',
                                        cursor: 'pointer',
                                        fontSize: '14px'
                                    }}
                                >
                                    ✕
                                </button>
                            </div>
                        </div>
                    )}

                    {isListening && (
                        <div style={{ 
                            marginBottom: '10px',
                            color: '#FF5722',
                            fontWeight: 'bold',
                            animation: 'pulse 1.5s infinite'
                        }}>
                            Listening...
                        </div>
                    )}
                    
                    {micPermissionError && (
                        <div style={{ 
                            marginBottom: '10px',
                            color: '#f44336',
                            fontSize: '12px'
                        }}>
                            Microphone access denied. Please enable microphone permissions in your device settings.
                        </div>
                    )}

                    {!buttonDisabled && <div className="extract-recipe-dialog-form-submit-button">
                        <button 
                            type="submit" 
                            disabled={loading || (!prompt && !selectedImage)} 
                            className={prompt || selectedImage ? "submit-button" : "disabled-button"} 
                            style={{ padding: '10px 10px', fontSize: '15px', fontFamily: 'Montserrat-SemiBold', width:'198px' }} 
                            value="Submit" 
                        >
                            Estimate Macros
                            {loading && <LoopIcon style={{ fontSize: '22px', margin: '0px 0px 0px 5px', animation: 'spin 2s linear infinite' }} />}
                        </button>
                    </div>}

                </form>

                {foods.length === 0 ? <div style={{ fontSize: '10px', textAlign: 'center', color: 'grey', width: '305px', margin: '0px auto' }}>AI tracking provides a fast estimate of macronutrients but is not guaranteed to be 100% accurate. Note you can always adjust the macros once the items are in your calendar.</div>
                    : <>{rows}
                        <div className="extract-recipe-dialog-form-submit-button"><button disabled={loading || allNotChecked} className={!allNotChecked ? "order-ingredients-button-for-browser" : "order-ingredients-button-for-browser-disabled"} style={{ padding: '10px 20px', fontSize: '15px', fontFamily: 'Montserrat-SemiBold', width:'198px' }} onClick={handleAddToCalendar} >Add to Calendar</button></div>

                    </>}

                {errorState === 1 && <div style={{ marginTop: "10px" }}><Alert severity="error"><>Error from AI parsing food... Try again</></Alert></div>}

            </div>

        </>
    )

}

export default AITrackingForm;