import React from 'react';
import { CartContext } from 'contexts/cart_context';
import { AuthContext } from 'contexts/auth_context';
import { UserContext } from 'contexts/user_context';
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { app } from "../../../firebase";
import HealthService from 'services/health_service';

export const UserLoaderProvider = ({ children }) => {
  const { loadUsers } = React.useContext(CartContext);
  const { currentUser, isNewlyWrittenUser } = React.useContext(AuthContext);
  const { userObject, setUserObject } = React.useContext(UserContext);
  const db = getFirestore(app);
  const prevUserRef = React.useRef(null);
  const migrationCompleteRef = React.useRef(false);
  const { showBanner } = React.useContext(UserContext);

  // Handle user changes and loading
  React.useEffect(() => {
    if (currentUser?.uid !== prevUserRef.current) {
      migrationCompleteRef.current = false;
      prevUserRef.current = currentUser?.uid;
      console.log('UserLoader - New user detected, resetting migration status');
      loadUsers();
    }
  }, [currentUser, loadUsers]);

  // Initial health sync when user object loads
  React.useEffect(() => {
    const syncHealthData = async () => {
      if (!userObject?.loaded || !userObject?.healthSync) return;

      if (userObject.healthSync.steps || userObject.healthSync.weight) {
  
          
          await HealthService.syncHistoricalData(userObject, setUserObject, showBanner);
          

      }
    };

    syncHealthData();
  }, [userObject?.loaded]); // Only run on initial load

  // Existing migration effect
  React.useEffect(() => {
    const migrateExistingUser = async () => {
      // For newly written users, we don't need to migrate
      if (isNewlyWrittenUser) {
        console.log('UserLoader - Skipping migration for newly written user');
        loadUsers()
        return;
      }

      // Skip if already migrated or no user
      if (migrationCompleteRef.current || !currentUser || !userObject.loaded) {
        return;
      }

      // Only log on first check
      if (!migrationCompleteRef.current) {
        console.log('UserLoader - Checking migration status:', {
          currentUser: !!currentUser,
          userLoaded: userObject.loaded,
          hasOnboardingStatus: !!userObject.onboardingStatus,
          userId: currentUser?.uid,
        });
      }
    
      if (!userObject.onboardingStatus) {
        console.log('UserLoader - Migrating existing user:', currentUser?.uid);
        
        const updatedUserObject = {
          ...userObject,
          onboardingStatus: {
            isComplete: true,
            currentStage: 5
          }
        };
        setUserObject({...updatedUserObject});
        
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          await updateDoc(userDocRef, {
            onboardingStatus: {
              isComplete: true,
              currentStage: 5
            }
          });
          console.log('UserLoader - Migration complete for user:', currentUser?.uid);
          migrationCompleteRef.current = true;
        } catch (error) {
          console.error('UserLoader - Migration failed for user:', currentUser?.uid, error);
        }
      } else {
        migrationCompleteRef.current = true;
      }
    };

    migrateExistingUser();
  }, [currentUser, userObject.loaded,isNewlyWrittenUser]);

  return children;
}; 