import React from "react";
import './expanded_recipes_view.css';
import Button from '@material-ui/core/Button';
import { TextField } from "@material-ui/core";
import FilterIcon from "images/filters_icon.png";
import Filters from "../filters/filters";
import { useDebouncedCallback } from 'use-debounce';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import Chip from '@material-ui/core/Chip';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useHistory, useLocation } from 'react-router-dom';
import { checkImageValidity, cleanUpNutrients, decimalToFraction, getMealNutrientAmount, recalculateCaloriesForRecipe } from "helpers/recipe_helpers";
import { RecipeBooksContext } from "contexts/recipe_books_context";

const ExpandedRecipesView = ({
    recipes = [],
    recipesCollectionName = "Recipes",
    onBack,
    handleRecipeViewClick,
    handleRecipeAddClick,
    handleRecipeCopyClick,
    handleEditRecipeClick,
    currentUser,
    getCustomRecipeBookId,
    recipeFilterText,
    handleRecipeFilterTextChange,
    showFilters,
    toggleShowFilters,
    filters,
    setFilters,
    timeFiltered,
    setTimeFiltered,
    mealCategoriesFiltered,
    setMealCategoriesFiltered,
    recipeBooksFiltered,
    setRecipeBooksFiltered,
}) => {
    const location = useLocation();
    const { recipes: originalRecipes = [], recipesCollectionName: originalRecipesCollectionName = "Recipes" } = location.state || {};

    const history = useHistory();

    console.log(recipeFilterText)

    const debouncedLogFilterEvent = useDebouncedCallback(
        async (filteredText) => {
            try {
                if (filteredText.length >= 3) {
                    FirebaseAnalytics.logEvent({
                        name: "filtered_recipe",
                        params: { filtered_text: filteredText, currentUser: currentUser.uid }
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        1000
    );

    const getTimeRangeLabel = (range) => {
        if (range[0] === 0 && range[1] === 15) return "Quick (0-15m)";
        if (range[0] === 15 && range[1] === 30) return "15-30 mins";
        if (range[0] === 30 && range[1] === 60) return "30-60 mins";
        if (range[0] === 60 && range[1] === 300) return "60+ mins";
        return `${range[0]}-${range[1]} mins`;
    };

    const filtersApplied = Object.keys(filters).filter(key => filters[key]);

    return (
        <div className="expanded-recipes-container">
            <div className="expanded-recipes-header">
                <div className="expanded-recipes-header-tint">
                    <div className="expanded-recipes-title-row">
                        <button
                            onClick={onBack}
                            className="expanded-recipes-back-button"
                        >
                            ← Back
                        </button>
                        <div className="expanded-recipes-title">{recipesCollectionName}</div>
                    </div>
                    <div className="expanded-recipes-search-container">
                    <div className="expanded-recipes-search">
                        <button className="expanded-recipes-filter-button" onClick={toggleShowFilters}>
                            <img src={FilterIcon} alt="filter icon" />
                        </button>
                        <TextField
                            className="expanded-recipes-filter-input"
                            InputProps={{ disableUnderline: true }}
                            placeholder='Filter and search recipes...'
                            value={recipeFilterText}
                            onChange={handleRecipeFilterTextChange}
                        />
                    </div>

                    {/* Active Filters Display */}
                    {(filtersApplied.length > 0 || mealCategoriesFiltered || recipeBooksFiltered || timeFiltered.length > 0) && (
                        <div className="expanded-recipes-active-filters">
                            {timeFiltered.map((range, index) => (
                                <Chip
                                    key={`time-${index}`}
                                    icon={<AccessTimeIcon style={{fontSize: 16}}/>}
                                    label={getTimeRangeLabel(range)}
                                    onDelete={() => {
                                        const newTimeFiltered = timeFiltered.filter((_, i) => i !== index);
                                        setTimeFiltered(newTimeFiltered);
                                    }}
                                    style={{
                                        background: '#E8F5E9',
                                        color: '#4A7018',
                                        borderRadius: '16px',
                                        fontSize: '12px',
                                        height: '28px'
                                    }}
                                />
                            ))}
                            {filtersApplied.map((filter, index) => (
                                <Chip
                                    key={`filter-${index}`}
                                    label={filter}
                                    onDelete={() => {
                                        const newFilters = {...filters};
                                        newFilters[filter] = false;
                                        setFilters(newFilters);
                                    }}
                                    style={{
                                        background: '#E8F5E9',
                                        color: '#4A7018',
                                        borderRadius: '16px',
                                        fontSize: '12px',
                                        height: '28px'
                                    }}
                                />
                            ))}
                            {mealCategoriesFiltered && (
                                <Chip
                                    label={mealCategoriesFiltered}
                                    onDelete={() => setMealCategoriesFiltered("")}
                                    style={{
                                        background: '#E8F5E9',
                                        color: '#4A7018',
                                        borderRadius: '16px',
                                        fontSize: '12px',
                                        height: '28px'
                                    }}
                                />
                            )}
                            {recipeBooksFiltered && (
                                <Chip
                                    label={recipeBooksFiltered}
                                    onDelete={() => setRecipeBooksFiltered("")}
                                    style={{
                                        background: '#E8F5E9',
                                        color: '#4A7018',
                                        borderRadius: '16px',
                                        fontSize: '12px',
                                        height: '28px'
                                    }}
                                />
                            )}
                            {(filtersApplied.length > 0 || mealCategoriesFiltered || recipeBooksFiltered || timeFiltered.length > 0) && (
                                <Chip
                                    label="Clear all"
                                    onClick={() => {
                                        setFilters({});
                                        setMealCategoriesFiltered("");
                                        setRecipeBooksFiltered("");
                                        setTimeFiltered([]);
                                    }}
                                    style={{
                                        background: '#F5F5F5',
                                        color: '#616161',
                                        borderRadius: '16px',
                                        fontSize: '12px',
                                        height: '28px',
                                        cursor: 'pointer'
                                    }}
                                />
                            )}
                        </div>
                    )}
                    </div>
                </div>
            </div>
            <div className="expanded-recipes-grid-container">
                <div className="expanded-recipes-grid">
                    {recipes.map(recipe => (
                        <div key={recipe.id} className="expanded-recipes-card">
                            <img
                                className="expanded-recipes-thumbnail"
                                value={recipe.id ? recipe.id : recipe.spoonacularRecipeId}
                                onClick={handleRecipeViewClick}
                                src={recipe.picture.replace("https://storage.googleapis.com/thymeless-upload-storage/", "https://ik.imagekit.io/hk6gff5az/tr:w-210,h-210/")}
                                alt={recipe.name}
                            />
                            <div className="expanded-recipes-info">
                                <div className="expanded-recipes-name-outer">
                                    <div
                                        onClick={handleRecipeViewClick}
                                        value={recipe.id ? recipe.id : recipe.spoonacularRecipeId}
                                        className="expanded-recipes-name"
                                    >
                                        {recipe.name}
                                    </div>
                                </div>
                                {(!!recipe.aggregateLikes && !recipe.recipeBooks) &&
                                    <div className="expanded-recipes-likes">
                                        {recipe.aggregateLikes} like{recipe.aggregateLikes > 1 && "s"}
                                    </div>
                                }
                                {getMealNutrientAmount(recipe, "Calories") > 0 &&
                                    <div className="expanded-recipes-nutrition">
                                        <span className="caloric-chip caloric-chip-calories">
                                            {getMealNutrientAmount(recipe, "Calories")} Cals
                                        </span>
                                        <span className="caloric-chip caloric-chip-protein">
                                            {getMealNutrientAmount(recipe, "Protein")}g
                                        </span>
                                        <span className="caloric-chip caloric-chip-carbs">
                                            {getMealNutrientAmount(recipe, "Carbohydrates")}g
                                        </span>
                                        <span className="caloric-chip caloric-chip-fats">
                                            {getMealNutrientAmount(recipe, "Fat")}g
                                        </span>
                                    </div>
                                }
                                <div>
                                    <button
                                        className="plan-recipes-add-button"
                                        variant="contained"
                                        color="primary"
                                        value={recipe.id ? recipe.id : recipe.spoonacularRecipeId}
                                        onClick={handleRecipeAddClick}
                                    >
                                        Add
                                    </button>
                                    {(recipe.recipeBooks && recipe.recipeBooks.includes(getCustomRecipeBookId())) ?
                                        <button
                                            className="plan-recipes-add-button"
                                            style={{ background: 'rgb(129 197 36)' }}
                                            variant="contained"
                                            color="primary"
                                            value={recipe.id}
                                            onClick={handleEditRecipeClick}
                                        >
                                            Edit
                                        </button>
                                        :
                                        <button
                                            className="plan-recipes-add-button"
                                            style={{ background: '#E6EFDA', color: '#5e8b20' }}
                                            variant="contained"
                                            color="primary"
                                            value={recipe.id ? recipe.id : recipe.spoonacularRecipeId}
                                            onClick={handleRecipeCopyClick}
                                        >
                                            Save
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {showFilters && (
                <Filters
                    addTime={true}
                    addComplexity={true}
                    removeRecipeBooks={true}
                    filters={filters}
                    setFilters={setFilters}
                    timeFiltered={timeFiltered}
                    setTimeFiltered={setTimeFiltered}
                    mealCategoriesFiltered={mealCategoriesFiltered}
                    setMealCategoriesFiltered={setMealCategoriesFiltered}
                    recipeBooksFiltered={recipeBooksFiltered}
                    setRecipeBooksFiltered={setRecipeBooksFiltered}
                />
            )}
        </div>
    );
};

export default ExpandedRecipesView; 