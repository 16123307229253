import React, { useContext } from 'react';
import { UserContext } from 'contexts/user_context';

const BannerSystem = ({ isOnline }) => {
    const { bannerMessages, hideBanner } = useContext(UserContext);

    if (!bannerMessages.length) return null;

    return (
        <div style={{
            position: 'fixed',
            bottom: isOnline ? '60px' : '120px',
            left: 0,
            right: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            padding: '0 16px',
            pointerEvents: 'none', // Allow clicking through the container
            zIndex: 9999, // Moved z-index to the container
        }}>
            {bannerMessages.map((banner) => (
                <div
                    key={banner.id}
                    style={{
                        backgroundColor: '#2E6E4D',
                        color: 'white',
                        padding: '6px 16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontSize: '12px',
                        backdropFilter: 'blur(10px)',
                        borderRadius: '10px',
                        width: 'calc(100% - 32px)',
                        pointerEvents: 'auto', // Re-enable pointer events for the banner itself
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '8px' }}>ⓘ</span>
                        <span>{banner.message}</span>
                    </div>
                    <button
                        onClick={() => hideBanner(banner.id)}
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            color: 'white',
                            padding: '4px 0',
                            cursor: 'pointer',
                            fontSize: '12px',
                            fontFamily: 'Montserrat-SemiBold'
                        }}
                    >
                        Dismiss
                    </button>
                </div>
            ))}
        </div>
    );
};

export default BannerSystem; 