import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from 'contexts/user_context';
import { useHistory } from 'react-router-dom';
import './step_tracker_card.css';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import StepsIcon from 'images/Steps.png';
import StepsGreen from 'images/Steps-Green.png';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SyncIcon from '@material-ui/icons/Sync';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Slider,
  Tooltip,
  Divider,
} from '@material-ui/core';
import { getDateMonthYear } from 'helpers/date_helpers';
import { Capacitor } from '@capacitor/core';

const StepTrackerCard = ({ calendarDate }) => {
  const history = useHistory();
  const { getStepData, setStepData, getStepGoal, setStepGoal, userObject } = useContext(UserContext);
  const isMobile = Capacitor.getPlatform() !== 'web';

  // Get steps and handle both object and number formats
  const rawSteps = getStepData(calendarDate);
  const [steps, setSteps] = useState(typeof rawSteps === 'object' ? rawSteps?.value || 0 : rawSteps || 0);

  const [stepGoalState, setStepGoalState] = useState(getStepGoal());
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [inputDialogOpen, setInputDialogOpen] = useState(false);
  const [newSteps, setNewSteps] = useState('');

  useEffect(() => {
    const initialSteps = getStepData(calendarDate);
    // Handle both object and number formats
    setSteps(typeof initialSteps === 'object' ? initialSteps?.value || 0 : initialSteps || 0);
  }, [calendarDate, getStepData]);

  useEffect(() => {
    setStepGoalState(getStepGoal());
  }, [userObject.stepGoal]);

  // Check if steps are from health sync
  const isHealthData = typeof rawSteps === 'object' && rawSteps?.source === 'healthKit';
  const hasHealthSync = userObject?.healthSync?.steps;

  const handleNavigateToAccount = () => {
    setInputDialogOpen(false);
    history.push('/account');
  };

  const handleSaveSteps = () => {
    const stepsValue = parseInt(newSteps, 10);
    setSteps(stepsValue);
    setStepData(calendarDate, stepsValue);
    setInputDialogOpen(false);
    setNewSteps('');
  };

  const handleSaveSettings = () => {
    setStepGoal(stepGoalState);
    setSettingsOpen(false);
  };

  const progressBar = (steps / stepGoalState) * 100;
  const progress = Math.min(97, Math.max(3, (steps / stepGoalState) * 100));

  const getProgressColor = (steps, goal) => {
    const percentage = (steps / goal) * 100;

    if (percentage >= 100) {
      return {
        bar: 'rgba(76, 175, 80, 0.2)', // green
        icon: '#4CAF50'
      };
    } else if (percentage >= 75) {
      return {
        bar: 'rgba(241, 196, 15, 0.2)', // yellow
        icon: '#f1c40f'
      };
    } else if (percentage >= 50) {
      return {
        bar: 'rgba(243, 156, 18, 0.2)', // orange
        icon: '#f39c12'
      };
    } else {
      return {
        bar: 'rgba(241, 160, 146, 0.2)', // original red
        icon: '#f1a092'
      };
    }
  };

  const colors = getProgressColor(steps, stepGoalState);

  // Format the date for the dialog title
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="step-tracker-card">
      <div className="step-tracker-header">
        <div className="header-left">Steps</div>
        <div className="header-center">
          {steps.toLocaleString()} / {stepGoalState.toLocaleString()}
        </div>
        <div className="header-right">
          <MoreHorizIcon
            className="settings-icon"
            onClick={() => setSettingsOpen(true)}
            style={{ cursor: 'pointer', color: 'grey' }}
          />
        </div>
      </div>

      <div
        className="step-progress-container"
        onClick={() => setInputDialogOpen(true)}
        style={{ cursor: 'pointer' }}
      >
        <div
          className="step-progress-bar"
          style={{
            width: `${Math.min(100, progressBar)}%`,
            backgroundColor: colors.bar
          }}
        />
        <img src={progressBar >= 100 ? StepsGreen : StepsIcon}
          className="step-icon"
          style={{
            left: `calc(${Math.max(Math.min(100, progress), 5)}% - 12px)`,
            height: '24px',
          }}
        />
      </div>

      {/* Enhanced Input Dialog */}
      <Dialog open={inputDialogOpen} onClose={() => setInputDialogOpen(false)}>
        <DialogTitle>Log Steps for {formatDate(calendarDate)}</DialogTitle>
        <DialogContent style={{ paddingTop: '10px', minWidth: '300px' }}>
          {isMobile && (
            <>
              {/* Health Sync Status Section */}
              <div style={{ marginBottom: '20px' }}>
                {hasHealthSync ? (
                  <div>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '8px',
                      color: '#666'
                    }}>
                      <PhoneIphoneIcon style={{ marginRight: '8px', fontSize: '20px' }} />
                      <span style={{ fontSize: '13px' }}>Health App Sync is enabled</span>
                    </div>
                    {isHealthData && (
                      <div style={{
                        fontSize: '13px',
                        color: '#666',
                        backgroundColor: 'rgba(241, 160, 146, 0.1)',
                        padding: '8px',
                        borderRadius: '4px',
                        marginBottom: '8px'
                      }}>
                        Note: Manual entry will override synced data for this day
                      </div>
                    )}
                  </div>
                ) : (
                  <div style={{ marginBottom: '16px', textAlign: 'center' }}>
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '8px',
                      justifyContent: 'center'
                    }}>
                      <span style={{ fontSize: '13px', color: '#666' }}>
                        Want to sync steps automatically?
                      </span>
                    </div>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleNavigateToAccount}
                      style={{
                        fontSize: '13px',
                        textTransform: 'none'

                      }}
                    >
                      Enable Health App Sync
                    </Button>
                  </div>
                )}
              </div>
              <Divider style={{ margin: '16px 0' }} />
            </>
          )}

          {/* Manual Input Section */}
          <div>
            <TextField
              autoFocus
              label="Steps"
              type="number"
              fullWidth
              value={newSteps}
              onChange={(e) => setNewSteps(e.target.value)}
              inputProps={{ min: 0 }}
              variant="outlined"
            />
            {isHealthData && (
              <div style={{
                fontSize: '13px',
                color: '#666',
                marginTop: '8px'
              }}>
                Current synced value: {steps.toLocaleString()} steps
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInputDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleSaveSteps}
            color="primary"
            disabled={!newSteps || parseInt(newSteps, 10) < 0}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Settings Dialog */}
      <Dialog open={settingsOpen} onClose={handleSaveSettings}>
        <DialogTitle>Step Counter Settings</DialogTitle>
        <DialogContent>
          <div className="settings-content" style={{ padding: '0px 20px' }}>
            {/* Health Sync Section */}
            <div className="settings-item" style={{ marginBottom: '20px' }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '10px'
              }}>
                <div>
                  <h3 style={{ margin: '0', color: '#333' }}>Health App Sync</h3>
                  <p style={{ margin: '5px 0', color: '#666', fontSize: '14px' }}>
                    Automatically sync steps from your phone
                  </p>
                </div>
                <PhoneIphoneIcon style={{ color: '#666' }} />
              </div>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleNavigateToAccount}
                style={{ width: '100%', color: 'rgb(241, 160, 146)', borderColor: 'rgb(241, 160, 146)' }}
              >
                Manage Health Sync
              </Button>
            </div>

            <Divider style={{ margin: '20px 0' }} />

            {/* Daily Goal Section */}
            <div className="settings-item">
              <h3 style={{ margin: '0 0 10px 0', color: '#333' }}>Daily Goal</h3>
              <p style={{ margin: '0 0 15px 0' }}>
                {stepGoalState.toLocaleString()} steps
              </p>
              <Slider
                style={{ color: 'rgb(241, 160, 146)' }}
                value={stepGoalState}
                onChange={(e, newValue) => setStepGoalState(newValue)}
                min={2000}
                max={20000}
                step={500}
                marks
                valueLabelDisplay="auto"
              />
            </div>

            <div style={{ marginTop: '20px', fontSize: '14px', color: '#666' }}>
              <p>Recommended daily step goals:</p>
              <ul style={{ marginTop: '5px' }}>
                <li>Starting point for health: 7,000 steps</li>
                <li>Active lifestyle: 10,000 steps</li>
                <li>High activity: 12,000+ steps</li>
              </ul>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveSettings} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StepTrackerCard; 