import React from 'react';
import { Button, makeStyles, MenuItem, TextField } from '@material-ui/core';
import axios from 'axios';
import './food_search_box.css';
import { Alert } from '@material-ui/lab';
// import FoodSearchRow from './branded_product_search_row';
import { CartContext } from 'contexts/cart_context';

import LoopIcon from '@material-ui/icons/Loop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Search from '@material-ui/icons/Search';
import BarcodeIcon from 'images/barcode-scanning.png';

import FoodSearchBoxRow from './food_search_box_row';
import BarcodeScanner from './barcode_scanner';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import SearchIcon from '@material-ui/icons/Search';
import FastfoodIcon from '@material-ui/icons/Fastfood';

const useStyles = makeStyles({
  favoriteIconContainer: {
    position: 'relative',
    display: 'inline-block',
    width: '24px',
    height: '24px',
  },
  favoriteIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: 'rgb(255 91 149)',
    opacity: 0,
    animation: '$fillIconAnimation 0.5s forwards',
    animationDelay: '1s',
  },
  favoriteBorderIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    color: 'grey',
    opacity: 1,
    animation: '$fadeOutIconAnimation 0.5s forwards',
    animationDelay: '1s',
  },
  '@keyframes fillIconAnimation': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  '@keyframes fadeOutIconAnimation': {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
});

const FoodSearchBox = (props) => {
  const favoriteFoodList = React.useContext(CartContext).favoriteFoodList;
  const [foodText, setFoodText] = React.useState('');
  const [foodList, setFoodList] = React.useState(favoriteFoodList);
  const [hasFoundFood, setHasFoundFood] = React.useState(1);
  const [errorState, setErrorState] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [foodMRU, setFoodMRU] = React.useState([]);
  const [barcodeScanDialogOpen, setBarcodeScanDialogOpen] = React.useState(false);
  const [tempKey, setTempKey] = React.useState(0);
  const classes = useStyles();

  


  React.useEffect(() => {
    if (hasFoundFood === 1 && favoriteFoodList.length > foodList.length) {
      setFoodList(favoriteFoodList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteFoodList]);


  const filterFavorites = (searchTerm) => {
    let filteredFoods = [];
    for (let favoriteItem of favoriteFoodList) {
      if (favoriteItem.type === 'meal') {
        if (favoriteItem.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          filteredFoods.push(favoriteItem);
        }
      } else {
        if (favoriteItem.food_name.toLowerCase().includes(searchTerm.toLowerCase())) {
          filteredFoods.push(favoriteItem);
        }
      }
    }
    return filteredFoods;
  };

  async function queryForFoods(term) {
    setHasFoundFood(2);
    setErrorState(0);
    let productText = term;

    if (!term) {
      setFoodList([]);
      return;
    }

    // First, filter and display favorites
    const matchingFavorites = filterFavorites(term);
    setFoodList(matchingFavorites);

    try {
      setIsLoading(true);
      const resp = await axios.get('/food-search/' + encodeURIComponent(productText));
      if (resp.data.length === 0 && matchingFavorites.length === 0) {
        setHasFoundFood(0);
      }
      for (let foodItem of resp.data) {
        foodItem.servingChosen = 0;
        foodItem.portionChosen = 1;
      }
      // Combine favorites with search results, removing duplicates
      const searchResults = resp.data.filter(item => 
        !matchingFavorites.some(fav => fav.food_id === item.food_id)
      );
      setFoodList([...matchingFavorites, ...searchResults]);
      setIsLoading(false);
    } catch {
      if (matchingFavorites.length === 0) {
        setFoodList([]);
        setErrorState(1);
      }
      setIsLoading(false);
    }
  }

  function openBarcodeDialog() {
    setTempKey(new Date().getTime());
    setBarcodeScanDialogOpen(true);
  }

  const handleFoodTextChange = (e) => {
    setFoodText(e.target.value);

    if (hasFoundFood === 1) {
      // Filter foods
      let filteredFoods = [];
      for (let favoriteItem of favoriteFoodList) {
        if (favoriteItem.type === 'meal') {
          if (favoriteItem.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            filteredFoods.push(favoriteItem);
          }
        } else {
          if (favoriteItem.food_name.toLowerCase().includes(e.target.value.toLowerCase())) {
            filteredFoods.push(favoriteItem);
          }
        }
      }
      setFoodList(filteredFoods);
    }

    axios
      .get('/food-search/autocomplete/' + encodeURIComponent(e.target.value))
      .then((resp) => {
        if (resp.data.length !== 0) {
          resp.data[0] = resp.data[0] + ' ';
          setFoodMRU(resp.data);
        } else {
          setFoodMRU([]);
        }
      });
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      queryForFoods(foodText);
    }
  };

  const handleAutoCompleteClick = (e, o, r) => {
    if (r === 'reset') {
      setFoodText(foodMRU[parseInt(e.target.getAttribute('data-option-index'))]);

      queryForFoods(foodMRU[parseInt(e.target.getAttribute('data-option-index'))]);
    }
    if (e.target.value !== 0 && (e.target.value === '' || !e.target.value)) {
      setFoodList(favoriteFoodList);
      setHasFoundFood(1);
    }
  };

  let rows = [];

  for (let index in foodList) {
    let food = foodList[index];

    let foodInFavorites = false;
    for (let favoriteFood of favoriteFoodList) {
      if (favoriteFood.food_id === food.food_id) {
        foodInFavorites = true;
        break;
      }
    }

    if (food.type && food.type === 'meal') {
      rows.push(
        <FoodSearchBoxRow
          key={index}
          food={food}
          foodList={foodList}
          setFoodList={setFoodList}
          index={index}
          foodInFavorites={foodInFavorites}
          calendarCategory={props.calendarCategory}
          calendarDate={props.calendarDate}
        />
      );
    } else {
      if (food.food_id)
        rows.push(
          <FoodSearchBoxRow
            key={index}
            food={food}
            foodList={foodList}
            setFoodList={setFoodList}
            index={index}
            servingOptions={food.servings.serving} // Pass the servings array
            foodInFavorites={foodInFavorites}
            calendarCategory={props.calendarCategory}
            calendarDate={props.calendarDate}
          />
        );
    }
  }
  return (
    <>
      <div style={{ width: 'calc(100vw - 30px)', maxWidth: '500px' }}>
        <div className="branded-product-search-box">
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            onInputChange={handleAutoCompleteClick}
            options={foodMRU}
            className="branded-product-search-textfield-parent"
            renderInput={(params) => (
              <TextField
                className="branded-product-search-textfield"
                {...params}
                placeholder="Search brand or food name"
                margin="normal"
                value={foodText}
                variant="outlined"
                onKeyDown={handleKeyDown}
                onChange={handleFoodTextChange}
              />
            )}
          />
          <Button className="branded-product-search-button" onClick={() => queryForFoods(foodText)}>
            {' '}
            <Search style={{ color: '#2D7933' }} />
          </Button>
          <Button
            className="branded-product-search-button"
            style={{ marginLeft: '5px' }}
            onClick={openBarcodeDialog}
          >
            {' '}
            <img
              alt="barcode"
              style={{ width: '25px', padding: '5px 0px' }}
              src={BarcodeIcon}
            />
          </Button>
        </div>
        <div className={rows.length ? 'branded-product-search-results' : ''}>
          {rows}
          {rows.length !== 0 && (
            <div style={{ color: 'grey', fontSize: '12px', textAlign: 'center', padding: '5px 0px 5px 0px' }}>
              Powered by FatSecret
            </div>
          )}
        </div>
        {isLoading && (
          <div style={{ textAlign: 'center' }}>
            <LoopIcon style={{ animation: 'spin 2s linear infinite' }} />
          </div>
        )}

        <div style={{ margin: '0px auto', width: 'calc(100% - 30px)' }}>
          {errorState === 1 && <Alert severity="error">Error finding food, try a different search term</Alert>}
          {!hasFoundFood && <Alert severity="warning">No branded product found for that search term!</Alert>}
          {rows.length === 0 && errorState === 0 && hasFoundFood && favoriteFoodList.length === 0 && (
            <div style={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '25px 20px',
              color: '#666',
              textAlign: 'center',
              background: '#f9f9f9',
              margin: '20px 10px',
              borderRadius: '8px'
            }}>
              <FastfoodIcon style={{ fontSize: 40, color: '#2D7933', marginBottom: '15px' }} />
              <div style={{ fontSize: '14px', marginBottom: '12px', fontFamily: 'Montserrat-SemiBold' }}>
                Track Any Food You Eat
              </div>
              <div style={{ fontSize: '13px', lineHeight: '1.5', maxWidth: '300px' }}>
                Search for any food or scan barcodes to track your meals. Add items to favorites by clicking{' '}
                <span style={{ 
                  display: 'inline-flex', 
                  alignItems: 'center',
                  verticalAlign: 'middle',
                  position: 'relative',
                  height: '20px',
                  width: '20px',
                  margin: '0 4px'
                }}>
                  <span className={classes.favoriteIconContainer}>
                    <FavoriteBorderIcon className={classes.favoriteBorderIcon} />
                    <FavoriteIcon className={classes.favoriteIcon} />
                  </span>
                </span>
                {' '}for quick access.
              </div>
            </div>
          )}

        </div>
      </div>

      {barcodeScanDialogOpen && (
        <BarcodeScanner
          key={tempKey}
          handleExitClick={props.handleExitClick}
          handleTabChange={props.handleTabChange}
          calendarCategory={props.calendarCategory}
          barcodeScanDialogOpen={barcodeScanDialogOpen}
          setBarcodeScanDialogOpen={setBarcodeScanDialogOpen}
          calendarDate={props.calendarDate}
          close={() => {
            setBarcodeScanDialogOpen(false);
          }}
        ></BarcodeScanner>
      )}
    </>
  );
};
export default FoodSearchBox;
