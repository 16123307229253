// CalendarColumn.js

import React from 'react';
import CalendarSlot from './calendar_slot';
import CalendarChart from '../calendar_chart/calendar_chart';
import CalendarEmptyColumn from './calendar_empty_column';
import WaterTrackerCard from '../water_tracker_card/water_tracker_card';
import ExerciseTrackerCard from '../exercise_tracker_card/exercise_tracker_card';
import StepTrackerCard from '../step_tracker_card/step_tracker_card';
import WeightTrackerCard from '../weight_tracker_card/weight_tracker_card';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EditIcon from '@material-ui/icons/Edit';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import LoopIcon from '@material-ui/icons/Loop';
import { UserContext } from 'contexts/user_context';
import { CartContext } from 'contexts/cart_context';
import { AuthContext } from 'contexts/auth_context';
import CalorieBox from '../modify_calories/calorie_box';
import { RecipeBooksContext } from 'contexts/recipe_books_context';
import Popover from '@material-ui/core/Popover';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import { Dialog, Switch, FormControlLabel, Button, IconButton, Checkbox } from '@material-ui/core';
import LogWeight from '../log_weight/log_weight';
import WeightOverTimeGraph from '../graphs/weight_over_time_graph';
import { useHistory } from 'react-router-dom';
import { getMealNutrientAmount } from 'helpers/recipe_helpers';

const CalendarColumn = (props) => {
  const getCalorieCategory = React.useContext(UserContext).getCalorieCategory();
  const recipeList = React.useContext(RecipeBooksContext).recipeList;
  const { userObject, setUserObject } = React.useContext(UserContext);

  const { currentUser } = React.useContext(AuthContext);
  const [isGenerateLoading, setIsGenerateLoading] = React.useState(false);
  const [wasGenerateTriggered, setWasGenerateTriggered] = React.useState(false);
  const [logWeightDialogOpen, setLogWeightDialogOpen] = React.useState(false);
  const [weightGraphDialogOpen, setWeightGraphDialogOpen] = React.useState(false);
  const [editLayoutDialogOpen, setEditLayoutDialogOpen] = React.useState(false);
  // Add temporary state for layout changes
  const [tempLayoutConfig, setTempLayoutConfig] = React.useState([]);
  const history = useHistory();

  // Add activeIndex state
  const [activeIndex, setActiveIndex] = React.useState(0);

  // Define slides and selectedMacros based on activeIndex
  const slides = [
    ['Protein', 'Carbohydrates', 'Fat'],
    ['Sodium', 'Sugar', 'Cholesterol'],
    ['Fiber', 'Net Carbohydrates', 'Saturated Fat'],
  ];

  const selectedMacros = slides[activeIndex];

  // Define default component configuration
  const defaultComponentConfig = [
    { id: 'chart', name: 'Nutrition Chart', visible: true },
    { id: 'weight', name: 'Weight Tracker', visible: true },
    { id: 'water', name: 'Water Tracker', visible: true },
    { id: 'breakfast', name: 'Breakfast', visible: true },
    { id: 'lunch', name: 'Lunch', visible: true },
    { id: 'dinner', name: 'Dinner', visible: true },
    { id: 'snack', name: 'Snack', visible: true },
    { id: 'steps', name: 'Steps Tracker', visible: true },
    { id: 'exercise', name: 'Exercise Tracker', visible: true },
  ];

  // Get component config from userObject or use default
  const componentConfig = userObject.calendarLayout || defaultComponentConfig;

  function handleLogWeightClick() {
    setLogWeightDialogOpen(true);
  }

  const handleLogWeightExit = () => {
    setLogWeightDialogOpen(false);
  };

  const handleWeightGraphExit = () => {
    setWeightGraphDialogOpen(false);
  };

  const handleLogWeightSubmit = () => {
    setLogWeightDialogOpen(false);
    setWeightGraphDialogOpen(true);
  };

  const handleEditLayoutOpen = () => {
    // Initialize temporary layout config with a deep copy of the current config
    setTempLayoutConfig(JSON.parse(JSON.stringify(componentConfig)));
    setEditLayoutDialogOpen(true);
  };

  const handleEditLayoutClose = () => {
    setEditLayoutDialogOpen(false);
  };

  const handleToggleVisibility = (id) => {
    // Update temporary layout config instead of the actual one
    setTempLayoutConfig(prevConfig => 
      prevConfig.map(component => 
        component.id === id 
          ? { ...component, visible: !component.visible } 
          : component
      )
    );
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    
    // Update temporary layout config instead of the actual one
    const items = Array.from(tempLayoutConfig);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    
    setTempLayoutConfig(items);
  };

  const resetToDefaults = () => {
    // Update temporary layout config with defaults
    setTempLayoutConfig(JSON.parse(JSON.stringify(defaultComponentConfig)));
  };

  const saveLayoutChanges = () => {
    // Save the temporary layout to userObject
    setUserObject({ 
      ...userObject, 
      calendarLayout: tempLayoutConfig 
    });
    handleEditLayoutClose();
  };

  if (!props.isColumnVisible) {
    return <div className="calendar-column"></div>;
  }

  let categoryMeals = { breakfast: [], lunch: [], dinner: [], snack: [] };
  for (let cartMeal of props.cartMeals) {
    categoryMeals[cartMeal.calendarCategory].push(cartMeal);
  }

  let calendarDateObject = new Date(props.calendarDate);
  let isDayInFuture = true;
  if (new Date() - calendarDateObject > 0) {
    isDayInFuture = false;
  }

  // Calculate totals for macros
  const calculateVerticalTotal = (cartMeals, attribute) => {
    let total = 0;
    for (let meal of cartMeals) {
      if (meal.nutrition) {
        let mealNutrientAmount = getMealNutrientAmount(meal, attribute);
        if (mealNutrientAmount) {
          total += mealNutrientAmount;
        }
      }
    }
    return total;
  };

  const totalCalories = calculateVerticalTotal(props.cartMeals, 'Calories');
  const totalProteins = calculateVerticalTotal(props.cartMeals, 'Protein');
  const totalCarbs = calculateVerticalTotal(props.cartMeals, 'Carbohydrates');
  const totalFats = calculateVerticalTotal(props.cartMeals, 'Fat');
  const totalSodium = calculateVerticalTotal(props.cartMeals, 'Sodium');
  const totalSugar = calculateVerticalTotal(props.cartMeals, 'Sugar');
  const totalCholesterol = calculateVerticalTotal(props.cartMeals, 'Cholesterol');
  const totalFiber = calculateVerticalTotal(props.cartMeals, 'Fiber');
  const totalSaturatedFat = calculateVerticalTotal(props.cartMeals, 'Saturated Fat');
  const totalNetCarbs = calculateVerticalTotal(props.cartMeals, 'Net Carbohydrates');

  // Render components based on configuration
  const renderComponent = (componentId) => {
    switch (componentId) {
      case 'chart':
        return (
          <CalendarChart
            key="calendar-chart"
            day={props.day}
            calendarDate={props.calendarDate}
            cartMeals={props.cartMeals}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            userObject={userObject}
            totalProteins={totalProteins}
            totalCarbs={totalCarbs}
            totalFats={totalFats}
            totalSodium={totalSodium}
            totalSugar={totalSugar}
            totalCholesterol={totalCholesterol}
            totalFiber={totalFiber}
            totalNetCarbs={totalNetCarbs}
            totalSaturatedFat={totalSaturatedFat}
            totalCalories={totalCalories}
          />
        );
      case 'weight':
        return <WeightTrackerCard key="weight-tracker" calendarDate={props.calendarDate} />;
      case 'water':
        return <WaterTrackerCard key="water-tracker" calendarDate={props.calendarDate} />;
      case 'breakfast':
      case 'lunch':
      case 'dinner':
      case 'snack':
        return (
          <CalendarSlot
            key={`calendar-slot-${componentId}`}
            cartMeals={categoryMeals[componentId] || []}
            calendarDate={props.calendarDate}
            calendarCategory={componentId}
            selectedMacros={selectedMacros}
          />
        );
      case 'steps':
        return <StepTrackerCard key="step-tracker" calendarDate={props.calendarDate} />;
      case 'exercise':
        return <ExerciseTrackerCard key="exercise-tracker" calendarDate={props.calendarDate} />;
      default:
        return null;
    }
  };

  let slots = [];
  if (props.cartMeals.length === 0 && !props.isCarouselDisabled) {
    slots.push(<CalendarEmptyColumn key={`calendar-empty`} calendarDate={props.calendarDate} />);
  } else {
    // Render components based on configuration
    componentConfig.forEach(component => {
      if (component.visible) {
        slots.push(renderComponent(component.id));
      }
    });
  }

  return (
    <div className="calendar-column">
      <div style={{ overflowY: 'auto', padding: '0px 10px 0px 10px' }}>
        {slots}
        
        {/* Edit Layout Button - now inside the scrollable area */}
        <div style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          padding: '0px 0px 10px 0px',
          marginTop: '0px',
        }}>
          <button 
            onClick={handleEditLayoutOpen}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '5px',
              background: 'transparent',
              border: '0px solid #ddd',
              borderRadius: '4px',
              padding: '5px 10px',
              cursor: 'pointer',
              color: '#666',
              fontSize: '12px',
              transition: 'all 0.2s ease',
            }}
       
          >
            <EditIcon style={{ fontSize: '16px' }} />
            Edit Layout
          </button>
        </div>
      </div>

      {/* Edit Layout Dialog */}
      <Dialog open={editLayoutDialogOpen} maxWidth={'sm'} onClose={handleEditLayoutClose}>
        <div style={{ width: '350px', maxWidth: 'calc(100vw - 20px)', padding: '20px' }}>
          <div className="input-calories-popover-header" style={{ fontFamily: 'Montserrat-SemiBold', marginBottom: '15px' }}>
            Edit Calendar Layout
          </div>
          
          <div style={{ marginBottom: '15px', fontSize: '14px', color: '#666' }}>
            Drag to reorder components or toggle visibility
          </div>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ marginBottom: '20px' }}
                >
                  {tempLayoutConfig.map((component, index) => (
                    <Draggable key={component.id} draggableId={component.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px',
                            marginBottom: '8px',
                            background: '#f9f9f9',
                            borderRadius: '4px',
                            border: '1px solid #eee',
                            touchAction: 'none',
                            ...provided.draggableProps.style
                          }}
                        >
                          <div 
                            {...provided.dragHandleProps} 
                            style={{ 
                              marginRight: '10px', 
                              cursor: 'grab',
                              touchAction: 'none'
                            }}
                          >
                            <DragIndicatorIcon style={{ color: '#999' }} />
                          </div>
                          <div style={{ flex: 1 }}>{component.name}</div>
                          <IconButton 
                            size="small" 
                            onClick={() => handleToggleVisibility(component.id)}
                            style={{ color: component.visible ? '#4CAF50' : '#999' }}
                          >
                            {component.visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <button
              className="cancel-button"
              onClick={resetToDefaults}
              style={{ marginRight: '10px', display:'flex', alignItems:'center' }}
            >
              Reset to Default
            </button>
            <button
              className="submit-button"
              onClick={saveLayoutChanges}
            >
              Save Changes
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog open={logWeightDialogOpen} maxWidth={'xl'} onClose={handleLogWeightExit}>
        <LogWeight calendarDate={props.calendarDate} handleLogWeightExit={handleLogWeightSubmit} />
      </Dialog>

      <Dialog open={weightGraphDialogOpen} maxWidth={'xl'} onClose={handleWeightGraphExit}>
        <div style={{ width: '350px', maxWidth: 'calc(100vw - 20px)', padding: '10px' }}>
          <div className="input-calories-popover-header" style={{ fontFamily: 'Montserrat-SemiBold' }}>
            Your weight over time
          </div>

          <WeightOverTimeGraph />
          <div
            style={{
              fontSize: '12px',
              color: 'grey',
              textAlign: 'center',
              margin:'15px 5px'
            }}
          >
            We recommend reviewing your macros and progress with our AI consultant weekly
          </div>
          <button
            className="generate-button"
            style={{ margin: '10px auto' }}
            onClick={() => {
              history.push('ai_consultation');
            }}
          >
            Consult with AI
          </button>
        </div>
      </Dialog>
    </div>
  );
};

export default CalendarColumn;
