import './copy_meals_to_day.css'
import React from 'react';
import 'react-calendar/dist/Calendar.css';

import {  MenuItem, Select } from '@material-ui/core';

import Calendar from 'react-calendar'
import { getDateString } from 'helpers/date_helpers';
import { CartContext } from 'contexts/cart_context';
import { UserContext } from 'contexts/user_context';
import { getMealDateString } from 'contexts/cart_context';


const CopyMealsToDay = (props) => {
	const addMealsFromCopy = React.useContext(CartContext).addMealsFromCopy;
	const [dateToCopyTo, setDateToCopyTo] = React.useState(new Date())
	const [chosenCalendarCategory, setChosenCalendarCategory] = React.useState(props.calendarCategory)
	const setUserObjectNoSave = React.useContext(UserContext).setUserObjectNoSave
	const userObject = React.useContext(UserContext).userObject


	const minDate = new Date();
	minDate.setDate(new Date().getDate() - 29)
	const maxDate = new Date();
	maxDate.setDate(new Date().getDate() + 29)


	const handleDayChange = (date) => {
		setDateToCopyTo(date)

	}

	function setUserCalendarPositionBasedOnDate() {
		let diffTime = (dateToCopyTo - new Date())
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		setUserObjectNoSave({ ...userObject, calendarPosition: diffDays + 30, calendarPositionModified:true })
	}

	const handleSubmitClick = () => {
		// Format the date in local timezone without UTC conversion
		const year = dateToCopyTo.getFullYear();
		const month = String(dateToCopyTo.getMonth() + 1).padStart(2, '0');
		const day = String(dateToCopyTo.getDate()).padStart(2, '0');
		const mealDateStr = `${year}-${month}-${day}`;
		// Add mealDate to the meals being copied
		const mealsWithDate = props.meals.map(meal => ({
			...meal,
			mealDate: mealDateStr,
			calendarDate: dateToCopyTo // keep for backwards compatibility
		}));

		addMealsFromCopy(mealsWithDate, dateToCopyTo, chosenCalendarCategory);
		setUserCalendarPositionBasedOnDate();
		props.exitCopyDaysDialog();
	}

	// function determineDay(calendarPositionX) {
	// 	const ordinals = ["", "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th", "13th", "14th", "15th",
	// 		"16th", "17th", "18th", "19th", "20th", "21st", "22nd", "23rd", "24th", "25th", "26th", "27th", "28th", "29th", "30th", "31st"];
	// 	var d = new Date();
	// 	d.setDate(d.getDate())
	// 	d.setDate(d.getDate() + calendarPositionX);
	// 	return d.toLocaleDateString('en-US', { weekday: 'long' }) + " " + ordinals[d.getDate()]

	// }

	const handleCalendarCategoryChange = (e) => {
		setChosenCalendarCategory(e.target.value)
	}


	return (
		<>
			<div style={{ margin: '20px 30px', textAlign: 'center' }}>

				<div style={{ marginBottom: '18px', fontSize: '18px', fontFamily: 'Montserrat-Semibold' }}>Choose day to copy meal(s) to:</div>

				<Calendar value={dateToCopyTo} minDate={minDate} maxDate={maxDate} onChange={handleDayChange} />
				<div style={{ margin: '15px', fontFamily: 'Montserrat-Medium' }}>{getDateString(dateToCopyTo)}</div>
				<div style={{margin:'10px 0px'}}>
					<Select labelId="demo-simple-select-label" style={{ width: '150px', marginRight: '10px' }} required={true} name='sex' id="demo-simple-select" value={chosenCalendarCategory} onChange={handleCalendarCategoryChange}>
						<MenuItem value={"breakfast"}>Breakfast</MenuItem>
						<MenuItem value={"lunch"}>Lunch</MenuItem>
						<MenuItem value={"dinner"}>Dinner</MenuItem>
						<MenuItem value={"snack"}>Snack</MenuItem>

					</Select>
				</div>
				<button className="copy-meals-to-day-button" onClick={handleSubmitClick}> Copy meals </button>			</div>
		</>
	)
}
export default CopyMealsToDay;
