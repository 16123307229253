import React from "react";
import './plan_recipes.css'

import Button from '@material-ui/core/Button';
import { RecipeBooksContext } from "contexts/recipe_books_context";
import { FixedSizeList } from "react-window";
import { Dialog, TextField } from "@material-ui/core";
import CreateNewRecipe from "../create_new_recipe/create_new_recipe";
import { checkImageValidity, cleanUpNutrients, decimalToFraction, getMealNutrientAmount, recalculateCaloriesForRecipe } from "helpers/recipe_helpers";
import _ from "lodash";
import LoopIcon from '@material-ui/icons/Loop';
import FilterIcon from "images/filters_icon.png";
import Filters from "../filters/filters";
import { useDebouncedCallback } from 'use-debounce';
import { AuthContext } from 'contexts/auth_context';
import ModifyRecipe from "../modify_recipe/modify_recipe";
import MealDetailsCard from "../meal_details_card/meal_details_card";
import { config } from "Constants";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import CopyRecipeDialog from "../modify_recipe/copy_recipe_dialog";
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import axios from "axios";
import AddRecipeToPlan from "../add_recipe_to_plan/add_recipe_to_plan";
import Chip from '@material-ui/core/Chip';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useHistory } from 'react-router-dom';
import ExpandedRecipesView from "../expanded_recipes_view/expanded_recipes_view";

const Row = ({ data, index, style }) => {
    let meal = data.userFilteredRecipes ? data.userFilteredRecipes[index] : data.biteTrackerFilteredRecipes ? data.biteTrackerFilteredRecipes[index] : data.webFilteredRecipes[index]
    let handleRecipeAddClick = data.handleRecipeAddClick ? data.handleRecipeAddClick : data.handleWebRecipeAddClick
    let getCustomRecipeBookId = data.getCustomRecipeBookId
    let handleEditRecipeClick = data.handleEditRecipeClick
    let handleRecipeViewClick = data.handleRecipeViewClick ? data.handleRecipeViewClick : data.handleWebRecipeViewClick
    let handleRecipeCopyClick = data.handleRecipeCopyClick ? data.handleRecipeCopyClick : data.handleWebRecipeCopyClick

    let pictureURL = meal.picture.replace("https://storage.googleapis.com/thymeless-upload-storage/", "https://ik.imagekit.io/hk6gff5az/tr:w-210,h-210/")

    return (
        <div key={`calendar-add-menu-meal-row-${meal.id}`} style={style} className="plan-recipes-row" >
            <img className="plan-recipes-thumbnail" value={meal.id ? meal.id : meal.spoonacularRecipeId} onClick={handleRecipeViewClick} src={pictureURL} alt={meal.name} />
            <div className="plan-recipes-info">
                <div className="plan-recipes-name-outer">
                    <div onClick={handleRecipeViewClick} value={meal.id ? meal.id : meal.spoonacularRecipeId} className="plan-recipes-name">{meal.name}</div>
                </div>
                {(!!meal.aggregateLikes && !meal.recipeBooks) && <div className="plan-recipes-likes"> {meal.aggregateLikes} like{meal.aggregateLikes > 1 && "s"}</div>}
                {getMealNutrientAmount(meal, "Calories") > 0 && <div className="plan-recipes-nutrition">
                    <span className="caloric-chip caloric-chip-calories">{getMealNutrientAmount(meal, "Calories")} Cals</span>
                    <span className="caloric-chip caloric-chip-protein">{getMealNutrientAmount(meal, "Protein")}g</span>
                    <span className="caloric-chip caloric-chip-carbs">{getMealNutrientAmount(meal, "Carbohydrates")}g</span>
                    <span className="caloric-chip caloric-chip-fats">{getMealNutrientAmount(meal, "Fat")}g</span>
                </div>}
                <div>
                    <button className="plan-recipes-add-button" variant="contained" color="primary" value={meal.id ? meal.id : meal.spoonacularRecipeId} onClick={handleRecipeAddClick}>Add</button>
                    {(meal.recipeBooks && meal.recipeBooks.includes(getCustomRecipeBookId())) ? <button className="plan-recipes-add-button" style={{ background: 'rgb(129 197 36)' }} variant="contained" color="primary" value={meal.id} onClick={handleEditRecipeClick}>Edit</button> :
                        <button className="plan-recipes-add-button" style={{ background: '#E6EFDA', color: '#5e8b20' }} variant="contained" color="primary" value={meal.id ? meal.id : meal.spoonacularRecipeId} onClick={handleRecipeCopyClick}>Save</button>}
                </div>
            </div>
        </div>
    )
};


const PlanRecipes = ({ setShowIngredientsChip, setShowWeeklyPlanChip }) => {
    const recipes = React.useContext(RecipeBooksContext).recipeList;
    const history = useHistory();

    const [recipeFilterText, setRecipeFilterText] = React.useState("")
    const [showFilters, setShowFilters] = React.useState(false)
    const [filters, setFilters] = React.useState({})
    const [mealCategoriesFiltered, setMealCategoriesFiltered] = React.useState("")
    const [recipeBooksFiltered, setRecipeBooksFiltered] = React.useState("")
    const [timeFiltered, setTimeFiltered] = React.useState([])
    const { currentUser } = React.useContext(AuthContext)
    const getCustomRecipeBookId = React.useContext(RecipeBooksContext).getCustomRecipeBookId
    const [recipeBeingModified, setRecipeBeingModified] = React.useState(null)
    const [recipeBeingCopied, setRecipeBeingCopied] = React.useState(null)
    const [recipeBeingAdded, setRecipeBeingAdded] = React.useState(null)
    const [recipeBeingViewed, setRecipeBeingViewed] = React.useState(null)
    const [showUserRecipes, setShowUserRecipes] = React.useState(true)
    const [showBiteTrackerRecipes, setShowBiteTrackerRecipes] = React.useState(true)
    const [showWebRecipes, setShowWebRecipes] = React.useState(true)
    const myRecipesRef = React.useRef(null);
    const [webRecipeList, setWebRecipeList] = React.useState([])
    const [webRecipesLoading, setWebRecipesLoading] = React.useState(false)
    const [biteTrackerRecipesFromSearch, setBiteTrackerRecipesFromSearch] = React.useState([])
    const [showExpandedView, setShowExpandedView] = React.useState(false);
    const [expandedViewRecipes, setExpandedViewRecipes] = React.useState(null);
    const [expandedViewProps, setExpandedViewProps] = React.useState(null);
    const [biteTrackerRecipesLoading, setBiteTrackerRecipesLoading] = React.useState(false)

    React.useEffect(() => {
        if (recipeFilterText !== "") {
            setWebRecipeList([]);
            setBiteTrackerRecipesFromSearch([]);
            setWebRecipesLoading(true);
            setBiteTrackerRecipesLoading(true);

            const searchRecipes = setTimeout(async () => {
                const resp = await axios.get('/recipe/search-v2/' + recipeFilterText);
                
                if (resp.data && resp.data.BTRecipes) {
                    setBiteTrackerRecipesFromSearch(resp.data.BTRecipes);
                }
                setBiteTrackerRecipesLoading(false);

                let webRecipes = [];
                if (resp.data && resp.data.webRecipes) {
                    for (let webRecipe of resp.data.webRecipes) {
                        if (webRecipe.analyzedInstructions.length === 0) { continue; }
                        if (webRecipe.servings > 8) { continue; }
                        let doesImageWork = await checkImageValidity(webRecipe.image);
                        webRecipes.push({
                            name: webRecipe.title, spoonacularRecipeId: webRecipe.id, description: 'From ' + webRecipe.sourceName + ' - designed for ' + webRecipe.servings + ' portion(s)', analyzedInstructions: webRecipe.analyzedInstructions[0],
                            nutrition: webRecipe.nutrition, type: 'web-recipe', picture: doesImageWork ? webRecipe.image : config.basePictureURL, aggregateLikes: webRecipe.aggregateLikes,
                            ingredients: webRecipe.extendedIngredients, portion: webRecipe.servings, tags: [], categories: [], readyInMinutes: webRecipe.readyInMinutes
                        });
                    }
                }

                setWebRecipeList(webRecipes);
                setWebRecipesLoading(false);
            }, 500);

            return () => clearTimeout(searchRecipes);
        }
        else {
            setWebRecipesLoading(false);
            setBiteTrackerRecipesLoading(false);
            setWebRecipeList([]);
            setBiteTrackerRecipesFromSearch([]);
        }
    }, [recipeFilterText]);

    const scrollToTopOfMyRecipes = () => {
        myRecipesRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest'
        });

        setShowUserRecipes(true)
    };

    const toggleShowUserRecipes = () => {
        setShowUserRecipes(!showUserRecipes)
    }

    const toggleShowWebRecipes = () => {
        setShowWebRecipes(!showWebRecipes)
    }

    const toggleShowBiteTrackerRecipes = () => {
        setShowBiteTrackerRecipes(!showBiteTrackerRecipes)
    }

    const handleExitClick = () => {
        setRecipeBeingModified(null)
    }

    const handleViewingExitClick = () => {
        setRecipeBeingViewed(null)
    }

    const handleEditRecipeClick = (e) => {
        let foundRecipe = recipes.find(r => r.id === e.currentTarget.value)
        setRecipeBeingModified(foundRecipe)
    }

    const handleRecipeViewClick = (e) => {
        let foundRecipe = recipes.find(r => r.id === e.currentTarget.getAttribute('value'))
        console.log('foundRecipe', foundRecipe)
        setRecipeBeingViewed(_.cloneDeep(foundRecipe))
    }

    const handleRecipeCopyClick = (e) => {
        let foundRecipe = recipes.find(r => r.id === e.currentTarget.value)
        setRecipeBeingCopied(foundRecipe)
    }

    const handleRecipeAddClick = (e) => {
        let foundRecipe = recipes.find(r => r.id === e.currentTarget.value)
        setRecipeBeingAdded(foundRecipe)
    }

    const handleWebRecipeViewClick = (e) => {
        let foundRecipe = webRecipeList.find(r => r.spoonacularRecipeId === parseInt(e.currentTarget.getAttribute('value')))
        for (let ingredient of foundRecipe.ingredients) {
            ingredient.selectedUnit = ingredient.unit
            ingredient.possibleUnits = [ingredient.unit]
            ingredient.amountString = (Math.round(ingredient.amount * 100) / 100).toString()
        }
        setRecipeBeingViewed(foundRecipe)
    }

    const handleWebRecipeCopyClick = (e) => {
        let foundRecipe = webRecipeList.find(r => r.spoonacularRecipeId === parseInt(e.currentTarget.value))
        setRecipeBeingCopied(foundRecipe)
    }

    const handleWebRecipeAddClick = (e) => {
        let foundRecipe = webRecipeList.find(r => r.spoonacularRecipeId === parseInt(e.currentTarget.value))
        setRecipeBeingAdded(foundRecipe)
    }

    const handleEditRecipeClickInDetailsCard = (recipe) => {
        setTimeout(() => {
            setRecipeBeingViewed(null)
        }, 150)
        setRecipeBeingModified(recipe)
    }

    const setRecipeBeingViewedPortion = (portion) => {

        let recipeClone = _.cloneDeep(recipeBeingViewed)

        //with ingredients
        if (recipeClone.portion) {
            for (let ingredient of recipeClone.ingredients) {
                ingredient.amountString = decimalToFraction(ingredient.amount * portion / recipeClone.portion)
                ingredient.amount = ingredient.amount * portion / recipeClone.portion
            }
        }
        else {
            for (let ingredient of recipeClone.ingredients) {
                ingredient.amountString = decimalToFraction(ingredient.amount * portion)
                ingredient.amount = ingredient.amount * portion
            }
        }
        recipeClone = recalculateCaloriesForRecipe(recipeClone)
        setRecipeBeingViewed({ ...recipeClone, portion: portion })
    }

    const debouncedLogFilterEvent = useDebouncedCallback(
        async (filteredText) => {
            try {
                if (filteredText.length >= 3) {
                    FirebaseAnalytics.logEvent({
                        name: "filtered_recipe",
                        params: { filtered_text: filteredText, currentUser: currentUser.uid }
                    })
                }
            }
            catch (error) {
                console.log(error)
            }
        },
        1000
    );

    const cleanUpMeasures = (ingredient, nutrition) => {
        let unitsToGrams = {
            'Tbsps': 14.175,
            'tbsp': 14.175,
            'Tbsp': 14.175,
            'tbsps': 14.175,
            'cups': 340,
            'tsps': 5.7,
            'tsp': 5.7,
            'cloves': 15,
            'large': 250,
            'medium': 200,
            'small': 150,
            'oz': 28.35,
            'packets': 10,
            'handfuls': 30
        }
        let foundUnit = false

        for (let unit of Object.keys(unitsToGrams)) {
            if (ingredient.measures.metric.unitShort === unit) {
                nutrition.weightPerServing = { amount: unitsToGrams[unit], unit: 'g' }
                foundUnit = true
                break
            }
            if (ingredient.measures.us.unitShort === unit) {
                nutrition.weightPerServing = { amount: unitsToGrams[unit], unit: 'g' }
                foundUnit = true
                break
            }
        }
        if (!foundUnit) {
            console.log("units not found: ", ingredient.measures.metric.unitShort, ingredient.measures.us.unitShort)
            nutrition.weightPerServing = { amount: 0.001, unit: 'g' }
        }
    }

    const prepareWebRecipe = async (recipe) => {
        let recipeClone = _.cloneDeep(recipe)
        const response = await axios.get('/recipe/ingredientNutrition/' + recipeClone.spoonacularRecipeId);
        for (let ingredientIndex in response.data.ingredients) {
            let extendedIngredient = recipeClone.ingredients[ingredientIndex]
            let ingredientNutrition = response.data.ingredients[ingredientIndex]

            extendedIngredient.selectedUnit = extendedIngredient.unit
            extendedIngredient.possibleUnits = [extendedIngredient.unit]
            extendedIngredient.amountString = (Math.round(extendedIngredient.amount * 100) / 100).toString()

            cleanUpMeasures(extendedIngredient, ingredientNutrition)

            ingredientNutrition.nutrients = cleanUpNutrients(ingredientNutrition.nutrients)

            let possibleUnits = ["g"]

            if (extendedIngredient.unit !== "g") {
                possibleUnits.push(extendedIngredient.unit)
            }
            extendedIngredient.possibleUnits = possibleUnits
            extendedIngredient.nutrition = ingredientNutrition
        }
        return recipeClone
    }

    const addMealFromDetailsCard = async (recipe) => {
        setRecipeBeingAdded(recipe)
    }

    const handleRecipeFilterTextChange = (event) => {
        setRecipeFilterText(event.target.value)
        debouncedLogFilterEvent(event.target.value)
    }

    const toggleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    let userFilteredRecipes = []
    let biteTrackerFilteredRecipes = []
    let biteTrackerFilteredRecipesDescriptionMatch = []
    let webFilteredRecipes = webRecipeList
    let foundUserRecipe = false

    let filtersApplied = []

    if (recipes === null) {
        return <></>
    } else {
        for (let filterValue in filters) {
            if (filters[filterValue]) {
                filtersApplied.push(filterValue)
            }
        }

        if (mealCategoriesFiltered.length !== 0) {
            filtersApplied.push(mealCategoriesFiltered)
        }

        if (recipeBooksFiltered.length !== 0) {
            filtersApplied.push(recipeBooksFiltered)
        }

        for (let meal of recipes) {
            if (filtersApplied.length === 0 && mealCategoriesFiltered.length === 0 && recipeBooksFiltered.length === 0 &&
                recipeFilterText === "" && timeFiltered.length === 0 && timeFiltered.some(range => 
                    meal.readyInMinutes >= range[0] && meal.readyInMinutes <= range[1]
                )) {
                if (meal.recipeBooks[0] === config.baseRecipeBookId) {
                    biteTrackerFilteredRecipes.push(meal)
                } else {
                    userFilteredRecipes.push(meal)
                    foundUserRecipe = true
                }
            } else {
                let tags = meal.tags.concat(meal.categories).concat(meal.recipeBooks)
                let allTagsFound = true

                for (let filterValue in filtersApplied) {
                    if (tags === null) {
                        allTagsFound = false
                        break
                    }
                    if (!tags.includes(filtersApplied[filterValue])) {
                        allTagsFound = false
                    }
                }
                if (allTagsFound) {
                    if (!meal.readyInMinutes || timeFiltered.length === 0 || timeFiltered.some(range => 
                        meal.readyInMinutes >= range[0] && meal.readyInMinutes <= range[1]
                    ))
                        if (meal.name.toLowerCase().includes(recipeFilterText.toLowerCase())) {
                            if (meal.recipeBooks[0] === config.baseRecipeBookId) {
                                biteTrackerFilteredRecipes.push(meal)
                                continue;
                            } else {
                                foundUserRecipe = true
                                userFilteredRecipes.push(meal)
                            }
                        }
                    if (meal.description.toLowerCase().includes(recipeFilterText.toLowerCase())) {
                        if (meal.recipeBooks[0] === config.baseRecipeBookId) {
                            biteTrackerFilteredRecipesDescriptionMatch.push(meal)
                        }
                    }
                }
            }
        }
    }

    for (let biteTrackerRecipeFromSearch of biteTrackerRecipesFromSearch) {
        let foundRecipe = false
        for (let biteTrackerFilteredRecipe of biteTrackerFilteredRecipes) {
            if (biteTrackerRecipeFromSearch.name === biteTrackerFilteredRecipe.name) {
                foundRecipe = true
            }
        }
        if (!foundRecipe) {
            biteTrackerFilteredRecipes.push(biteTrackerRecipeFromSearch)
        }
    }

    for (let biteTrackerRecipeDescriptionMatched of biteTrackerFilteredRecipesDescriptionMatch) {
        let foundRecipe = false
        for (let biteTrackerFilteredRecipe of biteTrackerFilteredRecipes) {
            if (biteTrackerRecipeDescriptionMatched.name === biteTrackerFilteredRecipe.name) {
                foundRecipe = true
            }
        }
        if (!foundRecipe) {
            biteTrackerFilteredRecipes.push(biteTrackerRecipeDescriptionMatched)
        }
    }

    const getTimeRangeLabel = (range) => {
        if (range[0] === 0 && range[1] === 15) return "Quick (0-15m)";
        if (range[0] === 15 && range[1] === 30) return "15-30 mins";
        if (range[0] === 30 && range[1] === 60) return "30-60 mins";
        if (range[0] === 60 && range[1] === 300) return "60+ mins";
        return `${range[0]}-${range[1]} mins`;
    };

    filtersApplied = filtersApplied.filter(filter => filter !== mealCategoriesFiltered)

    const handleShowExpandedView = (recipes, recipesCollectionName) => {
        setExpandedViewProps({ 
            recipesCollectionName,
            // Only keep non-filter related props here
        });
        setShowExpandedView(true);
    };

    const dialogsJSX = (
        <>
            <Dialog maxWidth={'xl'} open={Boolean(recipeBeingModified)}>
                {<ModifyRecipe 
                    recipeBook={getCustomRecipeBookId} 
                    recipeBeingModified={recipeBeingModified} 
                    close={handleExitClick} 
                    creatingNewRecipe={false} 
                    setCreatingNewRecipe={null} 
                    disableCopy={false} 
                />}
            </Dialog>
            <Dialog open={Boolean(recipeBeingViewed)} maxWidth={'xl'}>
                <MealDetailsCard 
                    isReadOnly={true} 
                    handleEditRecipeClickInDetailsCard={handleEditRecipeClickInDetailsCard} 
                    prepareWebRecipe={prepareWebRecipe} 
                    addToPlanEnabled={true} 
                    portion={recipeBeingViewed?.portion ? recipeBeingViewed.portion : 1} 
                    handleExitClick={handleViewingExitClick} 
                    meal={recipeBeingViewed} 
                    setPortion={setRecipeBeingViewedPortion} 
                    customAddMealFunction={addMealFromDetailsCard} 
                />
            </Dialog>
            <Dialog 
                open={Boolean(recipeBeingAdded)} 
                onClose={() => { setRecipeBeingAdded(null) }} 
                maxWidth={'xl'}
            >
                <AddRecipeToPlan 
                    setShowIngredientsChip={setShowIngredientsChip} 
                    setShowWeeklyPlanChip={setShowWeeklyPlanChip} 
                    recipe={recipeBeingAdded} 
                    exitDialog={() => { 
                        setRecipeBeingAdded(null); 
                        setRecipeBeingViewed(null) 
                    }} 
                    prepareWebRecipe={prepareWebRecipe} 
                />
            </Dialog>
            <Dialog open={showFilters} onClose={toggleShowFilters} maxWidth={'xl'}>
                <div style={{ display: 'flex', padding: '15px 15px', maxWidth: '400px' }} className="plan-recipes-filters">
                    <Filters 
                        addTime={true} 
                        addComplexity={true} 
                        removeRecipeBooks={true} 
                        filters={filters} 
                        setFilters={setFilters} 
                        timeFiltered={timeFiltered} 
                        setTimeFiltered={setTimeFiltered}
                        mealCategoriesFiltered={mealCategoriesFiltered} 
                        setMealCategoriesFiltered={setMealCategoriesFiltered} 
                        recipeBooksFiltered={recipeBooksFiltered}
                        setRecipeBooksFiltered={setRecipeBooksFiltered} 
                    />
                </div>
            </Dialog>
            <CopyRecipeDialog 
                setRecipeFilterText={setRecipeFilterText} 
                prepareWebRecipe={prepareWebRecipe} 
                attemptedCopy={Boolean(recipeBeingCopied)} 
                setAttemptedCopy={(value) => { setRecipeBeingCopied(null) }} 
                recipe={recipeBeingCopied} 
                scrollToTopOfRecipeBookList={scrollToTopOfMyRecipes} 
            />
        </>
    );

    if (showExpandedView && expandedViewProps) {
        return (
            <>
                <ExpandedRecipesView 
                    {...expandedViewProps}
                    recipes={
                        expandedViewProps.recipesCollectionName === "BiteTracker Basics" 
                            ? biteTrackerFilteredRecipes 
                            : userFilteredRecipes
                    }
                    recipeFilterText={recipeFilterText}
                    handleRecipeFilterTextChange={(event) => {
                        setRecipeFilterText(event.target.value);
                        debouncedLogFilterEvent(event.target.value);
                    }}
                    showFilters={showFilters}
                    toggleShowFilters={toggleShowFilters}
                    filters={filters}
                    setFilters={setFilters}
                    timeFiltered={timeFiltered}
                    setTimeFiltered={setTimeFiltered}
                    mealCategoriesFiltered={mealCategoriesFiltered}
                    setMealCategoriesFiltered={setMealCategoriesFiltered}
                    recipeBooksFiltered={recipeBooksFiltered}
                    setRecipeBooksFiltered={setRecipeBooksFiltered}
                    onBack={() => setShowExpandedView(false)}
                    handleRecipeViewClick={handleRecipeViewClick}
                    handleRecipeAddClick={handleRecipeAddClick}
                    handleRecipeCopyClick={handleRecipeCopyClick}
                    handleEditRecipeClick={handleEditRecipeClick}
                    currentUser={currentUser}
                    getCustomRecipeBookId={getCustomRecipeBookId}
                />
                {dialogsJSX}
            </>
        );
    }

    return (
        <>
            <div className="plan-recipes-container">

                <div className="plan-recipes-content">
                    <div className="plan-recipes-top-image">
                        <div className="plan-recipes-top-image-tint">
                            <div className="plan-recipes-top-image-text">Find a Recipe</div>
                            <div style={{ display: 'flex', background: 'white', width: '300px', borderRadius: '20px', padding: '0px 5px' }}>
                                <button className="plan-recipes-filter-button" onClick={toggleShowFilters}>
                                    <img src={FilterIcon} alt="filter icon" style={{ width: '18px', marginTop: '4px', marginRight: '4px', filter: 'invert(47%) sepia(3%) saturate(6%) hue-rotate(344deg) brightness(100%) contrast(88%)'  }} />
                                </button>
                                <TextField className="plan-recipes-filter-input" InputProps={{ disableUnderline: true }} placeholder='Filter and search recipes...' label="" value={recipeFilterText} onChange={handleRecipeFilterTextChange} />
                            </div>
                            
                            {(filtersApplied.length > 0 || mealCategoriesFiltered || recipeBooksFiltered || timeFiltered.length > 0) && (
                                <div style={{ 
                                    display: 'flex', 
                                    flexWrap: 'wrap', 
                                    gap: '8px', 
                                    marginTop: '12px',
                                    maxWidth: '600px',
                                    justifyContent: 'center'
                                }}>
                                    {timeFiltered.map((range, index) => (
                                        <Chip
                                            key={`time-${index}`}
                                            icon={<AccessTimeIcon style={{fontSize: 16}}/>}
                                            label={getTimeRangeLabel(range)}
                                            onDelete={() => {
                                                const newTimeFiltered = timeFiltered.filter((_, i) => i !== index);
                                                setTimeFiltered(newTimeFiltered);
                                            }}
                                            style={{
                                                background: '#E8F5E9',
                                                color: '#4A7018',
                                                borderRadius: '16px',
                                                fontSize: '12px',
                                                height: '28px'
                                            }}
                                        />
                                    ))}
                                    {filtersApplied.map((filter, index) => (
                                        <Chip
                                            key={`filter-${index}`}
                                            label={filter}
                                            onDelete={() => {
                                                const newFilters = {...filters};
                                                newFilters[filter] = false;
                                                setFilters(newFilters);
                                            }}
                                            style={{
                                                background: '#E8F5E9',
                                                color: '#4A7018',
                                                borderRadius: '16px',
                                                fontSize: '12px',
                                                height: '28px'
                                            }}
                                        />
                                    ))}
                                    {mealCategoriesFiltered && (
                                        <Chip
                                            label={mealCategoriesFiltered}
                                            onDelete={() => setMealCategoriesFiltered("")}
                                            style={{
                                                background: '#E8F5E9',
                                                color: '#4A7018',
                                                borderRadius: '16px',
                                                fontSize: '12px',
                                                height: '28px'
                                            }}
                                        />
                                    )}
                                    {recipeBooksFiltered && (
                                        <Chip
                                            label={recipeBooksFiltered}
                                            onDelete={() => setRecipeBooksFiltered("")}
                                            style={{
                                                background: '#E8F5E9',
                                                color: '#4A7018',
                                                borderRadius: '16px',
                                                fontSize: '12px',
                                                height: '28px'
                                            }}
                                        />
                                    )}
                                    {(filtersApplied.length > 0 || mealCategoriesFiltered || recipeBooksFiltered || timeFiltered.length > 0) && (
                                        <Chip
                                            label="Clear all"
                                            onClick={() => {
                                                setFilters({});
                                                setMealCategoriesFiltered("");
                                                setRecipeBooksFiltered("");
                                                setTimeFiltered([]);
                                            }}
                                            style={{
                                                background: '#F5F5F5',
                                                color: '#616161',
                                                borderRadius: '16px',
                                                fontSize: '12px',
                                                height: '28px',
                                                cursor: 'pointer'
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div class="break"></div>


                    <div className="plan-recipes-section" style={{ width: window.innerWidth > 1340 ? '1330px' : '100%' }}>
                        <div ref={myRecipesRef} className="plan-recipes-section-header" style={{ marginBottom: '6px' }}><div >My Recipes </div>
                            <div style={{ marginRight: '0px', marginLeft: '8px' }}><CreateNewRecipe setRecipeFilterText={setRecipeFilterText} /></div>
                            <div style={{ marginRight: 'auto', marginLeft: '5px' }}><CreateNewRecipe setRecipeFilterText={setRecipeFilterText} extractRecipe={true} /></div>

                        </div>

                        {recipes.length === 0 && <div className="plan-recipes-loading-message">Loading recipes <LoopIcon style={{ animation: 'spin 2s linear infinite', marginLeft: '5px', color: 'grey', fontSize: '16px' }} /></div>}

                        {!foundUserRecipe && showUserRecipes && recipes.length !== 0 && <div className="plan-recipes-no-recipes-message">
                            No recipes found. Create recipes or save BiteTracker/Web recipes
                        </div>}
                        {showUserRecipes && foundUserRecipe &&
                            <FixedSizeList
                                height={320}
                                width={window.innerWidth > 1340 ? 1330 : window.innerWidth}
                                itemCount={userFilteredRecipes.length}
                                itemSize={190}
                                layout="horizontal"
                                itemData={{
                                    userFilteredRecipes,
                                    handleRecipeAddClick,
                                    getCustomRecipeBookId,
                                    handleEditRecipeClick,
                                    handleRecipeViewClick,
                                }}
                            >
                                {Row}
                            </FixedSizeList>
                        }
                    </div>
                    <div class="break"></div>

                    <div className="plan-recipes-section" style={{ width: window.innerWidth > 1340 ? '1330px' : '100%' }}>
                        <div className="plan-recipes-section-header" style={{ marginBottom: '0px', paddingTop:'0px', marginTop:'-4px'}}>
                            <div> BiteTracker Basics</div>
                            <Button 
                                className="plan-recipes-see-all" 
                                onClick={() => handleShowExpandedView(biteTrackerFilteredRecipes, "BiteTracker Basics")}
                            >
                                See All
                            </Button>
                        </div>
                        {biteTrackerRecipesLoading && <div className="plan-recipes-loading-message" style={{marginTop:'0px'}}>Loading recipes <LoopIcon style={{ animation: 'spin 2s linear infinite', marginLeft: '5px', color: 'grey', fontSize: '16px' }} /></div>}

                        {recipes.length === 0 && <div className="plan-recipes-loading-message" style={{marginTop:'0px'}}>Loading recipes <LoopIcon style={{ animation: 'spin 2s linear infinite', marginLeft: '5px', color: 'grey', fontSize: '16px' }} /></div>}

                        {showBiteTrackerRecipes && !biteTrackerRecipesLoading && biteTrackerFilteredRecipes.length > 0 &&
                            <FixedSizeList
                                height={320}
                                width={window.innerWidth > 1340 ? 1330 : window.innerWidth}
                                itemCount={biteTrackerFilteredRecipes.length}
                                itemSize={190}
                                layout="horizontal"
                                style={{ margin: '0px auto' }}
                                itemData={{
                                    biteTrackerFilteredRecipes,
                                    handleRecipeAddClick,
                                    getCustomRecipeBookId,
                                    handleRecipeViewClick,
                                    handleRecipeCopyClick
                                }}
                            >
                                {Row}
                            </FixedSizeList>
                        }
                        {biteTrackerFilteredRecipes.length === 0 && !biteTrackerRecipesLoading && recipes.length !== 0 && <div className="plan-recipes-no-recipes-message">
                            No recipes found for search term
                        </div>}
                    </div>
                    <div class="break"></div>
                    <div className="plan-recipes-section" style={{ width: window.innerWidth > 1340 ? '1330px' : '100%' }}>
                        <div className="plan-recipes-section-header" style={{ marginBottom: '8px' }}>Web Recipes
                        </div>
                        {webRecipesLoading && <div className="plan-recipes-loading-message">Loading recipes <LoopIcon style={{ animation: 'spin 2s linear infinite', marginLeft: '5px', color: 'grey', fontSize: '16px' }} /></div>}
                        {webRecipeList.length === 0 && !webRecipesLoading && showWebRecipes && <div className="plan-recipes-no-recipes-message"> Type in search box to see web recipes</div>}

                        {showWebRecipes && webRecipeList.length !== 0 && !webRecipesLoading &&
                            <FixedSizeList
                                height={340}
                                width={window.innerWidth > 1340 ? 1330 : window.innerWidth}
                                itemCount={webRecipeList.length}
                                itemSize={190}
                                layout="horizontal"
                                itemData={{
                                    webFilteredRecipes,
                                    handleWebRecipeAddClick,
                                    getCustomRecipeBookId,
                                    handleWebRecipeViewClick,
                                    handleWebRecipeCopyClick
                                }}
                            >
                                {Row}
                            </FixedSizeList>
                        }
                    </div>
                </div>
            </div >
            {dialogsJSX}
        </>
    )
}

export default PlanRecipes;
