import './calendar_page.css';

import React from 'react';

import Calendar from 'components/shared/calendar/calendar'
import CalendarSideBar from 'components/shared/calendar_side_bar/calendar_side_bar'

import { CartContext } from 'contexts/cart_context';


import { UserContext } from 'contexts/user_context';
import CalendarMealCarousel from 'components/shared/calendar_meal_carousel/calendar_meal_carousel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Button, Dialog } from '@material-ui/core';
import SelectFoodVendor from 'components/shared/select_food_vendor/select_food_vendor';
import MobileSettingsButton from 'components/shared/mobile_settings_button/mobile_settings_button';
import { useResizeDetector } from 'react-resize-detector';
import { MenuContext } from 'contexts/menu_context';
import { AuthContext } from 'contexts/auth_context';
import OnboardingWizard from 'components/shared/onboarding_wizard/onboarding_wizard';
import { Capacitor } from '@capacitor/core';


const CalendarPage = () => {
  const [isCarouselCollapsed, setCarouselCollapsed] = React.useState(window.innerWidth < 500 || window.innerHeight < 500);
  const { userObject, setUserObject } = React.useContext(UserContext);
  const { isNewlyWrittenUser } = React.useContext(AuthContext);
  const menuObject = React.useContext(MenuContext).menuObject
  const [vendorDialogOpen, setVendorDialogOpen] = React.useState(false)
  const getCurrentDeliveryDate = React.useContext(MenuContext).getCurrentDeliveryDate
  const loadUsers = React.useContext(CartContext).loadUsers;
  const [filters, setFilters] = React.useState({})
  const [mealCategoriesFiltered, setMealCategoriesFiltered] = React.useState("")
  const [recipeBooksFiltered, setRecipeBooksFiltered] = React.useState("")
  const [isSideBarCollapsed, setIsSideBarCollapsed] = React.useState(true)
  const { width, ref } = useResizeDetector();
  let isSideBarDisabled = width <= 760
  let isCarouselDisabled = true
  let sideBarWidth = isSideBarDisabled ? 0 : isSideBarCollapsed ? 45 : 275

  const shouldShowOnboarding = userObject && 
    (userObject.loaded || isNewlyWrittenUser) && 
    (!userObject.onboardingStatus || !userObject.onboardingStatus.isComplete);

  function toggleIsSideBarCollapsed() {
    setIsSideBarCollapsed(!isSideBarCollapsed)
  }

  const handleVendorDialogExitClick = () => {
    setVendorDialogOpen(false)
  }



  if ((userObject.foodVendor === "" || !getCurrentDeliveryDate()) && userObject.loaded && menuObject.loaded && !vendorDialogOpen) {
    setVendorDialogOpen(true)
  }





  return (
    <>
      <div ref={ref} className="calendar-page-outer-frame">
        <div className="calendar-page-middle-frame" style={Capacitor.getPlatform() === "web" ? { height: 'calc(100% - 60px)' } : {}}>
          {!isSideBarCollapsed && !isSideBarDisabled && <CalendarSideBar setVendorDialogOpen={setVendorDialogOpen} toggleIsSideBarCollapsed={toggleIsSideBarCollapsed} />}
          {isSideBarCollapsed && !isSideBarDisabled && <div className="calendar-page-side-bar-collapsed"> <Button className="side-bar-expand-button" onClick={toggleIsSideBarCollapsed}>
            <KeyboardArrowDownIcon />Edit macros
          </Button></div>}

          <div className="calendar-page-inner-frame" style={{ maxWidth: 'calc(100% - ' + sideBarWidth + 'px)' }}>

            <Calendar isCarouselDisabled={isCarouselDisabled} filters={filters} mealCategoriesFiltered={mealCategoriesFiltered} />

          </div>

        </div>
        {!isCarouselDisabled && <CalendarMealCarousel isFiltersCollapsed={isSideBarCollapsed} isFiltersHidden={isSideBarDisabled} isCollapsed={isCarouselCollapsed} setCollapsed={setCarouselCollapsed} filters={filters} setFilters={setFilters} mealCategoriesFiltered={mealCategoriesFiltered}
          setMealCategoriesFiltered={setMealCategoriesFiltered} recipeBooksFiltered={recipeBooksFiltered} setRecipeBooksFiltered={setRecipeBooksFiltered} />}
        {isSideBarDisabled && Capacitor.getPlatform() !== "web" && <MobileSettingsButton isCalendar={true} />}


      </div>
      <Dialog open={vendorDialogOpen} onClose={handleVendorDialogExitClick} maxWidth={false} >
        <SelectFoodVendor afterSelect={handleVendorDialogExitClick} />
      </Dialog>

      <Dialog open={shouldShowOnboarding}>
        <OnboardingWizard 
        />
      </Dialog>

    </>
  )
}

export default CalendarPage;