// CalendarCopyFromHistory.js

import React, { useState, useContext } from 'react';
import { Dialog, Button, Checkbox, Select, MenuItem } from '@material-ui/core';
import { CartContext, getMealDateString } from 'contexts/cart_context';
import './calendar_copy_from_history.css';
import { CloseRounded } from '@material-ui/icons';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import AddFromHistoryIcon from 'images/add-from-history-icon.png';

const CalendarCopyFromHistory = (props) => {
    const [open, setOpen] = useState(false);
    const [selectedMeals, setSelectedMeals] = useState({});
    const [selectedCategory, setSelectedCategory] = useState(props.calendarCategory);
    const cartContext = useContext(CartContext);
    const { cartObject, addMealsFromCopy } = cartContext;

    const handleOpen = (event) => {
        event.stopPropagation(); // Prevent click from propagating to parent components
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedMeals({});
        setSelectedCategory(props.calendarCategory); // Reset selected category
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        // Do not reset selected meals when category changes
    };

    // Get the current date to filter out future meals
    const currentDate = new Date(props.calendarDate);
    const userTimezoneOffset = currentDate.getTimezoneOffset() * 60000;
    const localCurrentDate = new Date(currentDate.getTime() - userTimezoneOffset);
    const currentDateStr = localCurrentDate.toISOString().split('T')[0];

    const thirtyDaysAgo = new Date(currentDate);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const localThirtyDaysAgo = new Date(thirtyDaysAgo.getTime() - thirtyDaysAgo.getTimezoneOffset() * 60000);
    const thirtyDaysAgoStr = localThirtyDaysAgo.toISOString().split('T')[0];

    // Get all past meals within the last 30 days
    let pastMeals = cartObject.filter((meal) => {
        const mealDateStr = getMealDateString(meal);
        return (
            mealDateStr >= thirtyDaysAgoStr &&
            mealDateStr < currentDateStr
        );
    });

    // Remove duplicates by meal content
    const uniqueMeals = [];
    const mealSignatures = new Set();

    pastMeals.forEach((meal) => {
        // Use meal.id or generate a unique signature
        const signature = meal.id || JSON.stringify({
            name: meal.name,
            ingredients: meal.ingredients,
            // Include other attributes that define a unique meal
        });

        if (!mealSignatures.has(signature)) {
            mealSignatures.add(signature);
            uniqueMeals.push({ ...meal, signature });
        }
    });

    // Sort past meals by most recent date
    uniqueMeals.sort(
        (a, b) => new Date(b.calendarDate) - new Date(a.calendarDate)
    );

    const handleMealSelection = (mealSignature) => {
        setSelectedMeals((prevState) => ({
            ...prevState,
            [mealSignature]: !prevState[mealSignature],
        }));
    };

    const handleCopyMeals = () => {
        const mealsToCopy = uniqueMeals.filter(meal => selectedMeals[meal.signature]);

        if (mealsToCopy.length > 0) {
            // Create new meal objects without mutating the originals
            const newMeals = mealsToCopy.map((meal) => {
                const newMeal = { ...meal };
                // Set both date formats
                newMeal.calendarDate = props.calendarDate; // keep for backwards compatibility
                newMeal.mealDate = getMealDateString({ calendarDate: props.calendarDate });
                newMeal.id = undefined; // Let addMealsFromCopy assign new IDs
                return newMeal;
            });

            FirebaseAnalytics.logEvent({
                name: 'added_meals_to_calendar_from_history',
            });

            addMealsFromCopy(newMeals, props.calendarDate, props.calendarCategory);
        }

        handleClose();
    };

    // Filter meals for the selected category for display purposes
    const filteredMeals = uniqueMeals.filter(meal => meal.calendarCategory === selectedCategory);

    // Render the icon with appropriate styling
    return (
        <div className="calendar-copy-from-history">
            <img
                src={AddFromHistoryIcon}
                alt="Copy from history"
                className="calendar-copy-from-history-icon"
                onClick={handleOpen}
                title="Add from history"
            />

            <Dialog 
                open={open} 
                onClose={handleClose} 
                maxWidth="md"
                classes={{ paper: "history-dialog-paper" }}
            >
                <div className="history-dialog-header">
                    <h3 className="history-dialog-title">Add From Recent History</h3>
                    <Button className="history-dialog-close-button" onClick={handleClose}>
                        <CloseRounded />
                    </Button>
                </div>

                <div className="history-dialog-filter">
                    <span>Showing 30 day history for</span>
                    <Select
                        value={selectedCategory}
                        onChange={handleCategoryChange}
                        className="history-category-select"
                    >
                        <MenuItem value="breakfast">Breakfast</MenuItem>
                        <MenuItem value="lunch">Lunch</MenuItem>
                        <MenuItem value="dinner">Dinner</MenuItem>
                        <MenuItem value="snack">Snack</MenuItem>
                    </Select>
                </div>

                <div className="history-dialog-content">
                    {filteredMeals.length === 0 ? (
                        <div className="history-empty-state">
                            No past meals found in the last 30 days
                        </div>
                    ) : (
                        <div className="meal-history-list">
                            {filteredMeals.map((meal) => (
                                <div 
                                    key={meal.signature} 
                                    className={`meal-history-item ${selectedMeals[meal.signature] ? 'selected' : ''}`}
                                    onClick={() => handleMealSelection(meal.signature)}
                                >
                                    <div className="meal-history-checkbox">
                                        <Checkbox
                                            checked={!!selectedMeals[meal.signature]}
                                            color="primary"
                                            className="history-checkbox"
                                        />
                                    </div>
                                    <div className="history-dialog-meal-image-wrapper">
                                        {meal.picture ? (
                                            <img
                                                src={meal.picture}
                                                alt={meal.name}
                                                className="history-dialog-meal-picture"
                                            />
                                        ) : (
                                            <div className="history-dialog-meal-placeholder"></div>
                                        )}
                                    </div>
                                    <div className="history-dialog-meal-info">
                                        <div className="history-dialog-meal-name">{meal.name}</div>
                                        <div className="history-dialog-meal-date">
                                            Last eaten on:{' '}
                                            {(() => {
                                                const dateStr = getMealDateString(meal);
                                                const date = new Date(dateStr + 'T00:00:00');
                                                const userTimezoneOffset = date.getTimezoneOffset() * 60000;
                                                const localDate = new Date(date.getTime() + userTimezoneOffset);
                                                return localDate.toLocaleDateString();
                                            })()}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="history-dialog-actions">
                    <Button 
                        className="history-cancel-button" 
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="history-submit-button"
                        onClick={handleCopyMeals}
                        disabled={Object.values(selectedMeals).filter(Boolean).length === 0}
                    >
                        Add Selected Meals
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};

export default CalendarCopyFromHistory;
