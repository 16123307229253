import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from 'contexts/user_context';
import './water_tracker_card.css';
import OpacityIcon from '@material-ui/icons/Opacity';
import WaterIcon from 'images/Water-Empty.png'
import WaterFilledIcon from 'images/Water-Filled.png'
import WaterHalfFilledIcon from 'images/Water-Half-Filled.png'

import Tooltip from '@material-ui/core/Tooltip';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Slider,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputAdornment,
  FormControl,
  InputLabel,
} from '@material-ui/core';

const WaterTrackerCard = ({ calendarDate }) => {
  const {
    userObject,
    getWaterIntake,
    setWaterIntake,
    getWaterGoalCups,
    getCupSizeOz,
    getCupSizeUnit,
    setWaterSettings,
  } = useContext(UserContext);

  const [cups, setCups] = useState(0);
  const [waterGoalCupsState, setWaterGoalCupsState] = useState(getWaterGoalCups());
  const [cupSizeState, setCupSizeState] = useState(getCupSizeOz());
  const [cupSizeUnitState, setCupSizeUnitState] = useState(getCupSizeUnit());
  const [allowHalfCupsState, setAllowHalfCupsState] = useState(userObject.allowHalfCups || false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  useEffect(() => {
    const initialCups = getWaterIntake(calendarDate);
    setCups(initialCups);
  }, [calendarDate, getWaterIntake]);

  useEffect(() => {
    setWaterGoalCupsState(getWaterGoalCups());
    setCupSizeState(getCupSizeOz());
    setCupSizeUnitState(getCupSizeUnit());
    setAllowHalfCupsState(userObject.allowHalfCups || false);
  }, [userObject.waterGoalCups, userObject.cupSizeOz, userObject.cupSizeUnit, userObject.allowHalfCups]);

  const handleDropClick = (index) => {
    const isFilled = index < Math.floor(cups);
    const isHalfFilled = allowHalfCupsState && (index === Math.floor(cups) && cups % 1 !== 0);
    let newCups = cups;

    // Handle the rightmost cup with tri-state behavior when half cups are enabled
    if (allowHalfCupsState && index === Math.floor(cups)) {
      if (isHalfFilled) {
        // If half filled, make it fully filled
        newCups = Math.floor(cups) + 1;
      } else if (isFilled) {
        // If already filled, clicking again should empty it
        newCups = Math.max(0, Math.floor(cups) - 1);
      } else {
        // If empty, make it half filled
        newCups = Math.floor(cups) + 0.5;
      }
    } else {
      // For all other cups, maintain the original behavior
      if (isFilled) {
        // If fully filled, reduce by 1
        newCups = Math.max(0, cups - 1);
      } else {
        // If empty, add a full cup
        newCups = Math.min(100, Math.floor(cups) + 1);
      }
    }

    setCups(newCups);
    setWaterIntake(calendarDate, newCups);
  };

  // Convert between oz and ml if needed
  const displayValue = (value) => {
    if (cupSizeUnitState === 'ml') {
      return Math.round(value * cupSizeState);
    }
    return value * cupSizeState;
  };

  // Get the water drop icon based on fill state
  const getWaterIcon = (index) => {
    if (index < Math.floor(cups)) {
      return WaterFilledIcon;
    } else if (allowHalfCupsState && index === Math.floor(cups) && cups % 1 !== 0) {
      return WaterHalfFilledIcon;
    } else {
      return WaterIcon;
    }
  };

  // Convert between oz and ml for display
  const convertUnitForDisplay = (value) => {
    if (cupSizeUnitState === 'ml' && value) {
      return Math.round(value);
    }
    return value;
  };

  // Handle cup size unit change
  const handleCupSizeUnitChange = (e) => {
    const newUnit = e.target.value;
    let newSize = cupSizeState;
    
    // Convert the value when changing units
    if (newUnit === 'ml' && cupSizeUnitState === 'oz') {
      // Convert oz to ml (1 oz ≈ 29.57 ml)
      newSize = Math.round(cupSizeState * 29.57);
    } else if (newUnit === 'oz' && cupSizeUnitState === 'ml') {
      // Convert ml to oz (1 ml ≈ 0.033814 oz)
      newSize = parseFloat((cupSizeState * 0.033814).toFixed(1));
    }
    
    setCupSizeState(newSize);
    setCupSizeUnitState(newUnit);
  };

  return (
    <div className="water-tracker-card">
      <div className="water-tracker-header">
        <div className="header-left">Water</div>
        <div className="header-center">
          {convertUnitForDisplay(displayValue(cups))} {cupSizeUnitState} / {convertUnitForDisplay(displayValue(waterGoalCupsState))} {cupSizeUnitState}
        </div>
        <div className="header-right">
          <MoreHorizIcon
            className="settings-icon"
            onClick={() => setSettingsOpen(true)}
            style={{ cursor: 'pointer', color: 'grey' }}
          />
        </div>
      </div>
      <div className="water-drops-container">
        {Array.from({ length: Math.max(Math.ceil(cups), waterGoalCupsState) }, (_, index) => (
          <Tooltip
            key={index}
            title={
              (allowHalfCupsState && index === Math.floor(cups) - 1 && cups % 1 === 0 && cups > 0)
                ? 'Click to empty cup'
                : (index < Math.floor(cups))
                  ? 'Click to remove one cup' 
                  : (allowHalfCupsState && index === Math.floor(cups) && cups % 1 !== 0)
                    ? 'Click to fill cup completely'
                    : (allowHalfCupsState && index === Math.floor(cups))
                      ? 'Click to add half cup'
                      : 'Click to add one cup'
            }
          >
            <img 
              src={getWaterIcon(index)}
              className={`water-drop`}
              onClick={() => handleDropClick(index)}
              style={{ margin: '2px', cursor: 'pointer' }}
            />
          </Tooltip>
        ))}
      </div>

      {/* Settings Dialog */}
      <Dialog open={settingsOpen} onClose={() => {
        setWaterSettings(waterGoalCupsState, cupSizeState, allowHalfCupsState, cupSizeUnitState);
        setSettingsOpen(false);
      }}>
        <DialogTitle>Water Tracker Settings</DialogTitle>
        <div className="settings-content" style={{ padding: '0px 20px' }}>
          <div className="settings-item">
            <p>Daily Goal (Cups): {waterGoalCupsState}</p>
            <Slider
              style={{ color: 'rgb(89 169 239)' }}
              value={waterGoalCupsState}
              onChange={(e, newValue) => setWaterGoalCupsState(newValue)}
              aria-labelledby="water-goal-cups-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={4}
              max={20}
            />
          </div>
          <div className="settings-item" style={{ marginTop: '20px' }}>
            <FormControl fullWidth variant="outlined">
              <TextField
                label={`Cup Size (${cupSizeUnitState})`}
                type="number"
                value={cupSizeState}
                onChange={(e) => setCupSizeState(parseFloat(e.target.value))}
                inputProps={{ 
                  min: cupSizeUnitState === 'oz' ? 1 : 30, 
                  max: cupSizeUnitState === 'oz' ? 64 : 2000,
                  step: cupSizeUnitState === 'oz' ? 0.1 : 1
                }}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Select
                        value={cupSizeUnitState}
                        onChange={handleCupSizeUnitChange}
                        disableUnderline
                      >
                        <MenuItem value="oz">oz</MenuItem>
                        <MenuItem value="ml">ml</MenuItem>
                      </Select>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </div>
          <div className="settings-item" style={{ marginTop: '20px' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={allowHalfCupsState}
                  onChange={(e) => setAllowHalfCupsState(e.target.checked)}
                  style={{ color: 'rgb(89 169 239)' }}
                />
              }
              label="Allow Half Cup Increments"
            />
          </div>
        </div>
        <DialogActions>
          <Button
            onClick={() => {
              setWaterSettings(waterGoalCupsState, cupSizeState, allowHalfCupsState, cupSizeUnitState);
              setSettingsOpen(false);
            }}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WaterTrackerCard;
