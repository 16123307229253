import './contact_us.css'
import React from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { config as mainConfig } from 'Constants';
import emailjs from '@emailjs/browser';
import { Capacitor } from '@capacitor/core';
import MobileSettingsButton from 'components/shared/mobile_settings_button/mobile_settings_button';
import { AuthContext } from 'contexts/auth_context';

const ContactUs = (props) => {
    const form = React.useRef();
    let [showSuccessMessage, setShowSuccessMessage] = React.useState(false)
    const { currentUser } = React.useContext(AuthContext)

    const sendEmail = async (e) => {
        e.preventDefault()
        try {
            await emailjs.sendForm(
                mainConfig.emailServiceId,
                mainConfig.emailTemplateId,
                form.current,
                mainConfig.emailPublicKey);

            setShowSuccessMessage(true)
            e.target.reset()
        } catch (e) {
            console.log(e);
        }
    };


    return (
        <>

            <div className="contact-us-page-top" style={{ minHeight: "calc(100% - 45px)" }}>

                {Capacitor.getPlatform() !== "web" && <MobileSettingsButton isPlanning={true}/>}




                <div className="contact-us-form">

                   {currentUser && currentUser.emailVerified? <div className="contact-us-form-header">Leave us a message below or contact us at hello@thebitetracker.com</div>:
                   <div className="contact-us-form-header">Leave us a message and we'll get back to you</div>}
                    <form ref={form} onSubmit={sendEmail}>
                        {currentUser && <input type="hidden" name="firebase_user_id" value={currentUser.uid} />}

                        <div className="contact-us-form-line-item">
                            <TextField className="contact-us-form-input-narrow-left" type="text" variant="outlined" required={true} label="name" name='name'  ></TextField>
                            <TextField className="contact-us-form-input-narrow-right" type="text" variant="outlined" required={true} label="email address" name='email' ></TextField>

                        </div>


                        <div className="contact-us-form-line-item">
                            <TextField className="contact-us-form-input-wide" type="text" variant="outlined" required={true} label="subject" name='subject' ></TextField>

                        </div>

                        <div className="contact-us-form-line-item">
                            <TextField className="contact-us-form-input-wide" type="text" variant="outlined" required={true} label="message" multiline rows={5} name='message' ></TextField>

                        </div>

                        <div className="contact-us-form-submit-button"><Button type="submit" variant="contained" color="primary" value="Submit" >Submit</Button></div>
                    </form>
                    {showSuccessMessage && <div style={{ textAlign: 'center' }}>Success! We'll get back to you shortly.</div>}
                </div>
            </div></>)
}

export default ContactUs;