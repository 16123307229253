import React from 'react';
import { UserContext } from 'contexts/user_context';
import BasicInfoStep from './steps/basic_info_step';
import ActivityLifestyleStep from './steps/activity_lifestyle_step';
import GoalSettingStep from './steps/goal_setting_step';
import { getDateMonthYear, parseDateMonthYear } from 'helpers/date_helpers';
import { calculateProjections } from './projection_calculations';
import './calorie_calculator.css';

// Add the getCalorieAdjustment function here
const getCalorieAdjustment = (weightDiff, currentWeight) => {
    const changePercentage = Math.abs(weightDiff) / currentWeight * 100;

    if (changePercentage < 1) {
        return 1.0; // Maintenance calories
    }

    if (weightDiff < 0) { // Weight loss
        if (changePercentage < 5) {
            return 0.90; // 10% deficit for small losses
        } else if (changePercentage < 10) {
            return 0.85; // 15% deficit for moderate losses
        } else {
            return 0.80; // 20% deficit for larger losses
        }
    } else { // Weight gain
        if (changePercentage < 5) {
            return 1.05; // 5% surplus for small gains
        } else if (changePercentage < 10) {
            return 1.08; // 8% surplus for moderate gains
        } else {
            return 1.10; // 10% surplus for larger gains
        }
    }
};

const CalorieCalculator = (props) => {
    const userContext = React.useContext(UserContext);
    const { userObject, setUserObject, getCurrentWeight, getCurrentBodyFat } = userContext;
    const [step, setStep] = React.useState(1);
    const [errors, setErrors] = React.useState({});
    const [needsCalculation, setNeedsCalculation] = React.useState(true);
    const [showErrors, setShowErrors] = React.useState(false);
    const [isValid, setIsValid] = React.useState(false);

    // Initialize form data from userObject
    const [formData, setFormData] = React.useState({
        // Basic Info
        sex: userObject.sex || '',
        age: userObject.age || '',
        height: userObject.height || '',
        heightUnit: userObject.heightUnit || 'ft',
        heightFeet: userObject.heightFeet || '',
        heightInches: userObject.heightInches || '',
        weight: userObject.weight || {},
        bodyFat: userObject.bodyFat || {},
        currentBodyFat: parseFloat(getCurrentBodyFat()) || '',

        // Activity Levels
        activityLevel: userObject.activityMultiplier || 1.2,
        cardioActivity: userObject.cardioModifier || 1.0,
        muscleBuildingActivity: userObject.muscleBuildingModifier || 1.0,

        // Goals
        goalWeight: userObject.weightGoal?.value || '',
        goalBodyFat: userObject.bodyFatGoal || '',
        dailyCalories: userObject.calorieTarget || '',
        macroDistribution: userObject.macroDistribution || { protein: 30, carbs: 40, fat: 30 }
    });

    React.useEffect(() => {
        if (formData.goalWeight || formData.goalBodyFat) {
            setNeedsCalculation(true);
        }
    }, [formData.goalWeight, formData.goalBodyFat]);

    const handleUpdateFormData = (updates) => {
        setFormData(prev => ({
            ...prev,
            ...updates
        }));
    };

    const checkValidation = (currentStep) => {
        const newErrors = {};

        switch (currentStep) {
            case 1:
                if (!formData.sex) {
                    newErrors.sex = 'Please select your gender';
                }
                if (!formData.age || formData.age < 18 || formData.age > 100) {
                    newErrors.age = 'Please enter a valid age between 18 and 100';
                }

                // Height validation
                if (formData.heightUnit === 'cm') {
                    if (!formData.height || formData.height < 130 || formData.height > 230) {
                        newErrors.height = 'Please enter a valid height between 130cm and 230cm';
                    }
                } else {
                    if (!formData.heightFeet || !formData.heightInches) {
                        newErrors.height = 'Please enter your full height';
                    } else {
                        const totalInches = (parseInt(formData.heightFeet) * 12) + parseInt(formData.heightInches);
                        if (totalInches < 51 || totalInches > 90) {
                            newErrors.height = 'Please enter a valid height between 4\'3" and 7\'6"';
                        }
                    }
                }

                // ... existing code ...
                const dateKeys = Object.keys(formData.weight || {});
                let latestDateKey = dateKeys[0];

                if (dateKeys.length > 0) {
                    let maxDate = parseDateMonthYear(dateKeys[0]);

                    // Find the latest date
                    for (let dateKey of dateKeys) {
                        let currentDate = parseDateMonthYear(dateKey);
                        if (currentDate > maxDate) {
                            maxDate = currentDate;
                            latestDateKey = dateKey;
                        }
                    }
                }

                const currentWeight = formData.weight?.[latestDateKey]?.value;
                const weightUnit = formData.weight?.[latestDateKey]?.unit || 'lbs';
                const minWeight = weightUnit === 'lbs' ? 70 : 31.75;
                const maxWeight = weightUnit === 'lbs' ? 600 : 272.15;
                // ... existing code ...

                if (!currentWeight || currentWeight < minWeight || currentWeight > maxWeight) {
                    newErrors.weight = `Please enter a valid weight between ${minWeight} and ${maxWeight} ${weightUnit}`;
                }
                break;

            case 2:
                if (!formData.activityLevel) {
                    newErrors.activityLevel = 'Please select your activity level';
                }
                if (!formData.cardioActivity) {
                    newErrors.cardioActivity = 'Please select your cardio frequency';
                }
                if (!formData.muscleBuildingActivity) {
                    newErrors.muscleBuildingActivity = 'Please select your strength training frequency';
                }
                break;

            case 3:
                if (needsCalculation && !formData.goalWeight) {
                    newErrors.goalWeight = 'Please enter your goal weight';
                }
                break;

            default:
                break;
        }

        return { isValid: Object.keys(newErrors).length === 0, errors: newErrors };
    };

    React.useEffect(() => {
        const { isValid: newIsValid, errors: newErrors } = checkValidation(step);
        setIsValid(newIsValid);
        if (showErrors) {
            setErrors(newErrors);
        }
    }, [formData, step, needsCalculation]);

    const handleNext = () => {
        const { isValid: newIsValid, errors: newErrors } = checkValidation(step);
        if (newIsValid) {
            if (step === 1) {
                // Save basic info to userObject when moving from step 1
                const dateKey = getDateMonthYear(new Date());
                const updatedUserObject = {
                    ...userObject,
                    sex: formData.sex,
                    age: formData.age,
                    height: formData.height,
                    heightUnit: formData.heightUnit,
                    heightFeet: formData.heightFeet,
                    heightInches: formData.heightInches,
                    weight: formData.weight
                };
                // Only add bodyFat if it exists
                if (formData.currentBodyFat !== '' && formData.currentBodyFat !== undefined) {
                    updatedUserObject.bodyFat = {
                        ...userObject.bodyFat,
                        [dateKey]: parseFloat(formData.currentBodyFat)
                    };
                }

                setUserObject(updatedUserObject);
                setStep(step + 1);
                setErrors({});
                setShowErrors(false);
            } else if (step === 3 && needsCalculation) {
                // Calculate macros and update user object
                const [currentWeight, weightUnit] = getCurrentWeight();

                // Calculate BMR and TDEE
                const heightCm = formData.heightUnit === 'cm' ?
                    formData.height :
                    ((parseFloat(formData.heightFeet || 0) * 12) + parseFloat(formData.heightInches || 0)) * 2.54;

                const toKg = weightUnit === 'lbs' ? 0.453592 : 1;
                const weightKg = currentWeight * toKg;

                let BMR;
                if (formData.sex === 'male') {
                    BMR = 10 * weightKg + 6.25 * heightCm - 5 * formData.age + 5;
                } else {
                    BMR = 10 * weightKg + 6.25 * heightCm - 5 * formData.age - 161;
                }

                // Calculate TDEE with proper activity multipliers
                const baseMultiplier = formData.activityLevel;
                const cardioMultiplier = formData.cardioActivity - 1;
                const strengthMultiplier = formData.muscleBuildingActivity - 1;

                // Base TDEE using lifestyle multiplier
                let TDEE = BMR * baseMultiplier;

                // Add exercise impacts
                const cardioImpact = cardioMultiplier * weightKg * 2.5 * 7;
                const strengthImpact = strengthMultiplier * weightKg * 2.75 * 7;
                TDEE += (cardioImpact + strengthImpact) / 7;

                // Calculate target calories
                const weightDiff = formData.goalWeight - currentWeight;
                const isWeightLoss = weightDiff < 0;
                const adjustmentFactor = getCalorieAdjustment(weightDiff, currentWeight);
                const calculatedCalories = Math.round(TDEE * adjustmentFactor);

                // Set macro distribution
                const macroDistribution = {
                    protein: isWeightLoss ? 35 : 30,
                    carbs: isWeightLoss ? 40 : 45,
                    fat: isWeightLoss ? 25 : 25
                };

                // Calculate projections
                const projectionResults = calculateProjections({
                    currentWeight,
                    goalWeight: formData.goalWeight,
                    currentBodyFat: formData.currentBodyFat,
                    goalBodyFat: formData.goalBodyFat,
                    dailyCalories: calculatedCalories,
                    macroDistribution,
                    activityLevel: formData.activityLevel,
                    cardioActivity: formData.cardioActivity,
                    muscleBuildingActivity: formData.muscleBuildingActivity,
                    userObject: {
                        sex: formData.sex,
                        age: formData.age,
                        height: formData.height,
                        heightUnit: formData.heightUnit,
                        heightFeet: formData.heightFeet,
                        heightInches: formData.heightInches,
                        weightUnit
                    }
                });

                // Update form data with calculated values
                const updatedFormData = {
                    ...formData,
                    dailyCalories: calculatedCalories,
                    macroDistribution,
                    weightProjection: projectionResults.dataPoints
                };

                // Update user object
                const updatedUserObject = {
                    ...userObject,
                    weightGoal: {
                        value: formData.goalWeight,
                        unit: weightUnit
                    },
                    calorieTarget: calculatedCalories,
                    macroDistribution,
                    activityMultiplier: formData.activityLevel,
                    cardioModifier: formData.cardioActivity,
                    muscleBuildingModifier: formData.muscleBuildingActivity
                };

                if (formData.goalBodyFat !== '' && formData.goalBodyFat !== undefined) {
                    updatedUserObject.bodyFatGoal = parseFloat(formData.goalBodyFat);
                }

                setFormData(updatedFormData);
                setUserObject(updatedUserObject);
                setNeedsCalculation(false);

            } else if (step < 3) {
                setStep(step + 1);
                setErrors({});
                setShowErrors(false);
            } else {
                // Final save
                handleSubmit();
            }
        } else {
            setErrors(newErrors);
            setShowErrors(true);
        }
    };

    const handleBack = () => {
        if (step > 1) {
            setStep(step - 1);
            setErrors({});
            setShowErrors(false);
        } else if (props.onBack) {
            props.onBack();
        }
    };

    const handleSubmit = () => {
        console.log('handleSubmit')
        setUserObject({
            ...userObject,
            activityMultiplier: formData.activityLevel,
            cardioModifier: formData.cardioActivity,
            muscleBuildingModifier: formData.muscleBuildingActivity,
            calories: formData.dailyCalories,
            proteins: Math.round(formData.macroDistribution.protein * formData.dailyCalories / 400),
            fats: Math.round(formData.macroDistribution.fat * formData.dailyCalories / 900),
            carbs: Math.round(formData.macroDistribution.carbs * formData.dailyCalories / 400),
            macroDistribution: formData.macroDistribution,
            weightProjection: formData.weightProjection, 
            onboardingStatus: {
              ...userObject.onboardingStatus,
              currentStage: 4
            }
        });
        props.handleExitClick();
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <BasicInfoStep
                        formData={formData}
                        onUpdate={handleUpdateFormData}
                        errors={errors}
                    />
                );
            case 2:
                return (
                    <ActivityLifestyleStep
                        formData={formData}
                        onUpdate={handleUpdateFormData}
                        errors={errors}
                    />
                );
            case 3:
                return (
                    <GoalSettingStep
                        formData={formData}
                        onUpdate={handleUpdateFormData}
                        userObject={userObject}
                        setUserObject={setUserObject}
                        needsCalculation={needsCalculation}
                        setNeedsCalculation={setNeedsCalculation}
                        errors={errors}
                        dailyCalories={formData.dailyCalories}
                        macroDistribution={formData.macroDistribution}
                        weightProjection={formData.weightProjection}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="calorie-calculator-form">
            {!props.showBackButton && (
                <button
                    onClick={props.handleExitClick}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        background: 'none',
                        border: 'none',
                        fontSize: '20px',
                        cursor: 'pointer',
                        padding: '5px',
                        color: '#666'
                    }}
                >
                    ✕
                </button>
            )}
            {renderStep()}
            {showErrors && Object.keys(errors).length > 0 && (
                <div style={{
                    color: '#d32f2f',
                    fontSize: '12px',
                    padding: '8px 16px',
                    backgroundColor: '#ffebee',
                    textAlign: 'left'
                }}>
                    <div style={{ fontWeight: 'bold', marginBottom: '4px' }}>Please address the following:</div>
                    <ul style={{ margin: '0', paddingLeft: '20px' }}>
                        {Object.values(errors).map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </div>
            )}
            <div style={{
                display: 'flex',
                gap: '10px',
                width: '100%',
                padding: '20px',
                borderTop: '1px solid #eee',
                backgroundColor: 'white',
                marginTop: 'auto',
                boxSizing: 'border-box'
            }}>
                {(step > 1 || props.showBackButton) && (
                    <button
                        onClick={step === 1 ? props.onBack : handleBack}
                        style={{
                            flex: 1,
                            padding: '14px',
                            border: 'none',
                            borderRadius: '8px',
                            fontSize: '16px',
                            fontFamily: 'Montserrat-SemiBold',
                            cursor: 'pointer',
                            backgroundColor: '#f5f5f5',
                            color: '#666'
                        }}
                    >
                        Back
                    </button>
                )}
                <button
                    onClick={handleNext}
                    style={{
                        flex: 1,
                        padding: '14px',
                        border: 'none',
                        borderRadius: '8px',
                        fontSize: '16px',
                        fontFamily: 'Montserrat-SemiBold',
                        cursor: 'pointer',
                        backgroundColor: '#2D7933',
                        color: 'white',
                        opacity: isValid ? 1 : 0.5
                    }}
                >
                    {step === 3 && needsCalculation ? 'Calculate Plan' :
                        step === 3 ? 'Save Plan' : 'Next'}
                </button>
            </div>
        </div>
    );
};

export default CalorieCalculator;