export function getMealNutrientAmount(meal, nutrientName) {
    if (meal.nutrition) {
        if (nutrientName === "Net Carbohydrates") {
            let fiber = 0;
            let carbohydrate = 0;
            for (let nutrient of meal.nutrition.nutrients) {
                if (nutrient.name === "Carbohydrates") {
                    carbohydrate = nutrient.amount
                }
                if (nutrient.name === "Fiber") {
                    fiber = nutrient.amount
                }

            }
            if (carbohydrate === 0) { return 0 }
            else { return Math.round(carbohydrate - fiber) }
        }

        else {


            for (let nutrient of meal.nutrition.nutrients) {
                if (nutrient.name === nutrientName) {
                    if (!nutrient.amount) { return 0 }
                    return Math.round(nutrient.amount)

                }

            }
        }
    }
    return 0;
}

export function getFoodNutrientAmount(food, nutrientName) {
    if (food.type && food.type === "meal") {
        return getMealNutrientAmount(food, nutrientName)
    }

    if (nutrientName === 'Carbohydrates') {
        nutrientName = 'carbohydrate'
    }

    if (nutrientName === "Saturated Fat") {
        nutrientName = "saturated_fat"
    }

    if (food.servings.serving[food.servingChosen]) {
        return parseFloat(food.servings.serving[food.servingChosen][nutrientName.toLowerCase()])


    }
}

function gcd(a, b) {
    if (a === 0)
        return b;
    else if (b === 0)
        return a;
    if (a < b)
        return gcd(a, b % a);
    else
        return gcd(b, a % b);
}

// Function to convert decimal to fraction
export function decimalToFraction(number) {
    let floor = Math.floor(number)
    let fraction = number - floor
    // Fetch letegral value of the decimal
    let letVal = floor

    // Fetch fractional part of the decimal
    let fVal = fraction

    // Consider precision value to
    // convert fractional part to
    // letegral equivalent
    let pVal = 8;

    // Calculate GCD of letegral
    // equivalent of fractional
    // part and precision value
    let gcdVal = gcd(Math.round(
        fVal * pVal), pVal);

    // Calculate num and deno
    let num = Math.round(fVal * pVal) / gcdVal;
    let deno = pVal / gcdVal;

    if (deno === 1) {
        return ((letVal * deno) + num).toString()
    }
    if (floor === 0) {
        return ((letVal * deno) +
            num + "/" + deno);
    }
    return (floor + " " + (
        num) + "/" + deno);

}

export const recalculateCaloriesForRecipe = (recipe) => {
    for (let recipeNutrient of recipe.nutrition.nutrients) {
        recipeNutrient['amount'] = 0
        for (let ingredient of recipe.ingredients) {
            for (let ingredientNutrient of ingredient.nutrition.nutrients) {

                if (recipeNutrient.name === ingredientNutrient.name) {
                    if (ingredientNutrient['amount'] && ingredient['amount']) {
                        recipeNutrient['amount'] = recipeNutrient['amount'] + (ingredientNutrient['amount'] * ingredient['amount'])
                        if (recipeNutrient.name === "Calories") {
                        }
                    }
                }
            }
        }
    }
    return recipe
}

export const cleanUpNutrients = (nutrients) => {
    //need to only grab the nutrient information inside nutrition (i.e. not flavonoids)
    let ingredientNutrients = []
    for (let ingredientNutrient of nutrients) {
        if (["Calories", "Fat", "Saturated Fat", "Carbohydrates", "Calcium", "Sugar", "Cholesterol", "Sodium", "Protein", "Fiber"].includes(ingredientNutrient.name)) {
            ingredientNutrients.push(ingredientNutrient)
        }
    }
    return ingredientNutrients
}

export function compareByTimestamp(a, b) {
    // Compare the timestamp values of the objects

    if (!a.timestamp) {
        return 1
    }
    if (!b.timestamp) {
        return -1
    }
    return b.timestamp - a.timestamp;
}


export const getGramsFromServing = (serving) => {
    let grams = 0
    if (serving.metric_serving_unit === "oz") {
        let ounces = parseFloat(serving.metric_serving_amount)
        grams = 28.35 * ounces
    }
    else if (serving.metric_serving_unit === "g") {
        grams = parseFloat(serving.metric_serving_amount)
    }
    return grams
}

export async function checkImageValidity(url) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = img.onabort = () => resolve(false);
        img.src = url;
    });
}



export const cleanUpMeasures = (ingredient, nutrition) => {
    let unitsToGrams = {
        'Tbsps': 14.175,
        'tbsp': 14.175,
        'Tbsp': 14.175,
        'tbsps': 14.175,
        'cups': 340,
        'tsps': 5.7,
        'tsp': 5.7,
        'cloves': 15,
        'large': 250,
        'medium': 200,
        'small': 150,
        'oz': 28.35
    }
    let foundUnit = false
    for (let unit of unitsToGrams.keys()) {
        if (ingredient.measures.metric.unitShort === unit) {
            nutrition.weightPerServing = { amount: unitsToGrams[unit], unit: 'g' }
            foundUnit = true
            break
        }
        if (ingredient.measures.us.unitShort === unit) {
            nutrition.weightPerServing = { amount: unitsToGrams[unit], unit: 'g' }
            foundUnit = true
            break
        }
    }
    if (!foundUnit) {
        console.log("units not found: ", ingredient.measures.metric.unitShort, ingredient.measures.us.unitShort)
        nutrition.weightPerServing = { amount: 200, unit: 'g' }
    }

}
