import './login.css'
import { Button, TextField } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { AuthContext } from "contexts/auth_context"
import React, { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import LoopIcon from '@material-ui/icons/Loop';
import { googleProvider } from "../../../firebase"
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { Capacitor } from '@capacitor/core'
import AppleIcon from '@material-ui/icons/Apple';
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";


(function() {
    const originalSetTimeout = window.setTimeout;
    window.setTimeout = function(fn, delay, ...args) {
      // Check if the delay matches Firebase's _Timeout.AUTH_EVENT
      if (delay === 8000) {
        delay = 1000; 
      }
      return originalSetTimeout(fn, delay, ...args);
    };
  })();

export default function Login() {
    let [loginObject, setLoginObject] = React.useState({});
    const { login, loginSocial, appleLogin, resetPassword } = useContext(AuthContext)
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [signinLoading, setSigninLoading] = useState(false)
    const history = useHistory()
    const { currentUser } = React.useContext(AuthContext)
    const [resetDialogOpen, setResetDialogOpen] = useState(false);
    const [resetEmail, setResetEmail] = useState("");
    const [resetStatus, setResetStatus] = useState({ message: "", isError: false });

    const handleSocialClick = async (provider) => {
        setError('')
        setSigninLoading(true)
        try {
            await loginSocial(provider)
            history.push("/calendar")
        } catch (error) {
            setError("Failed to sign in with Google")
        } finally {
            setSigninLoading(false)
        }
    }

    const loginWithApple = async () => {
        setSigninLoading(true)
        try {
            await appleLogin()
            history.push("/calendar")
        } catch (error) {
            setError("Failed to sign in with Apple")
        } finally {
            setSigninLoading(false)
        }
    }

    async function handleSubmit(e) {
        e.preventDefault()
        try {
            setError("")
            setLoading(true)
            let result = await login(loginObject.email, loginObject.password)
            console.log(result)
            if (result.length === 0) {
                history.push("/calendar")
            } else {
                setError(result)
            }
        } catch {
            setError("Failed to log in")
        } finally {
            setLoading(false)
        }
    }

    if (currentUser !== null && error.length === 0) {
        history.push("/calendar")
    }

    const handleChange = (e) => {
        setLoginObject({ ...loginObject, [e.target.name]: e.target.value })
    }

    const handleResetPassword = async (e) => {
        e.preventDefault();
        setResetStatus({ message: "", isError: false });
        
        if (!resetEmail) {
            setResetStatus({ message: "Please enter your email", isError: true });
            return;
        }

        const result = await resetPassword(resetEmail);
        
        if (result.success) {
            setResetStatus({ 
                message: "Password reset email sent. Please check your inbox.", 
                isError: false 
            });
            // Close dialog after 3 seconds on success
            setTimeout(() => {
                setResetDialogOpen(false);
                setResetEmail("");
                setResetStatus({ message: "", isError: false });
            }, 3000);
        } else {
            setResetStatus({ 
                message: result.error, 
                isError: true 
            });
        }
    };

    return (
        <div className="contact-us-page-top">
            {signinLoading ? (
                <div className="login-frame">
                    <div className="loading-spinner">Loading...</div>
                </div>
            ) : (
                <div className="login-frame">
                    <div className="login-header">Welcome Back</div>
                    {error && <Alert severity="error">{error}</Alert>}
                    <form onSubmit={handleSubmit}>
                        <div className="login-form-line-item">
                            <TextField 
                                className="login-form-input-wide"
                                type="email"
                                variant="outlined"
                                required={true}
                                label="Email"
                                name="email"
                                value={loginObject.email}
                                onChange={handleChange}
                                fullWidth
                            />
                        </div>
                        <div className="login-form-line-item">
                            <TextField
                                className="login-form-input-wide"
                                type="password"
                                variant="outlined"
                                required={true}
                                label="Password"
                                name="password"
                                value={loginObject.password}
                                onChange={handleChange}
                                fullWidth
                            />
                        </div>
                        <Button
                            variant="contained"
                            disabled={loading}
                            className="login-button"
                            type="submit"
                            fullWidth
                        >
                            {loading ? (
                                <LoopIcon style={{ animation: 'spin 2s linear infinite' }} />
                            ) : (
                                'Sign In'
                            )}
                        </Button>
                    </form>
                    
                    <div className="login-form-divider">or continue with</div>
                    
                    <Button 
                        className="login-google-button"
                        onClick={() => handleSocialClick(googleProvider)}
                        startIcon={<img className="google-logo" alt="google logo" src="/googleLogo.jpeg" />}
                    >
                        Google
                    </Button>
                    
                    {Capacitor.getPlatform() === 'ios' && (
                        <Button
                            className="login-apple-button"
                            onClick={() => loginWithApple()}
                            startIcon={<AppleIcon />}
                        >
                            Apple
                        </Button>
                    )}
                    
                    <div className="login-form-switch">
                        Don't have an account? <NavLink to="signup">Sign up</NavLink>
                    </div>
                    <div className="login-form-switch">
                        Forgot password? <Button 
                            onClick={() => setResetDialogOpen(true)}
                            style={{ 
                                textTransform: 'none',
                                padding: '0',
                                minWidth: 'auto',
                                fontSize: '14px',
                                color: '#2D7933',
                                fontWeight: 500
                            }}
                        >
                            Reset
                        </Button>
                    </div>
                </div>
            )}

            {/* Reset Password Dialog */}
            <Dialog 
                open={resetDialogOpen} 
                onClose={() => {
                    setResetDialogOpen(false);
                    setResetEmail("");
                    setResetStatus({ message: "", isError: false });
                }}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Reset Password</DialogTitle>
                <form onSubmit={handleResetPassword}>
                    <DialogContent>
                        {resetStatus.message && (
                            <Alert 
                                severity={resetStatus.isError ? "error" : "success"}
                                style={{ marginBottom: '16px' }}
                            >
                                {resetStatus.message}
                            </Alert>
                        )}
                        <TextField
                            className="login-form-input-wide"
                            type="email"
                            variant="outlined"
                            required
                            label="Email"
                            value={resetEmail}
                            onChange={(e) => setResetEmail(e.target.value)}
                            fullWidth
                            autoFocus
                        />
                    </DialogContent>
                    <DialogActions style={{ padding: '16px 24px' }}>
                        <Button 
                            onClick={() => {
                                setResetDialogOpen(false);
                                setResetEmail("");
                                setResetStatus({ message: "", isError: false });
                            }}
                            style={{ 
                                textTransform: 'none',
                                color: '#666' 
                            }}
                        >
                            Cancel
                        </Button>
                        <Button 
                            type="submit"
                            variant="contained"
                            className="login-button"
                            style={{ 
                                width: 'auto',
                                margin: '0',
                                padding: '6px 16px'
                            }}
                        >
                            Send Reset Link
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}